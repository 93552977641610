import {
    Box,
    Checkbox,
    createStyles,
    DefaultProps,
    Group,
    Selectors,
    TextInput,
    useComponentDefaultProps,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { ConnectionMonitor } from '@/management/models';
import { useControlledForm } from 'src/common/hooks';

export interface ConnectionMonitorInputStyleParams {}

const useStyles = createStyles((theme, params: ConnectionMonitorInputStyleParams, getRef) => {
    return {
        root: {},
    };
});

type ConnectionMonitorInputStyleNames = Selectors<typeof useStyles>;

export interface ConnectionMonitorInputProps
    extends DefaultProps<ConnectionMonitorInputStyleNames, ConnectionMonitorInputStyleParams> {
    value?: ConnectionMonitor;
    onChange?: (value: ConnectionMonitor) => void;
}

const componentName = 'CLUIConnectionMonitorInput';
const defaultProps: Partial<ConnectionMonitorInputProps> = {};
const defaultValues: Partial<ConnectionMonitor> = {
    iris: {
        hostname: '',
        paused: false,
    },
};

export const ConnectionMonitorInput = (props: ConnectionMonitorInputProps) => {
    const { className, classNames, styles, unstyled, value, onChange, ...rest } = useComponentDefaultProps(
        componentName,
        defaultProps,
        props,
    );
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    const form = useControlledForm({
        defaultValues,
        value,
        onChange,
    });
    return (
        <Box className={cx(classes.root, className)} {...rest}>
            <Group position="apart" grow sx={{ flexWrap: 'nowrap' }}>
                <TextInput label={t('Hostname')} placeholder={t('')} {...form.getInputProps('iris.hostname')} />
                <Box />
            </Group>
            <Group mt="sm" position="apart" grow sx={{ flexWrap: 'nowrap' }}>
                <Checkbox
                    label={t('Paused')}
                    {...form.getInputProps('iris.paused', {
                        type: 'checkbox',
                    })}
                />
                <Box />
            </Group>
        </Box>
    );
};

export default ConnectionMonitorInput;
