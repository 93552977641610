import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAME, CrmState } from './models';

const initialState: CrmState = {};

export const crmSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {},
});

export const {} = crmSlice.actions;

export default crmSlice.reducer;
