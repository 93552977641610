import { getErrorMessage } from '@/errors';
import { Accordion, ActionIcon, Alert, Button, Code, Group, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle, IconDeviceFloppy, IconUpload } from '@tabler/icons';
import { useCallback, useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddressInput from '@/common/components/AddressInput';
import { useCreateLocationAttachmentMutation, useUpdateLocationMutation } from '@/management/api';
import { Location } from '@/management/models';
import { Address } from '@/models';
import { $F, useFeatureCheck } from 'src/identity';
import LocationInput from 'src/common/components/LatLngInput';
import { LatLng } from 'src/common';
import { ContactAssociation, useContactSelectData } from 'src/crm';

export interface UpdateLocationFormProps {
    location: Location;
    locationId: string;
    onClose?: () => void;
}

type UpdateLocationFormValues = {
    name: string;
    logoUrl: string;
    iconUrl: string;
    address: Address;
    location: LatLng;
    administrativeContactID: string;
    technicalContactID: string;
};

export const UpdateLocationForm = ({ location, locationId, onClose }: UpdateLocationFormProps) => {
    const { t, i18n } = useTranslation();
    const feat = useFeatureCheck();
    const form = useForm<UpdateLocationFormValues>({
        initialValues: {
            name: '',
            logoUrl: '',
            iconUrl: '',
            address: {},
            location: {},
            administrativeContactID: '',
            technicalContactID: '',
        },
        validate: {
            name: (value) => (value.length < 1 ? t('Name is required') : null),
        },
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [updateLocation, { isLoading: isSaving, isSuccess, isError }] = useUpdateLocationMutation();

    const onSubmit = useCallback(
        async ({
            name,
            address,
            location,
            logoUrl,
            iconUrl,
            administrativeContactID,
            technicalContactID,
        }: UpdateLocationFormValues) => {
            try {
                const res = await updateLocation({
                    location: {
                        id: locationId,
                        name,
                        address,
                        location,
                        logoUrl,
                        iconUrl,
                        administrativeContact: {
                            id: administrativeContactID,
                        } as ContactAssociation,
                        technicalContact: {
                            id: technicalContactID,
                        } as ContactAssociation,
                    },
                }).unwrap();
                if (onClose) {
                    onClose();
                }
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
            }
        },
        [updateLocation, onClose],
    );

    useEffect(() => {
        if (location) {
            form.setValues({
                name: location.name,
                logoUrl: location.logoUrl,
                iconUrl: location.iconUrl,
                address: location.address,
                location: location.location,
                administrativeContactID: location.administrativeContact?.id!,
                technicalContactID: location.technicalContact?.id!,
            });
        }
    }, [location]);

    const [createLocationAttachment, { isLoading: isCreatingLocationAttachment }] =
        useCreateLocationAttachmentMutation();
    const onAttachmentChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0];
            const as = e.target.getAttribute('data-as') ?? '';
            if (file) {
                try {
                    const res = await createLocationAttachment({
                        locationId,
                        file,
                        as,
                    }).unwrap();
                    // if (onClose) {
                    //     onClose();
                    // }
                } catch (err) {
                    setErrorMessage(getErrorMessage(err));
                }
            }
        },
        [createLocationAttachment, setErrorMessage, onClose],
    );

    const logoFileId = useId();
    const logoFileRef = useRef<HTMLInputElement>(null);
    const iconFileId = useId();
    const iconFileRef = useRef<HTMLInputElement>(null);

    const [contactSelectData, setContactSearch, contactSearch] = useContactSelectData(
        location.administrativeContact
            ? {
                  label: location.administrativeContact.name,
                  value: location.administrativeContact.id,
              }
            : null,
    );

    const [contactSelectDataTech, setContactSearchTech, contactSearchTech] = useContactSelectData(
        location.technicalContact
            ? {
                  label: location.technicalContact.name,
                  value: location.technicalContact.id,
              }
            : null,
    );

    return (
        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
            <TextInput label={t('Customer')} disabled value={location?.customer?.name ?? t('Unknown')} />
            <TextInput
                mt="md"
                data-autofocus
                required
                label={t('Name')}
                placeholder={t('Name')}
                {...form.getInputProps('name')}
            />
            <input
                ref={logoFileRef}
                type="file"
                data-as={'logoUrl'}
                id={logoFileId}
                style={{ display: 'none' }}
                onChange={onAttachmentChange}
            />
            <TextInput
                label={t('Logo URL')}
                placeholder={t('URL')}
                {...form.getInputProps('logoUrl')}
                rightSection={
                    <label htmlFor={logoFileId}>
                        <ActionIcon
                            onClick={() => {
                                logoFileRef.current?.click();
                            }}
                        >
                            <IconUpload />
                        </ActionIcon>
                    </label>
                }
            />
            <input
                ref={iconFileRef}
                type="file"
                data-as={'iconUrl'}
                id={iconFileId}
                style={{ display: 'none' }}
                onChange={onAttachmentChange}
            />
            <TextInput
                label={t('Icon URL')}
                placeholder={t('URL')}
                {...form.getInputProps('iconUrl')}
                rightSection={
                    <label htmlFor={iconFileId}>
                        <ActionIcon
                            onClick={() => {
                                iconFileRef.current?.click();
                            }}
                        >
                            <IconUpload />
                        </ActionIcon>
                    </label>
                }
            />
            <Accordion
                variant="separated"
                mt="md"
                multiple
                defaultValue={['administrativeContact', 'technicalContact', 'address', 'location']}
            >
                <Accordion.Item value="administrativeContact">
                    <Accordion.Control>{t('Administrative Contact')}</Accordion.Control>
                    <Accordion.Panel>
                        <Select
                            placeholder={t('Search for contact')}
                            data={contactSelectData}
                            required
                            searchable
                            onSearchChange={setContactSearch}
                            nothingFound={contactSearch ? t('No contacts found') : t('Start typing to search')}
                            {...form.getInputProps('administrativeContactID')}
                            clearable
                        />
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="technicalContact">
                    <Accordion.Control>{t('Technical Contact')}</Accordion.Control>
                    <Accordion.Panel>
                        <Select
                            placeholder={t('Search for contact')}
                            data={contactSelectDataTech}
                            required
                            searchable
                            onSearchChange={setContactSearchTech}
                            nothingFound={contactSearchTech ? t('No contacts found') : t('Start typing to search')}
                            {...form.getInputProps('technicalContactID')}
                            clearable
                        />
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="address">
                    <Accordion.Control>{t('Address')}</Accordion.Control>
                    <Accordion.Panel>
                        <AddressInput mt="md" {...form.getInputProps('address')} />
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="location">
                    <Accordion.Control>{t('Location')}</Accordion.Control>
                    <Accordion.Panel>
                        <LocationInput mt="md" {...form.getInputProps('location')} />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            {errorMessage ? (
                <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                    {errorMessage}
                </Alert>
            ) : null}
            <Group position="right" mt={'md'}>
                <Button color={'gray'} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button type="submit" color={'primary'} loading={isSaving} leftIcon={<IconDeviceFloppy />}>
                    {t('Save')}
                </Button>
            </Group>
            {feat($F.FormDebug) ? (
                <Code mt="md" block>
                    {JSON.stringify(form.values, null, 2)}
                </Code>
            ) : null}
        </form>
    );
};

export default UpdateLocationForm;
