import { LIGHT, THEMES, ThemeMode, getThemeIndex } from '@/styles';
import { MantineThemeOverride } from '@mantine/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
    themeName: string;
    themeMode: 'light' | 'dark';
    theme: MantineThemeOverride;
    themeBackgroundUrl: string | null;
    themeBackgroundColor: string | null;
}

const initialState = (): AppState => {
    const themeName = localStorage.getItem('themeName') ?? 'cloverleaf';
    const themeMode = (localStorage.getItem('themeMode') as ThemeMode) || ('light' as const);
    return {
        themeName,
        themeMode,
        theme: THEMES[themeName][getThemeIndex(themeMode)] ?? LIGHT,
        themeBackgroundUrl: null,
        themeBackgroundColor: null,
    };
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleTheme: (state) => {
            state.themeMode = state.themeMode === 'light' ? 'dark' : 'light';
            state.theme = THEMES[state.themeName][getThemeIndex(state.themeMode)] ?? LIGHT;
            localStorage.setItem('themeMode', state.themeMode);
        },
        setThemeBackgroundUrl: (state, action: PayloadAction<string | null>) => {
            state.themeBackgroundUrl = action.payload;
        },
        setThemeBackgroundColor: (state, action: PayloadAction<string | null>) => {
            state.themeBackgroundColor = action.payload;
        },
    },
});

export const { toggleTheme, setThemeBackgroundUrl, setThemeBackgroundColor } = appSlice.actions;

export default appSlice.reducer;

export type AppStateSlice = {
    [appSlice.name]: AppState;
};

export const selectTheme = (state: AppStateSlice) => state.app.theme;
export const selectThemeBackgroundUrl = (state: AppStateSlice) => state.app.themeBackgroundUrl;
export const selectThemeBackgroundColor = (state: AppStateSlice) => state.app.themeBackgroundColor;
