import { getErrorMessage } from '@/errors';
import { useCreateUserMutation } from '@/identity/api';
import { useGroupTransferListData } from '@/identity/hooks';
import { Alert, Button, Checkbox, Group, PasswordInput, TextInput, TransferList } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle, IconDeviceFloppy } from '@tabler/icons';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface CreateUserFormProps {
    onClose: () => void;
}

type CreateUserValues = {
    emailAddress: string;
    password: string;
    confirmPassword: string;
    generatePassword: boolean;
    name: string;
};

export const CreateUserForm = ({ onClose }: CreateUserFormProps) => {
    const { t, i18n } = useTranslation();
    const form = useForm<CreateUserValues>({
        initialValues: {
            emailAddress: '',
            password: '',
            confirmPassword: '',
            generatePassword: true,
            name: '',
        },
        validate: {
            confirmPassword: (value, values) => (value !== values.password ? t('Passwords did not match') : null),
            name: (value) => (value.length < 1 ? t('Name is required') : null),
            emailAddress: (value) => (value.length < 1 ? t('Email address is required') : null),
            password: (value, values) =>
                !values.generatePassword && value.length < 1 ? t('Password is required') : null,
        },
    });
    const [groupData, setGroupData] = useGroupTransferListData();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [createUser, { isLoading, isSuccess, isError }] = useCreateUserMutation();

    const onSubmit = useCallback(
        async ({ emailAddress, password, name }: CreateUserValues) => {
            try {
                const res = await createUser({
                    user: {
                        emailAddress,
                        password,
                        name,
                    },
                    groupIds: groupData[1].map((group) => group.value),
                }).unwrap();
                onClose();
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
            }
        },
        [createUser, groupData, onClose],
    );

    return (
        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
            <TextInput
                data-autofocus
                required
                label={t('Name')}
                placeholder={t('Name')}
                {...form.getInputProps('name')}
            />
            <TextInput
                mt="md"
                required
                label={t('Email Address')}
                placeholder={t('user@email.com')}
                {...form.getInputProps('emailAddress')}
            />
            <Checkbox
                mt="md"
                label={t('Generate Password')}
                {...form.getInputProps('generatePassword', {
                    type: 'checkbox',
                })}
            />
            {!form.values.generatePassword ? (
                <PasswordInput
                    mt="md"
                    disabled={form.values.generatePassword}
                    required={!form.values.generatePassword}
                    label={t('Password')}
                    placeholder=""
                    {...form.getInputProps('password')}
                />
            ) : null}
            {!form.values.generatePassword ? (
                <PasswordInput
                    mt="md"
                    disabled={form.values.generatePassword}
                    required={!form.values.generatePassword}
                    label={t('Confirm Password')}
                    placeholder=""
                    {...form.getInputProps('confirmPassword')}
                />
            ) : null}
            <TransferList
                listHeight={360}
                titles={[t('Groups'), t('Assigned')]}
                value={groupData}
                mt="md"
                onChange={setGroupData}
            />
            {errorMessage ? (
                <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                    {errorMessage}
                </Alert>
            ) : null}
            <Group position="right" mt={'md'}>
                <Button color={'gray'} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button type="submit" color={'primary'} loading={isLoading} leftIcon={<IconDeviceFloppy />}>
                    {t('Save')}
                </Button>
            </Group>
        </form>
    );
};

export default CreateUserForm;
