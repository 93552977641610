import Drawer, { DrawerContext, DrawerProps } from '@/common/components/Drawer';
import { Text, useComponentDefaultProps } from '@mantine/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useLazyGetGroupPolicyQuery, useLazyGetGroupQuery } from 'src/identity/api';
import { Group, Policy } from 'src/identity/models';
import SetGroupPolicyForm from './SetGroupPolicyForm';

export interface SetGroupPolicyProps extends DrawerProps {}

const componentName = 'CLUISetGroupPolicy';
const defaultProps: Partial<SetGroupPolicyProps> = {
    size: '50vw',
    padding: 'md',
    position: 'right',
    closeOnClickOutside: false,
    overlayOpacity: 0.0,
    shadow: 'lg',
};

export const SetGroupPolicy = (props: SetGroupPolicyProps) => {
    const { onClose, ...rest } = useComponentDefaultProps(componentName, defaultProps, props);
    const { t, i18n } = useTranslation();
    const { groupId } = useParams();
    const [getGroup, { data: { group = {} as Group } = {} }] = useLazyGetGroupQuery();
    const [getGroupPolicy, { data: { policy = {} as Policy } = {} }] = useLazyGetGroupPolicyQuery();
    useEffect(() => {
        if (groupId) {
            getGroup({ id: groupId });
            getGroupPolicy({ groupId });
        }
    }, [groupId]);
    return (
        <Drawer
            onClose={onClose}
            {...rest}
            shadow="lg"
            title={
                <Text weight="bold">
                    {t('Set Group Policy:')} {group?.name ?? t('Unknown')}
                </Text>
            }
        >
            <DrawerContext.Consumer>
                {({ onClose }) => <SetGroupPolicyForm groupId={groupId} groupPolicy={policy} onClose={onClose} />}
            </DrawerContext.Consumer>
        </Drawer>
    );
};

export default SetGroupPolicy;
