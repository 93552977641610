import { Continuation } from 'src/models';

export const SLICE_NAME = 'crm';

export type Contact = {
    id: string;
    crn: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    emailAddress: string;
    mainPhone: string;
    mobilePhone: string;
};

export type ContactRelation = {
    id: string;
    crn: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    emailAddress: string;
};

export type ContactAssociation = {
    id: string;
    crn: string;
    name: string;
    emailAddress: string;
    mainPhone: string;
    mobilePhone: string;
};

export type ContactCreate = {
    name: string;
    emailAddress: string;
    mainPhone: string;
    mobilePhone: string;
};

export type ContactUpdate = {
    id: string;
    name: string;
    emailAddress: string;
    mainPhone: string;
    mobilePhone: string;
};

export type GetContactsRequest = {
    returnCount?: boolean;
    order?: string;
    continue?: string;
    search?: string;
};

export type GetContactsResponse = {
    contacts: Contact[];
    continue: Continuation;
};

export type GetContactRequest = {
    id: string;
};

export type GetContactResponse = {
    contact: Contact;
};

export type DeleteContactRequest = {
    id: string;
};

export type DeleteContactResponse = {
    contact: Contact;
};

export type CreateContactRequest = {
    contact: ContactCreate;
};

export type CreateContactResponse = {
    contact: Contact;
};

export type UpdateContactRequest = {
    contact: ContactUpdate;
};

export type UpdateContactResponse = {
    contact: Contact;
};

export type LookupContactsRequest = {
    returnCount?: boolean;
    order?: string;
    continue?: string;
    search?: string;
};

export type LookupContactsResponse = {
    contacts: ContactAssociation[];
    continue: Continuation;
};

export type CrmState = {};

export type CrmStateSlice = {
    [SLICE_NAME]: CrmState;
};
