import { LatLng } from 'src/common';
import { ContactAssociation } from 'src/crm';
import { Address, Continuation } from 'src/models';

export const SLICE_NAME = 'management';

export type AdministrativeContact = {
    name?: string;
    emailAddress?: string;
    mainPhone?: string;
    mobilePhone?: string;
};

export type TechnicalContact = {
    name?: string;
    emailAddress?: string;
    mainPhone?: string;
    mobilePhone?: string;
};

export type Customer = {
    id: string;
    crn: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    logoUrl: string;
    iconUrl: string;
    administrativeContact: ContactAssociation;
    technicalContact: ContactAssociation;
};

export type CustomerRelation = {
    id: string;
    crn: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    logoUrl: string;
    iconUrl: string;
};

export type CustomerCreate = {
    name: string;
    logoUrl?: string;
    iconUrl?: string;
    administrativeContact?: Partial<ContactAssociation> | null;
    technicalContact?: Partial<ContactAssociation> | null;
};

export type CustomerUpdate = {
    id: string;
    name: string;
    logoUrl?: string;
    iconUrl?: string;
    administrativeContact?: Partial<ContactAssociation> | null;
    technicalContact?: Partial<ContactAssociation> | null;
};

export type Location = {
    id: string;
    crn: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    customer: CustomerRelation;
    address: Address;
    location: LatLng;
    logoUrl: string;
    iconUrl: string;
    administrativeContact?: ContactAssociation;
    technicalContact?: ContactAssociation;
};

export type LocationRelation = {
    id: string;
    crn: string;
    createdAt: string;
    updatedAt: string;
    name: string;

    address: Address;
    location: LatLng;
    logoUrl: string;
    iconUrl: string;
};

export type LocationCreate = {
    name: string;
    customerId: string;
    address: Address;
    location: LatLng;
    logoUrl?: string;
    iconUrl?: string;
    administrativeContact?: Partial<ContactAssociation> | null;
    technicalContact?: Partial<ContactAssociation> | null;
};

export type LocationUpdate = {
    id: string;
    name: string;
    address: Address;
    location: LatLng;
    logoUrl?: string;
    iconUrl?: string;
    administrativeContact?: Partial<ContactAssociation> | null;
    technicalContact?: Partial<ContactAssociation> | null;
};

export type Device = {
    id: string;
    crn: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    location: LocationRelation;
};

export type DeviceCreate = {
    name: string;
    locationId: string;
};

export type DeviceUpdate = {
    id: string;
    name: string;
};

export type IrisConnectionMonitor = {
    hostname?: string;
    paused?: boolean;
};

export type ConnectionMonitor = {
    iris?: IrisConnectionMonitor;
};

export type GenericConnectionDetails = {
    carrier?: string;
    carrierConnectionId?: string;
    bandwidth?: string;
    subnetMask?: string;
    gateway?: string;
    hostname?: string;
    medium?: string;
    modemMake?: string;
    modemModel?: string;
    modemMacAddress?: string;
    firstUsableIp?: string;
    lastUsableIp?: string;
    dns1?: string;
    dns2?: string;
};

export type ConnectionDetails = {
    generic?: GenericConnectionDetails;
};

export const ConnectionStatusUnknown = 'UNKNOWN' as const;
export const ConnectionStatusDown = 'DOWN' as const;
export const ConnectionStatusWarning = 'WARNING' as const;
export const ConnectionStatusUp = 'UP' as const;
export const ConnectionStatusPaused = 'PAUSED' as const;
export type ConnectionStatus =
    | typeof ConnectionStatusUnknown
    | typeof ConnectionStatusDown
    | typeof ConnectionStatusWarning
    | typeof ConnectionStatusUp
    | typeof ConnectionStatusPaused;

export type Connection = {
    id: string;
    crn: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    location?: LocationRelation;
    details?: ConnectionDetails;
    monitor?: ConnectionMonitor;
    measure?: any;
    status?: ConnectionStatus;
};

export type ConnectionRelation = {
    id: string;
    crn: string;
    createdAt: string;
    updatedAt: string;
    name: string;

    details?: ConnectionDetails;
    monitor?: ConnectionMonitor;
    measure?: any;
    status?: ConnectionStatus;
};

export type ConnectionCreate = {
    locationId: string;
    name: string;
    details: ConnectionDetails;
    monitor: ConnectionMonitor;
};

export type ConnectionUpdate = {
    id: string;
    name: string;
    details: ConnectionDetails;
    monitor: ConnectionMonitor;
    status?: ConnectionStatus;
};

export type GetCustomersRequest = {
    returnCount?: boolean;
    order?: string;
    continue?: string;
    search?: string;
};

export type GetCustomersResponse = {
    customers: Customer[];
    continue: Continuation;
};

export type GetCustomerRequest = {
    id: string;
};

export type GetCustomerResponse = {
    customer: Customer;
};

export type DeleteCustomerRequest = {
    id: string;
};

export type DeleteCustomerResponse = {
    customer: Customer;
};

export type CreateCustomerRequest = {
    customer: CustomerCreate;
};

export type CreateCustomerResponse = {
    customer: Customer;
};

export type UpdateCustomerRequest = {
    customer: CustomerUpdate;
};

export type UpdateCustomerResponse = {
    customer: Customer;
};

export type GetLocationsRequest = {
    returnCount?: boolean;
    order?: string;
    continue?: string;
    search?: string;
};

export type GetLocationsResponse = {
    locations: Location[];
    continue: Continuation;
};

export type DeleteLocationRequest = {
    id: string;
};

export type DeleteLocationResponse = {
    location: Location;
};

export type GetLocationRequest = {
    id: string;
};

export type GetLocationResponse = {
    location: Location;
};

export type CreateLocationRequest = {
    location: LocationCreate;
};

export type CreateLocationResponse = {
    location: Location;
};

export type UpdateLocationRequest = {
    location: LocationUpdate;
};

export type UpdateLocationResponse = {
    location: Location;
};

export type GetCustomerLocationsRequest = {
    customerId: string;
    returnCount?: boolean;
    order?: string;
    continue?: string;
    search?: string;
};

export type GetCustomerLocationsResponse = {
    locations: Location[];
    continue: Continuation;
};

export type GetDevicesRequest = {
    returnCount?: boolean;
    order?: string;
    continue?: string;
};

export type GetDevicesResponse = {
    devices: Device[];
    continue: Continuation;
};

export type GetDeviceRequest = {
    id: string;
};

export type GetDeviceResponse = {
    device: Device;
};

export type CreateDeviceRequest = {
    device: DeviceCreate;
};

export type CreateDeviceResponse = {
    device: Device;
};

export type UpdateDeviceRequest = {
    device: DeviceUpdate;
};

export type UpdateDeviceResponse = {
    device: Device;
};

export type GetLocationDevicesRequest = {
    locationId: string;
    returnCount?: boolean;
    order?: string;
    continue?: string;
    search?: string;
};

export type GetLocationDevicesResponse = {
    devices: Device[];
    continue: Continuation;
};

export type GetConnectionsRequest = {
    returnCount?: boolean;
    order?: string;
    continue?: string;
    search?: string;
};

export type GetConnectionsResponse = {
    connections: Connection[];
    continue: Continuation;
};

export type GetConnectionRequest = {
    id: string;
};

export type GetConnectionResponse = {
    connection: Connection;
};

export type DeleteConnectionRequest = {
    id: string;
};

export type DeleteConnectionResponse = {
    connection: Connection;
};

export type CreateConnectionRequest = {
    connection: ConnectionCreate;
};

export type CreateConnectionResponse = {
    connection: Connection;
};

export type UpdateConnectionRequest = {
    connection: ConnectionUpdate;
};

export type UpdateConnectionResponse = {
    connection: Connection;
};

export type GetLocationConnectionsRequest = {
    locationId: string;
    returnCount?: boolean;
    order?: string;
    continue?: string;
    search?: string;
};

export type GetLocationConnectionsResponse = {
    connections: Connection[];
    continue: Continuation;
};

export type GetConnectionAvailabilityRequest = {
    connectionId: string;
};

export type GetConnectionAvailabilityResponse = {
    count: number;
    recv: number[];
    loss: number[];
    miss: number[];
    start: number;
    end: number;
    step: number;
};

export type GetConnectionLatencyRequest = {
    connectionId: string;
};

export type GetConnectionLatencyResponse = {
    count: number;
    rttA: number[];
    rttM: number[];
    start: number;
    end: number;
    step: number;
};

export type GetConnectionJitterRequest = {
    connectionId: string;
};

export type GetConnectionJitterResponse = {
    count: number;
    rttS: number[];
    start: number;
    end: number;
    step: number;
};

export type DashboardProblem = {
    customer: CustomerRelation;
    location: LocationRelation;
    connection?: ConnectionRelation;
};

export type GetDashboardRequest = {};

export type GetDashboardResponse = {
    connectionsCount: number;
    connectionsUp: number;
    connectionsDown: number;
    connectionsWarning: number;
    connectionsUnknown: number;
    connectionsPaused: number;
    errConnections: Connection[];
    problems: DashboardProblem[];
};

export type GetDashboardMapMarkersRequest = {};

export type DashboardMapMarker = {
    customer: CustomerRelation;
    location: LocationRelation;
    status: ConnectionStatus;
    upCount: number;
    totalCount: number;
};

export type GetDashboardMapMarkersResponse = {
    markers: DashboardMapMarker[];
};

export type CreateCustomerAttachmentRequest = {
    customerId: string;
    as: string;
    file: File;
};

export type CreateCustomerAttachmentResponse = {};

export type CreateLocationAttachmentRequest = {
    locationId: string;
    as: string;
    file: File;
};

export type CreateLocationAttachmentResponse = {};

export type ManagementState = {};

export type ManagementStateSlice = {
    [SLICE_NAME]: ManagementState;
};
