import { getErrorMessage } from '@/errors';
import { Accordion, Alert, Button, Code, Group, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle, IconDeviceFloppy } from '@tabler/icons';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateLocationMutation } from '@/management/api';
import { TechnicalContact, AdministrativeContact, Customer } from '@/management/models';
import { Address } from '@/models';
import AddressInput from '@/common/components/AddressInput';
import { useCustomerSelectData } from 'src/management/hooks';
import { $F, useFeatureCheck } from 'src/identity';
import LocationInput from 'src/common/components/LatLngInput';
import { LatLng } from 'src/common';
import { useContactSelectData } from 'src/crm';

export interface CreateLocationFormProps {
    customer?: Customer;
    customerId?: string;
    onClose?: () => void;
}

type CreateLocationFormValues = {
    name: string;
    address: Address;
    location: LatLng;
    customerId: string;
    administrativeContact: AdministrativeContact;
    technicalContact: TechnicalContact;
};

export const CreateLocationForm = ({ customer, customerId, onClose }: CreateLocationFormProps) => {
    const { t, i18n } = useTranslation();
    const feat = useFeatureCheck();
    const form = useForm<CreateLocationFormValues>({
        initialValues: {
            name: '',
            address: {},
            location: {},
            customerId: '',
            administrativeContact: {},
            technicalContact: {},
        },
        validate: {
            name: (value) => (value.length < 1 ? t('Name is required') : null),
        },
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [createLocation, { isLoading: isSaving }] = useCreateLocationMutation();
    const [customerSelectData, setCustomerSearch, customerSearch] = useCustomerSelectData();
    const onSubmit = useCallback(
        async ({
            name,
            address,
            location,
            customerId: formCustomerId,
            administrativeContact,
            technicalContact,
        }: CreateLocationFormValues) => {
            try {
                const res = await createLocation({
                    location: {
                        customerId: customerId ?? formCustomerId,
                        name,
                        address,
                        location,
                        administrativeContact,
                        technicalContact,
                    },
                }).unwrap();
                if (onClose) {
                    onClose();
                }
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
            }
        },
        [createLocation, onClose],
    );

    const [contactSelectData, setContactSearch, contactSearch] = useContactSelectData();
    const [contactSelectDataTech, setContactSearchTech, contactSearchTech] = useContactSelectData();

    return (
        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
            {customerId ? (
                <TextInput label={t('Customer')} disabled value={customer?.name ?? t('Unknown')} />
            ) : (
                <Select
                    data-autofocus={true}
                    label={t('Customer')}
                    placeholder={t('Search for customer')}
                    data={customerSelectData}
                    required
                    searchable
                    onSearchChange={setCustomerSearch}
                    nothingFound={customerSearch ? t('No customers found') : t('Start typing to search')}
                    {...form.getInputProps('customerId')}
                />
            )}
            <TextInput
                mt="md"
                data-autofocus={customerId ? true : undefined}
                required
                label={t('Name')}
                placeholder={t('Name')}
                {...form.getInputProps('name')}
            />
            <Accordion
                variant="separated"
                mt="md"
                multiple
                defaultValue={['administrativeContact', 'technicalContact', 'address', 'location']}
            >
                <Accordion.Item value="administrativeContact">
                    <Accordion.Control>{t('Administrative Contact')}</Accordion.Control>
                    <Accordion.Panel>
                        <Select
                            placeholder={t('Search for contact')}
                            data={contactSelectData}
                            required
                            searchable
                            onSearchChange={setContactSearch}
                            nothingFound={contactSearch ? t('No contacts found') : t('Start typing to search')}
                            {...form.getInputProps('administrativeContact.id')}
                            clearable
                        />
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="technicalContact">
                    <Accordion.Control>{t('Technical Contact')}</Accordion.Control>
                    <Accordion.Panel>
                        <Select
                            placeholder={t('Search for contact')}
                            data={contactSelectDataTech}
                            required
                            searchable
                            onSearchChange={setContactSearchTech}
                            nothingFound={contactSearchTech ? t('No contacts found') : t('Start typing to search')}
                            {...form.getInputProps('technicalContact.id')}
                            clearable
                        />
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="address">
                    <Accordion.Control>{t('Address')}</Accordion.Control>
                    <Accordion.Panel>
                        <AddressInput mt="md" {...form.getInputProps('address')} />
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="location">
                    <Accordion.Control>{t('Location')}</Accordion.Control>
                    <Accordion.Panel>
                        <LocationInput mt="md" {...form.getInputProps('location')} />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            {errorMessage ? (
                <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                    {errorMessage}
                </Alert>
            ) : null}
            <Group position="right" mt={'md'}>
                <Button color={'gray'} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button type="submit" color={'primary'} loading={isSaving} leftIcon={<IconDeviceFloppy />}>
                    {t('Save')}
                </Button>
            </Group>
            {feat($F.FormDebug) ? (
                <Code mt="md" block>
                    {JSON.stringify(form.values, null, 2)}
                </Code>
            ) : null}
        </form>
    );
};

export default CreateLocationForm;
