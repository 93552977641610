import { Button, Card, Group, Menu, Stack, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import View from '@/common/components/View';
import { useParams } from 'react-router';
import { useGetTicketQuery } from '@/support/api';
import { Ticket as TicketType } from '@/support/models';
import { IconDots, IconExternalLink } from '@tabler/icons';
import { parseISO, formatRelative } from 'date-fns';
import LabeledText from 'src/common/components/LabeledText';
import TicketStatusBadge from '../TicketStatusBadge';
import TicketTypeBadge from '../TicketTypeBadge';
import TicketPriorityBadge from '../TicketPriorityBadge';
import { useAllowsActionOnResource } from 'src/identity';
import * as $P from '@/identity/policy';

export const Ticket = () => {
    const { ticketId } = useParams();
    const { t } = useTranslation();
    const allowsActionOnResource = useAllowsActionOnResource();
    const { data: { ticket = {} as TicketType } = {} } = useGetTicketQuery({
        id: ticketId!,
    });
    return (
        <View
            header={
                <Group position="apart" sx={{ minHeight: 36 }}>
                    <Title order={3}>
                        {t('Ticket')} #{ticket.id}
                    </Title>
                    <Group position="right">
                        {allowsActionOnResource($P.CanManageTicket, $P.TicketResourceFmt(ticketId!)) ? (
                            <a
                                href={'https://cloverleafnetworks.zendesk.com/agent/tickets/' + ticket.id}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button variant="light" color="yellow" px="xs">
                                    <IconExternalLink />
                                </Button>
                            </a>
                        ) : null}
                        <Menu position="left-start" shadow="md" width={200}>
                            <Menu.Target>
                                <Button variant="light" color="yellow" px="xs">
                                    <IconDots />
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>Actions</Menu.Label>
                            </Menu.Dropdown>
                        </Menu>
                    </Group>
                </Group>
            }
        >
            <Card.Section>
                <Group position="left" m="md" align="flex-start">
                    <Stack spacing="xs">
                        <Group position="left">
                            <LabeledText label={t('Subject')}>{ticket.subject ?? t('Unknown')}</LabeledText>
                        </Group>
                        <Group position="left">
                            <LabeledText label={t('Priority')}>
                                <TicketPriorityBadge priority={ticket.priority} />
                            </LabeledText>
                        </Group>
                        <Group position="left">
                            <LabeledText label={t('Status')}>
                                <TicketStatusBadge status={ticket.status} />
                            </LabeledText>
                        </Group>
                        <Group position="left">
                            <LabeledText label={t('Type')}>
                                <TicketTypeBadge type={ticket.type} />
                            </LabeledText>
                        </Group>
                        <Group position="left">
                            <LabeledText label={t('Created')}>
                                {!isNaN(parseISO(ticket.createdAt).getTime())
                                    ? formatRelative(parseISO(ticket.createdAt), new Date())
                                    : ''}
                            </LabeledText>
                        </Group>
                        <Group position="left">
                            <LabeledText label={t('Updated')}>
                                {!isNaN(parseISO(ticket.updatedAt).getTime())
                                    ? formatRelative(parseISO(ticket.updatedAt), new Date())
                                    : ''}
                            </LabeledText>
                        </Group>
                    </Stack>
                </Group>
            </Card.Section>
        </View>
    );
};

export default Ticket;
