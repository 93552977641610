import { getErrorMessage } from '@/errors';
import { Accordion, Alert, Button, Code, Group, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle, IconDeviceFloppy } from '@tabler/icons';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContactSelectData } from 'src/crm';
import { $F, useFeatureCheck } from 'src/identity';
import { useCreateCustomerMutation } from 'src/management/api';
import { AdministrativeContact, TechnicalContact } from 'src/management/models';

export interface CreateCustomerFormProps {
    onClose?: () => void;
}

type CreateCustomerFormValues = {
    name: string;
    administrativeContact: AdministrativeContact;
    technicalContact: TechnicalContact;
};

export const CreateCustomerForm = ({ onClose }: CreateCustomerFormProps) => {
    const { t, i18n } = useTranslation();
    const feat = useFeatureCheck();
    const form = useForm<CreateCustomerFormValues>({
        initialValues: {
            name: '',
            administrativeContact: {},
            technicalContact: {},
        },
        validate: {
            name: (value) => (value.length < 1 ? t('Name is required') : null),
        },
    });

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [createCustomer, { isLoading, isSuccess, isError }] = useCreateCustomerMutation();

    const onSubmit = useCallback(
        async ({ name, administrativeContact, technicalContact }: CreateCustomerFormValues) => {
            try {
                const res = await createCustomer({
                    customer: {
                        name,
                        administrativeContact,
                        technicalContact,
                    },
                }).unwrap();
                if (onClose) {
                    onClose();
                }
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
            }
        },
        [createCustomer, onClose],
    );

    const [contactSelectData, setContactSearch, contactSearch] = useContactSelectData();
    const [contactSelectDataTech, setContactSearchTech, contactSearchTech] = useContactSelectData();

    return (
        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
            <TextInput
                data-autofocus
                required
                label={t('Name')}
                placeholder={t('Name')}
                {...form.getInputProps('name')}
            />
            <Accordion variant="separated" mt="md" multiple defaultValue={['administrativeContact']}>
                <Accordion.Item value="administrativeContact">
                    <Accordion.Control>{t('Administrative Contact')}</Accordion.Control>
                    <Accordion.Panel>
                        <Select
                            placeholder={t('Search for contact')}
                            data={contactSelectData}
                            required
                            searchable
                            onSearchChange={setContactSearch}
                            nothingFound={contactSearch ? t('No contacts found') : t('Start typing to search')}
                            {...form.getInputProps('administrativeContact.id')}
                            clearable
                        />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            <Accordion variant="separated" mt="md" multiple defaultValue={['technicalContact']}>
                <Accordion.Item value="technicalContact">
                    <Accordion.Control>{t('Technical Contact')}</Accordion.Control>
                    <Accordion.Panel>
                        <Select
                            placeholder={t('Search for contact')}
                            data={contactSelectDataTech}
                            required
                            searchable
                            onSearchChange={setContactSearchTech}
                            nothingFound={contactSearchTech ? t('No contacts found') : t('Start typing to search')}
                            {...form.getInputProps('technicalContact.id')}
                            clearable
                        />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            {errorMessage ? (
                <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                    {errorMessage}
                </Alert>
            ) : null}
            <Group position="right" mt={'md'}>
                <Button color={'gray'} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button type="submit" color={'primary'} loading={isLoading} leftIcon={<IconDeviceFloppy />}>
                    {t('Save')}
                </Button>
            </Group>
            {feat($F.FormDebug) ? (
                <Code mt="md" block>
                    {JSON.stringify(form.values, null, 2)}
                </Code>
            ) : null}
        </form>
    );
};

export default CreateCustomerForm;
