import {
    DrawerProps as BaseDrawerProps,
    Drawer as BaseDrawer,
    useComponentDefaultProps,
    packSx,
    Box,
    ScrollArea,
} from '@mantine/core';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

const NOOP = () => {};

export type DrawerContextValue = {
    onClose: () => void;
};
export const DrawerContext = createContext<DrawerContextValue>({
    onClose: NOOP,
});
export const useDrawerContext = () => {
    return useContext(DrawerContext);
};

export interface DrawerProps extends BaseDrawerProps {}

const componentName = 'CLUIDrawer';
const defaultProps: Partial<DrawerProps> = {};

export const Drawer = (props: DrawerProps) => {
    const {
        opened: req,
        onClose: reqOnClose,
        transitionDuration = 250,
        children,
        classNames,
        sx,
        ...rest
    } = useComponentDefaultProps(componentName, defaultProps, props);
    const [opened, setOpened] = useState(false);
    const onClose = useCallback(() => {
        setOpened(false);
        if (reqOnClose) {
            setTimeout(reqOnClose, transitionDuration);
        }
    }, [setOpened, reqOnClose, transitionDuration]);
    const contextValue = useMemo(() => ({ onClose }), [onClose]);
    useEffect(() => setOpened(req), []);

    return (
        <BaseDrawer
            opened={opened}
            onClose={onClose}
            transitionDuration={transitionDuration}
            sx={[
                {
                    ['& .mantine-Paper-root']: {
                        display: 'flex',
                        flexDirection: 'column',
                    },
                },
                ...packSx(sx),
            ]}
            {...rest}
        >
            <Box sx={{ flexGrow: 1, position: 'relative' }}>
                <ScrollArea
                    type="auto"
                    offsetScrollbars
                    sx={{
                        position: 'absolute !important' as 'absolute',
                        top: 0,
                        bottom: 0,
                        width: `calc(100% + 10px)`,
                        ['& [data-orientation="vertical"]']: {
                            transform: 'translateX(2px)',
                        },
                    }}
                >
                    <DrawerContext.Provider value={contextValue}>{children}</DrawerContext.Provider>
                </ScrollArea>
            </Box>
        </BaseDrawer>
    );
};

export default Drawer;
