import { Card, Stack, useComponentDefaultProps } from '@mantine/core';

export interface ViewStyleParams {}
export interface ViewProps {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    children: React.ReactNode;
}

const componentName = 'CLUIView';
const defaultProps: Partial<ViewProps> = {};

export const View = (props: ViewProps) => {
    const { header, footer, children, ...rest } = useComponentDefaultProps(componentName, defaultProps, props);
    return (
        <Stack align="stretch" justify="flex-start" spacing="xs" sx={{ height: '100%' }} {...rest}>
            {header}
            <Card withBorder shadow="sm" p="sm" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                {children}
                {footer}
            </Card>
        </Stack>
    );
};

export default View;
