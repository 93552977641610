import { Center, Stack, useMantineTheme } from '@mantine/core';
import { Connection, ConnectionStatusUnknown } from '@/management/models';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import Chart, { EChartsOption } from '@/common/components/Chart';
import { useGetConnectionAvailabilityQuery, useGetConnectionLatencyQuery } from '@/management/api';

export interface ConnectionStatusProps {
    connection?: Connection;
    connectionId?: string;
}

export const ConnectionStatus = ({ connection, connectionId, ...rest }: ConnectionStatusProps) => {
    const theme = useMantineTheme();
    const { t, i18n } = useTranslation();
    const { data: { start: rttStart = 0, step: rttStep = 0, end: rttEnd = 0, rttA = [] } = {} } =
        useGetConnectionLatencyQuery(
            { connectionId: connectionId! },
            {
                pollingInterval: 2.5 * 60 * 1000, // 2.5min
            },
        );
    const { data: { start: avaStart = 0, step: avaStep = 0, end: avaEnd = 0, recv = [], loss = [], miss = [] } = {} } =
        useGetConnectionAvailabilityQuery(
            { connectionId: connectionId! },
            {
                pollingInterval: 2.5 * 60 * 1000, // 2.5min
            },
        );
    const data = useMemo(() => {
        const num = 4;
        const count = rttA.length;
        const d = new Float64Array(count * num);
        for (let i = 0; i < count; i++) {
            d[i * num + 0] = (rttStart + i * rttStep) * 1000;
            d[i * num + 1] = Math.round(rttA[i] * 100) / 100;
            d[i * num + 2] = recv[i];
            d[i * num + 3] = loss[i];
        }
        return d;
    }, [rttStart, rttStep, rttA, recv, loss, miss]);
    useEffect(() => {
        if (rttStart === 0 || avaStart === 0) {
            return;
        }
        if (Math.abs(rttStart - avaStart) > ((rttStep + avaStep) / 2) * 2) {
            console.warn('rttStart != avaStart');
        }
    }, [rttStart, rttStep, avaStart, avaStep]);
    const option = useMemo<EChartsOption>(
        () => ({
            dataset: {
                source: data,
                dimensions: ['date', 'rtta', 'recv', 'loss'],
            },
            xAxis: {
                type: 'time',
            },
            yAxis: [
                {
                    type: 'value',
                    interval: 20,
                    axisLabel: {
                        formatter: '{value} ms',
                    },
                },
                {
                    type: 'value',
                    min: 0,
                    max: 100,
                    interval: 20,
                    axisLabel: {
                        formatter: '{value} %',
                    },
                    splitLine: {
                        show: false,
                    },
                },
            ],
            grid: {
                containLabel: true,
                left: 24,
                top: 64,
                right: 24,
                bottom: 48,
            },
            legend: {
                top: 24,
            },
            tooltip: {
                trigger: 'axis',
            },
            dataZoom: [
                {
                    type: 'inside',
                    xAxisIndex: 0,
                    minSpan: 5,
                },
                {
                    type: 'slider',
                    xAxisIndex: 0,
                    minSpan: 5,
                    left: 24,
                    right: 24,
                    showDetail: false,
                },
            ],
            series: [
                {
                    name: 'Latency',
                    z: 1,
                    type: 'line',
                    sampling: 'lttb',
                    showSymbol: false,
                    lineStyle: { width: 2 },
                    emphasis: { lineStyle: { width: 2 } },
                    encode: {
                        x: 'date',
                        y: 'rtta',
                    },
                },
                {
                    name: 'Availability',
                    z: 1,
                    yAxisIndex: 1,
                    type: 'line',
                    sampling: 'lttb',
                    showSymbol: false,
                    lineStyle: { width: 2 },
                    emphasis: { lineStyle: { width: 2 } },
                    encode: {
                        x: 'date',
                        y: 'recv',
                    },
                },
                {
                    name: 'Packet Loss',
                    z: 1,
                    yAxisIndex: 1,
                    type: 'line',
                    sampling: 'lttb',
                    showSymbol: false,
                    lineStyle: { width: 2 },
                    emphasis: { lineStyle: { width: 2 } },
                    encode: {
                        x: 'date',
                        y: 'loss',
                    },
                },
            ],
        }),
        [data],
    );

    if (!(connection?.status && connection.status !== ConnectionStatusUnknown)) {
        return <Center sx={{ height: '100%' }}>{t('Connection status is UNKNOWN.')}</Center>;
    }

    return (
        <Stack spacing={0} align="stretch" justify="flex-start" sx={{ height: '100%' }} p="md" {...rest}>
            <Chart sx={{ height: '100%', borderRadius: theme.radius.md }} option={option} />
        </Stack>
    );
};
