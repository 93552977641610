import { getErrorMessage } from '@/errors';
import { Alert, Button, Group, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle, IconDeviceFloppy } from '@tabler/icons';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetGroupPolicyMutation } from 'src/identity/api';
import { Policy } from 'src/identity/models';

export interface SetGroupPolicyFormProps {
    onClose?: () => void;
    groupId?: string;
    groupPolicy?: Policy;
}

type SetGroupPolicyFormValues = {
    json: string;
};

export const SetGroupPolicyForm = ({ groupId, groupPolicy, onClose }: SetGroupPolicyFormProps) => {
    const { t, i18n } = useTranslation();
    const form = useForm<SetGroupPolicyFormValues>({
        initialValues: {
            json: '',
        },
        validate: {
            json: (value) => (value.length < 1 ? t('JSON is required') : null),
        },
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [setGroupPolicy, { isLoading, isSuccess, isError }] = useSetGroupPolicyMutation();

    const onSubmit = useCallback(
        async ({ json }: SetGroupPolicyFormValues) => {
            try {
                const policy = JSON.parse(json);
                const res = await setGroupPolicy({
                    groupId: groupId!,
                    policy,
                }).unwrap();
                if (onClose) {
                    onClose();
                }
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
            }
        },
        [setGroupPolicy, onClose],
    );

    useEffect(() => {
        if (groupPolicy) {
            form.setValues({
                json: JSON.stringify(groupPolicy, null, 2),
            });
        }
    }, [groupPolicy]);

    return (
        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
            <Textarea
                data-autofocus
                autosize
                required
                spellCheck={false}
                label={t('Policy')}
                placeholder={t('Policy')}
                {...form.getInputProps('json')}
                styles={{
                    input: {
                        fontFamily: 'monospace, monospace',
                        overflow: 'hidden',
                    },
                }}
            />
            {errorMessage ? (
                <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                    {errorMessage}
                </Alert>
            ) : null}
            <Group position="right" mt={'md'}>
                <Button color={'gray'} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button type="submit" color={'primary'} loading={isLoading} leftIcon={<IconDeviceFloppy />}>
                    {t('Save')}
                </Button>
            </Group>
        </form>
    );
};

export default SetGroupPolicyForm;
