import { getErrorMessage } from '@/errors';
import { Alert, Button, Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle, IconDeviceFloppy } from '@tabler/icons';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateContactMutation } from '../../api';
import { Contact } from '../../models';
import { validatePhoneNumber, formatPhoneNumber } from '../../utils';

export interface UpdateContactFormProps {
    contact: Contact;
    contactId: string;
    onClose?: () => void;
}

type UpdateContactFormValues = {
    name: string;
    emailAddress: string;
    mainPhone: string;
    mobilePhone: string;
};

export const UpdateContactForm = ({ contact, contactId, onClose }: UpdateContactFormProps) => {
    const { t, i18n } = useTranslation();
    const form = useForm<UpdateContactFormValues>({
        validateInputOnChange: true,
        initialValues: {
            name: '',
            emailAddress: '',
            mainPhone: '',
            mobilePhone: '',
        },
        validate: {
            name: (value) => (value.length < 1 ? t('Name is required') : null),
            mainPhone: (value) => validatePhoneNumber(value),
            mobilePhone: (value) => validatePhoneNumber(value),
        },
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [updateContact, { isLoading, isSuccess, isError }] = useUpdateContactMutation();

    const onSubmit = useCallback(
        async ({ name, emailAddress, mainPhone, mobilePhone }: UpdateContactFormValues) => {
            try {
                mainPhone = formatPhoneNumber(mainPhone);
                mobilePhone = formatPhoneNumber(mobilePhone);
                const res = await updateContact({
                    contact: {
                        id: contactId,
                        name,
                        emailAddress,
                        mainPhone,
                        mobilePhone,
                    },
                }).unwrap();
                if (onClose) {
                    onClose();
                }
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
            }
        },
        [updateContact, setErrorMessage, onClose],
    );

    useEffect(() => {
        if (contact) {
            form.setValues({
                name: contact.name,
                emailAddress: contact.emailAddress,
                mainPhone: contact.mainPhone,
                mobilePhone: contact.mobilePhone,
            });
        }
    }, [contact]);

    return (
        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
            <TextInput
                data-autofocus
                required
                label={t('Name')}
                placeholder={t('Name')}
                {...form.getInputProps('name')}
            />
            <TextInput
                label={t('Email Address')}
                placeholder={t('user@email.com')}
                {...form.getInputProps('emailAddress')}
            />
            <TextInput label={t('Main Phone')} placeholder={t('602-704-8000')} {...form.getInputProps('mainPhone')} />
            <TextInput
                label={t('Mobile Phone')}
                placeholder={t('602-704-9000')}
                {...form.getInputProps('mobilePhone')}
            />
            {errorMessage ? (
                <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                    {errorMessage}
                </Alert>
            ) : null}
            <Group position="right" mt={'md'}>
                <Button color={'gray'} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button type="submit" color={'primary'} loading={isLoading} leftIcon={<IconDeviceFloppy />}>
                    {t('Save')}
                </Button>
            </Group>
        </form>
    );
};

export default UpdateContactForm;
