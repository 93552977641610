import { AppShell, Burger, createStyles, MediaQuery } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router';
import Header from '../Header';
import AppNav from '../Nav';
import { RequiresAuth } from '@/identity/components/RequiresAuth';
import Login from '@/identity/components/Login';
import PasswordReset from '@/identity/components/PasswordReset';
import NewPassword from '@/identity/components/NewPassword';
import Users from '@/identity/components/Users';
import CreateUser from '@/identity/components/CreateUser';
import Customers from '@/management/components/Customers';
import CreateCustomer from '@/management/components/CreateCustomer';
import Customer from '@/management/components/Customer';
import Locations from '@/management/components/Locations';
import Location from '@/management/components/Location';
import Connections from '@/management/components/Connections';
import Connection from '@/management/components/Connection';
import CreateLocation from '@/management/components/CreateLocation';
import Dashboard from '@/management/components/Dashboard';
import Projects from '@/support/components/Projects';
import Project from '@/support/components/Project';
import Tickets from '@/support/components/Tickets';
import User from '@/identity/components/User';
import UpdateUser from '@/identity/components/UpdateUser';
import Ticket from '@/support/components/Ticket';
import UpdateLocation from '@/management/components/UpdateLocation';
import UpdateCustomer from '@/management/components/UpdateCustomer';
import CreateConnection from '@/management/components/CreateConnection';
import UpdateConnection from '@/management/components/UpdateConnection';
import { useState } from 'react';
import { useShallowEffect } from '@mantine/hooks';
import Group from '@/identity/components/Group';
import SetGroupPolicy from '@/identity/components/SetGroupPolicy';
import SetUserPolicy from '@/identity/components/SetUserPolicy';
import Groups from '@/identity/components/Groups';
import Contact from '@/crm/components/Contact';
import Contacts from '@/crm/components/Contacts';
import CreateContact from '@/crm/components/CreateContact';
import UpdateContact from '@/crm/components/UpdateContact';

export interface AppProps {}

const useStyles = createStyles((theme, params, getRef) => {
    return {
        root: {},
    };
});

export const App = ({}: AppProps) => {
    const { classes, cx, theme } = useStyles();
    const { t, i18n } = useTranslation();
    const [opened, setOpened] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // The `backgroundLocation` state is the location that we were at when one of
    // the gallery links was clicked. If it's there, use it as the location for
    // the <Routes> so we show the gallery in the background, behind the modal.
    const state = location.state as { backgroundLocation?: Location };

    function onDismiss() {
        navigate(-1);
    }

    useShallowEffect(() => {
        setOpened(false);
    }, [location]);

    return (
        <>
            <Routes location={state?.backgroundLocation || location}>
                <Route path="login" element={<Login />} />
                <Route path="password_reset" element={<PasswordReset />} />
                <Route path="password-reset/verify" element={<NewPassword />} />
                <Route
                    path="*"
                    element={
                        <RequiresAuth>
                            <AppShell
                                sx={{ width: '100vw' }}
                                padding="xl"
                                navbarOffsetBreakpoint="md"
                                header={
                                    <Header
                                        height={64}
                                        navButton={
                                            <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                                                <Burger
                                                    opened={opened}
                                                    onClick={() => setOpened((o) => !o)}
                                                    size="md"
                                                    color={theme.colors.gray[6]}
                                                    ml="sm"
                                                />
                                            </MediaQuery>
                                        }
                                    />
                                }
                                navbar={
                                    <AppNav
                                        // Breakpoint at which navbar will be hidden if hidden prop is true
                                        hiddenBreakpoint="md"
                                        // Hides navbar when viewport size is less than value specified in hiddenBreakpoint
                                        hidden={!opened}
                                        // when viewport size is less than theme.breakpoints.sm navbar width is 100%
                                        // viewport size > theme.breakpoints.sm – width is 300px
                                        // viewport size > theme.breakpoints.lg – width is 400px
                                        width={{ sm: 256, lg: 256 }}
                                    />
                                }
                            >
                                <Outlet />
                            </AppShell>
                        </RequiresAuth>
                    }
                >
                    <Route path="users">
                        <Route path=":userId" element={<User />} />
                        <Route path=":userId/:tab" element={<User />} />
                        <Route index element={<Users />} />
                    </Route>
                    <Route path="groups">
                        <Route path=":groupId" element={<Group />} />
                        <Route path=":groupId/:tab" element={<Group />} />
                        <Route index element={<Groups />} />
                    </Route>
                    <Route path="customers">
                        <Route path=":customerId" element={<Customer />} />
                        <Route path=":customerId/:tab" element={<Customer />} />
                        <Route index element={<Customers />} />
                    </Route>
                    <Route path="locations">
                        <Route path=":locationId" element={<Location />} />
                        <Route path=":locationId/:tab" element={<Location />} />
                        <Route index element={<Locations />} />
                    </Route>
                    <Route path="connections">
                        <Route path=":connectionId" element={<Connection />} />
                        <Route path=":connectionId/:tab" element={<Connection />} />
                        <Route index element={<Connections />} />
                    </Route>
                    <Route path="projects">
                        <Route path=":projectId" element={<Project />} />
                        <Route path=":projectId/:tab" element={<Project />} />
                        <Route index element={<Projects />} />
                    </Route>
                    <Route path="tickets">
                        <Route path=":ticketId" element={<Ticket />} />
                        <Route index element={<Tickets />} />
                    </Route>
                    <Route path="contacts">
                        <Route path=":contactId" element={<Contact />} />
                        <Route path=":contactId/:tab" element={<Contact />} />
                        <Route index element={<Contacts />} />
                    </Route>
                    <Route index element={<Dashboard />} />
                </Route>
            </Routes>
            {/* Show the modal when a `backgroundLocation` is set */}
            {state?.backgroundLocation && (
                <Routes>
                    <Route path="users/new" element={<CreateUser opened={true} onClose={onDismiss} />} />
                    <Route path="users/:userId/edit" element={<UpdateUser opened={true} onClose={onDismiss} />} />
                    <Route path="customers/new" element={<CreateCustomer opened={true} onClose={onDismiss} />} />
                    <Route
                        path="customers/:customerId/edit"
                        element={<UpdateCustomer opened={true} onClose={onDismiss} />}
                    />
                    <Route path="locations/new" element={<CreateLocation opened={true} onClose={onDismiss} />} />
                    <Route
                        path="customers/:customerId/locations/new"
                        element={<CreateLocation opened={true} onClose={onDismiss} />}
                    />
                    <Route
                        path="locations/:locationId/edit"
                        element={<UpdateLocation opened={true} onClose={onDismiss} />}
                    />
                    <Route path="connections/new" element={<CreateConnection opened={true} onClose={onDismiss} />} />
                    <Route
                        path="locations/:locationId/connections/new"
                        element={<CreateConnection opened={true} onClose={onDismiss} />}
                    />
                    <Route
                        path="connections/:connectionId/edit"
                        element={<UpdateConnection opened={true} onClose={onDismiss} />}
                    />
                    <Route
                        path="users/:userId/policy/set"
                        element={<SetUserPolicy opened={true} onClose={onDismiss} />}
                    />
                    <Route
                        path="groups/:groupId/policy/set"
                        element={<SetGroupPolicy opened={true} onClose={onDismiss} />}
                    />
                    <Route path="contacts/new" element={<CreateContact opened={true} onClose={onDismiss} />} />
                    <Route
                        path="contacts/:contactId/edit"
                        element={<UpdateContact opened={true} onClose={onDismiss} />}
                    />
                </Routes>
            )}
        </>
    );
};

export default App;
