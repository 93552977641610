import { Box, createStyles, DefaultProps, Group, Selectors, TextInput, useComponentDefaultProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { ConnectionDetails } from '@/management';
import { useControlledForm } from 'src/common/hooks';

export interface ConnectionDetailsInputStyleParams {}

const useStyles = createStyles((theme, params: ConnectionDetailsInputStyleParams, getRef) => {
    return {
        root: {},
    };
});

type ConnectionDetailsInputStyleNames = Selectors<typeof useStyles>;

export interface ConnectionDetailsInputProps
    extends DefaultProps<ConnectionDetailsInputStyleNames, ConnectionDetailsInputStyleParams> {
    value?: ConnectionDetails;
    onChange?: (value: ConnectionDetails) => void;
}

const componentName = 'CLUIConnectionDetailsInput';
const defaultProps: Partial<ConnectionDetailsInputProps> = {};
const defaultValues: Partial<ConnectionDetails> = {
    generic: {
        carrier: '',
        carrierConnectionId: '',
        bandwidth: '',
        subnetMask: '',
        gateway: '',
        hostname: '',
        medium: '',
        modemMake: '',
        modemModel: '',
        modemMacAddress: '',
        firstUsableIp: '',
        lastUsableIp: '',
        dns1: '',
        dns2: '',
    },
};

export const ConnectionDetailsInput = (props: ConnectionDetailsInputProps) => {
    const { className, classNames, styles, unstyled, value, onChange, ...rest } = useComponentDefaultProps(
        componentName,
        defaultProps,
        props,
    );
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    const form = useControlledForm({
        defaultValues,
        value,
        onChange,
    });
    return (
        <Box className={cx(classes.root, className)} {...rest}>
            <Group position="apart" grow sx={{ flexWrap: 'nowrap' }}>
                <TextInput
                    label={t('Underlying Carrier')}
                    placeholder={t('')}
                    {...form.getInputProps('generic.carrier')}
                />
                <TextInput
                    label={t('Carrier Connection ID')}
                    placeholder={t('')}
                    {...form.getInputProps('generic.carrierConnectionId')}
                />
            </Group>
            <Group position="apart" grow sx={{ flexWrap: 'nowrap' }}>
                <TextInput label={t('Medium')} placeholder={t('')} {...form.getInputProps('generic.medium')} />
                <TextInput label={t('Bandwidth')} placeholder={t('')} {...form.getInputProps('generic.bandwidth')} />
            </Group>
            <Group position="apart" grow sx={{ flexWrap: 'nowrap' }}>
                <TextInput label={t('Modem Make')} placeholder={t('')} {...form.getInputProps('generic.modemMake')} />
                <TextInput label={t('Modem Model')} placeholder={t('')} {...form.getInputProps('generic.modemModel')} />
            </Group>
            <Group position="apart" grow sx={{ flexWrap: 'nowrap' }}>
                <TextInput
                    label={t('Modem MAC Address')}
                    placeholder={t('')}
                    {...form.getInputProps('generic.modemMacAddress')}
                />
                <Box />
            </Group>
            <Group position="apart" grow sx={{ flexWrap: 'nowrap' }}>
                <TextInput label={t('Subnet Mask')} placeholder={t('')} {...form.getInputProps('generic.subnetMask')} />
                <TextInput label={t('Gateway')} placeholder={t('')} {...form.getInputProps('generic.gateway')} />
            </Group>
            <Group position="apart" grow sx={{ flexWrap: 'nowrap' }}>
                <TextInput
                    label={t('First Usable IP')}
                    placeholder={t('')}
                    {...form.getInputProps('generic.firstUsableIp')}
                />
                <TextInput
                    label={t('Last Usable IP')}
                    placeholder={t('')}
                    {...form.getInputProps('generic.lastUsableIp')}
                />
            </Group>
            <Group position="apart" grow sx={{ flexWrap: 'nowrap' }}>
                <TextInput label={t('DNS 1')} placeholder={t('')} {...form.getInputProps('generic.dns1')} />
                <TextInput label={t('DNS 2')} placeholder={t('')} {...form.getInputProps('generic.dns2')} />
            </Group>
            <Group position="apart" grow sx={{ flexWrap: 'nowrap' }}>
                <TextInput label={t('Hostname')} placeholder={t('')} {...form.getInputProps('generic.hostname')} />
                <Box />
            </Group>
        </Box>
    );
};

export default ConnectionDetailsInput;
