import { Policy } from './models';

// TODO: find a way to generate this from go
// look into: https://github.com/gzuidhof/tygo/blob/main/tygo/generator.go

export const CanListUsers = 'cloe:user:list';
export const CanReadUser = 'cloe:user:read';
export const CanManageUser = 'cloe:user:manage';
export const CanAdministerUser = 'cloe:user:administer'; // can manage user policies
export const CanDoAnythingOnUser = 'cloe:user:*';

export const CanListGroups = 'cloe:group:list';
export const CanReadGroup = 'cloe:group:read';
export const CanManageGroup = 'cloe:group:manage';
export const CanAdministerGroup = 'cloe:group:administer'; // can manage group policies
export const CanDoAnythingOnGroup = 'cloe:group:*';

export const CanListCustomers = 'cloe:customer:list';
export const CanReadCustomer = 'cloe:customer:read';
export const CanManageCustomer = 'cloe:customer:manage';
export const CanDoAnythingOnCustomer = 'cloe:customer:*';

export const CanListLocations = 'cloe:location:list';
export const CanReadLocation = 'cloe:location:read';
export const CanManageLocation = 'cloe:location:manage';
export const CanDoAnythingOnLocation = 'cloe:location:*';

export const CanListCustomerLocations = 'cloe:customer:location:list';
export const CanReadCustomerLocation = 'cloe:customer:location:read';
export const CanManageCustomerLocation = 'cloe:customer:location:manage';
export const CanDoAnythingOnCustomerLocations = 'cloe:customer:location:*';

export const CanListDevices = 'cloe:device:list';
export const CanReadDevice = 'cloe:device:read';
export const CanManageDevice = 'cloe:device:manage';
export const CanDoAnythingOnDevice = 'cloe:device:*';

export const CanListLocationDevices = 'cloe:location:device:list';
export const CanReadLocationDevice = 'cloe:location:device:read';
export const CanManageLocationDevice = 'cloe:location:device:manage';
export const CanDoAnythingOnLocationDevices = 'cloe:location:device:*';

export const CanListConnections = 'cloe:connection:list';
export const CanReadConnection = 'cloe:connection:read';
export const CanManageConnection = 'cloe:connection:manage';
export const CanDoAnythingOnConnection = 'cloe:connection:*';

export const CanListTickets = 'cloe:ticket:list';
export const CanReadTicket = 'cloe:ticket:read';
export const CanManageTicket = 'cloe:ticket:manage';
export const CanDoAnythingOnTicket = 'cloe:ticket:*';

export const CanListProjects = 'cloe:project:list';
export const CanReadProject = 'cloe:project:read';
export const CanManageProject = 'cloe:project:manage';
export const CanDoAnythingOnProject = 'cloe:project:*';

export const CanListContacts = 'cloe:contact:list';
export const CanLookupContacts = 'cloe:contact:lookup';
export const CanReadContact = 'cloe:contact:read';
export const CanManageContact = 'cloe:contact:manage';
export const CanDoAnythingOnContact = 'cloe:contact:*';

export const CanListLocationConnections = 'cloe:location:connection:list';
export const CanReadLocationConnection = 'cloe:location:connection:read';
export const CanManageLocationConnection = 'cloe:location:connection:manage';
export const CanDoAnythingOnLocationConnections = 'cloe:location:connection:*';

export const CanListAnything = 'cloe:**:list';
export const CanReadAnything = 'cloe:**:read';
export const CanManageAnything = 'cloe:**:manage';

export const UsersResource = 'crn:cloe:user:*';
export const UserResourceFmt = (id: string) => `crn:cloe:user:${id}`;

export const GroupsResource = 'crn:cloe:group:*';
export const GroupResourceFmt = (id: string) => `crn:cloe:group:${id}`;

export const CustomersResource = 'crn:cloe:customer:*';
export const CustomerResourceFmt = (id: string) => `crn:cloe:customer:${id}`;

export const LocationsResource = 'crn:cloe:location:*';
export const LocationResourceFmt = (id: string) => `crn:cloe:location:${id}`;

export const CustomerLocationsResource = (id: string) => `crn:cloe:customer:${id}:location:*`;
export const CustomerLocationResourceFmt = (customerId: string, id: string) =>
    `crn:cloe:customer:${customerId}:location:${id}`;

export const DevicesResource = 'crn:cloe:device:*';
export const DeviceResourceFmt = (id: string) => `crn:cloe:device:${id}`;

export const LocationDevicesResourceFmt = (locationId: number) => `crn:cloe:location:${locationId}:device:*`;
export const LocationDeviceResourceFmt = (locationId: number, id: string) =>
    `crn:cloe:location:${locationId}:device:${id}`;

export const ConnectionsResource = 'crn:cloe:connection:*';
export const ConnectionResourceFmt = (id: string) => `crn:cloe:connection:${id}`;

export const LocationConnectionsResourceFmt = (locationId: number) => `crn:cloe:location:${locationId}:connection:*`;
export const LocationConnectionResourceFmt = (locationId: number, id: string) =>
    `crn:cloe:location:${locationId}:connection:${id}`;

export const TicketsResource = 'crn:cloe:ticket:*';
export const TicketResourceFmt = (id: string) => `crn:cloe:ticket:${id}`;

export const ProjectsResource = 'crn:cloe:project:*';
export const ProjectResourceFmt = (id: string) => `crn:cloe:project:${id}`;

export const ContactsResource = 'crn:cloe:contact:*';
export const ContactResourceFmt = (id: string) => `crn:cloe:contact:${id}`;

export function allowsActionOnResource(policy: Policy, act: string, obj: string): [boolean, boolean] {
    let ok = false;
    let denied = false;
    for (const s of policy.statements) {
        if (doesMatchRequired(s.obj, obj) && doesMatchRequired(s.act, act)) {
            if (s.eff != '' && (s.eff[0] == 'd' || s.eff[0] == 'D')) {
                denied = true;
                ok = false;
                return [ok, denied];
            } else {
                // implied allow
                ok = true;
            }
        }
    }
    return [ok, denied];
}

export function allowsFor(policy: Policy, ...reqs: string[][]): [boolean, boolean] {
    let ok = false;
    let denied = false;
    for (const req of reqs) {
        const [rok, rno] = allowsActionOnResource(policy, req[0], req[1]);
        if (rno) {
            denied = true;
            ok = false;
            return [ok, denied];
        } else {
            ok = ok || rok;
        }
    }
    return [ok, denied];
}

/*
func (p *Policy) AllowsActionOnResource(act string, obj string) (ok bool, denied bool) {
	ok = false
	denied = false
	for _, s := range p.Statements {
		if DoesMatchRequired(s.Object, obj) && DoesMatchRequired(s.Action, act) {
			if s.Effect != "" && (s.Effect[0] == 'd' || s.Effect[0] == 'D') {
				denied = true
				ok = false
				return
			} else {
				// implied allow
				ok = true
			}
		}
	}
	return
}
*/

export function doesMatchRequired(has: string, req: string): boolean {
    const hl = has.length;
    const rl = req.length;
    let ri = 0,
        hi = 0;

    for (; ri < rl && hi < hl; ri++, hi++) {
        if (has[hi] == '*') {
            if (hi == hl - 1) {
                // wildcard at end matches rest
                return true;
            } else if (has[hi + 1] == '*') {
                // spread wildcard, advance hi to next `:`, advance ri to same remaining segment number, more expensive branch
                // i.e. if hi has two remaining segments after the `:`, then ri must also have two remaining segments
                if (hi + 2 < hl && has[hi + 2] != ':') {
                    // sanity check
                    return false;
                }
                hi += 2; // hi on `:`
                // foo:**:bar
                //       ^ ==> scan forward count `:` as N
                // foo:one:two:bar
                //                ^ <== scan backward for `:` N times as long as > ri
                let n = 0;
                for (let hj = hi; hj < hl; hj++) {
                    if (has[hj] == ':') {
                        n += 1;
                    }
                }
                // fmt.Printf("scan back found n=%d\n", n)
                let rj = rl - 1;
                for (; rj >= ri; rj--) {
                    // >= is correct here?
                    if (req[rj] == ':') {
                        n -= 1;
                        if (n == 0) {
                            break;
                        }
                    }
                }
                if (n > 0 || req[rj] != ':') {
                    // sanity checks
                    // fmt.Printf("sanity check fail n=%d, rj=%c\n", n, req[rj])
                    return false;
                }
                ri = rj;
                // fmt.Printf("hi=%d, ri=%d, h=%c, r=%c\n", hi, ri, has[hi], req[ri])
                // both hi and ri are on `:`
            } else if (has[hi + 1] != ':') {
                // sanity check, if not at end, next character must be `:`
                return false;
            } else {
                // advance hi to next `:`, advance ri to next `:`
                hi += 1; // we know next char is `:` due to previous sanity check
                for (; ri < rl; ) {
                    if (req[ri] == ':') {
                        break;
                    } else {
                        ri += 1;
                    }
                }
            }
        } else if (has[hi] != req[ri]) {
            // current character must match
            // fmt.Printf("fail on neq check hi=%d, ri=%d, h=%c, r=%c\n", hi, ri, has[hi], req[ri])
            return false;
        }
    }

    if (ri != rl && hi != hl) {
        return false;
    } else {
        return true;
    }
}

/*
func DoesMatchRequired(has string, req string) bool {
	hl := len(has)
	rl := len(req)
	ri, hi := 0, 0

	for ; ri < rl && hi < hl; ri, hi = ri+1, hi+1 {
		if has[hi] == '*' {
			if hi == hl-1 {
				// wildcard at end matches rest
				return true
			} else if has[hi+1] == '*' {
				// spread wildcard, advance hi to next `:`, advance ri to same remaining segment number, more expensive branch
				// i.e. if hi has two remaining segments after the `:`, then ri must also have two remaining segments
				if hi+2 < hl && has[hi+2] != ':' { // sanity check
					return false
				}
				hi += 2 // hi on `:`
				// foo:**:bar
				//       ^ ==> scan forward count `:` as N
				// foo:one:two:bar
				//                ^ <== scan backward for `:` N times as long as > ri
				n := 0
				for hj := hi; hj < hl; hj++ {
					if has[hj] == ':' {
						n += 1
					}
				}
				// fmt.Printf("scan back found n=%d\n", n)
				rj := rl - 1
				for ; rj >= ri; rj-- { // >= is correct here?
					if req[rj] == ':' {
						n -= 1
						if n == 0 {
							break
						}
					}
				}
				if n > 0 || req[rj] != ':' { // sanity checks
					// fmt.Printf("sanity check fail n=%d, rj=%c\n", n, req[rj])
					return false
				}
				ri = rj
				// fmt.Printf("hi=%d, ri=%d, h=%c, r=%c\n", hi, ri, has[hi], req[ri])
				// both hi and ri are on `:`
			} else if has[hi+1] != ':' {
				// sanity check, if not at end, next character must be `:`
				return false
			} else {
				// advance hi to next `:`, advance ri to next `:`
				hi += 1 // we know next char is `:` due to previous sanity check
				for ri < rl {
					if req[ri] == ':' {
						break
					} else {
						ri += 1
					}
				}
			}
		} else if has[hi] != req[ri] {
			// current character must match
			// fmt.Printf("fail on neq check hi=%d, ri=%d, h=%c, r=%c\n", hi, ri, has[hi], req[ri])
			return false
		}
	}

	if ri != rl || hi != hl {
		// if we did not consume both strings, not a match
		return false
	} else {
		return true
	}
}
*/
