import { getErrorMessage } from '@/errors';
import { Accordion, Alert, Button, Code, Group, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle, IconDeviceFloppy } from '@tabler/icons';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateConnectionMutation } from '@/management/api';
import { ConnectionDetails, ConnectionMonitor, Location } from 'src/management/models';
import { useLocationSelectData } from '@/management/hooks';
import ConnectionDetailsInput from '@/management/components/ConnectionDetailsInput';
import ConnectionMonitorInput from '@/management/components/ConnectionMonitorInput';
import { $F, useFeatureCheck } from 'src/identity';

export interface CreateConnectionFormProps {
    location?: Location;
    locationId?: string;
    onClose?: () => void;
}

type CreateConnectionFormValues = {
    locationId: string;
    name: string;
    details: ConnectionDetails;
    monitor: ConnectionMonitor;
};

export const CreateConnectionForm = ({ location, locationId, onClose }: CreateConnectionFormProps) => {
    const { t, i18n } = useTranslation();
    const feat = useFeatureCheck();
    const form = useForm<CreateConnectionFormValues>({
        initialValues: {
            locationId: '',
            name: '',
            details: {},
            monitor: {},
        },
        validate: {
            name: (value) => (value.length < 1 ? t('Name is required') : null),
        },
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [createConnection, { isLoading: isSaving, isSuccess, isError }] = useCreateConnectionMutation();
    const [locationSelectData, setLocationSearch, locationSearch] = useLocationSelectData();
    const onSubmit = useCallback(
        async ({ name, locationId: formLocationId, details, monitor }: CreateConnectionFormValues) => {
            try {
                const res = await createConnection({
                    connection: {
                        locationId: locationId ?? formLocationId,
                        name,
                        details,
                        monitor,
                    },
                }).unwrap();
                if (onClose) {
                    onClose();
                }
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
            }
        },
        [createConnection, onClose],
    );

    return (
        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
            {locationId ? (
                <TextInput label={t('Location')} disabled value={location?.name ?? t('Unknown')} />
            ) : (
                <Select
                    data-autofocus={true}
                    label={t('Location')}
                    placeholder={t('Search for location')}
                    data={locationSelectData}
                    required
                    searchable
                    onSearchChange={setLocationSearch}
                    nothingFound={locationSearch ? t('No locations found') : t('Start typing to search')}
                    {...form.getInputProps('locationId')}
                />
            )}
            <TextInput
                mt="md"
                data-autofocus
                required
                label={t('Name')}
                placeholder={t('Name')}
                {...form.getInputProps('name')}
            />
            <Accordion variant="separated" mt="md" multiple defaultValue={['details', 'monitor']}>
                <Accordion.Item value="details">
                    <Accordion.Control>{t('Details')}</Accordion.Control>
                    <Accordion.Panel>
                        <ConnectionDetailsInput {...form.getInputProps('details')} />
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="monitor">
                    <Accordion.Control>{t('Monitor')}</Accordion.Control>
                    <Accordion.Panel>
                        <ConnectionMonitorInput {...form.getInputProps('monitor')} />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            {errorMessage ? (
                <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                    {errorMessage}
                </Alert>
            ) : null}
            <Group position="right" mt={'md'}>
                <Button color={'gray'} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button type="submit" color={'primary'} loading={isSaving} leftIcon={<IconDeviceFloppy />}>
                    {t('Save')}
                </Button>
            </Group>
            {feat($F.FormDebug) ? (
                <Code mt="md" block>
                    {JSON.stringify(form.values, null, 2)}
                </Code>
            ) : null}
        </form>
    );
};

export default CreateConnectionForm;
