import { Box, Button, Card, Center, Group, Loader, ScrollArea, Stack, Tabs, Title, Text, Space } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import View from '@/common/components/View';
import { useNavigate, useParams } from 'react-router';
import { GetProjectsRequest, GetProjectRequest, Project as ProjectType } from '@/support/models';
import { IconArrowBackUp, IconBuildingSkyscraper, IconInfoCircle, IconSwitchVertical } from '@tabler/icons';
import LabeledText from 'src/common/components/LabeledText';
import ProjectStatusBadge from '../ProjectStatusBadge';
import { useAllowsActionOnResource } from 'src/identity';
import ProjectPriorityBadge from '../ProjectPriorityBadge';
import { Link } from 'react-router-dom';
import { useGetProjectsQuery, useLazyGetProjectQuery } from '@/support/api';
import { useSelector } from 'react-redux';
import { ReactNode, useEffect, useState } from 'react';
import { RootState } from '@/app/store';

export const Project = () => {
    const { projectId, tab } = useParams();
    const { t } = useTranslation();
    const allowsActionOnResource = useAllowsActionOnResource();
    const [requestSet, setRequestSet] = useState(false);
    const navigate = useNavigate();
    const { installed, inprogress, search, allCompanies, sortBy, continuePage, direction } = useSelector(
        (state: RootState) => state.projects,
    );

    const [displayedProject, setDisplayedProject] = useState<ProjectType>({} as ProjectType);

    const [req, setRequest] = useState<GetProjectsRequest>({
        continue: continuePage,
        search: search,
        installed: installed,
        inProgress: inprogress,
        allCompanies: allCompanies,
        order: sortBy,
        direction: direction,
    });
    const { data: { projects = [] } = {}, isFetching } = useGetProjectsQuery(req, {});

    const [req2, setRequest2] = useState<GetProjectRequest>({
        id: projectId,
    });

    const getProject = useLazyGetProjectQuery();

    useEffect(() => {
        let gotProject = false;
        if (projects.length != 0 && projectId && !requestSet) {
            let foundProject = projects.find((p) => String(p.id) === projectId) as ProjectType;
            if (foundProject) {
                setDisplayedProject(foundProject);
                gotProject = true;
            }
        }

        if (!gotProject) {
            const fetchData = async () => {
                if (displayedProject.id === undefined && !requestSet && !isFetching && projectId) {
                    try {
                        setRequestSet(true);
                        const response = await getProject[0](req2);
                        const projectData = response.data?.project;

                        setDisplayedProject(projectData ? projectData : ({} as ProjectType));
                    } catch (error) {
                        console.error('Error fetching project:', error);
                    }
                }
            };

            fetchData();
        }
    }, [displayedProject.id, requestSet, isFetching, req2, projectId]);

    function onTabChange(value: string) {
        navigate(`./../${value}`, { replace: true });
    }

    return (
        <View
            header={
                <Group position="apart" sx={{ minHeight: 36 }}>
                    <Group>
                        <Title order={3}>{displayedProject.name}</Title>
                    </Group>
                    <Group>
                        {isFetching && <Loader size="sm" color="green" />}
                        <Button component={Link} to="/projects" variant="light" color="green" px="xs">
                            <IconArrowBackUp />
                        </Button>
                    </Group>
                </Group>
            }
        >
            <Card.Section>
                <Group position="left" m="md" align="flex-start">
                    <Stack spacing="xs">
                        <Group position="left">
                            <Center>
                                <IconBuildingSkyscraper />
                            </Center>
                            <LabeledText label={t('Location')} raw>
                                {displayedProject.companyName ?? t('Unknown')}
                            </LabeledText>
                        </Group>
                    </Stack>
                    <Stack ml="md" spacing="xs">
                        <Group position="left">
                            <Center>
                                <IconSwitchVertical />
                            </Center>
                            <LabeledText label={t('Status')} raw>
                                <ProjectStatusBadge status={displayedProject.status} />
                            </LabeledText>
                        </Group>
                    </Stack>
                </Group>
            </Card.Section>
            <Card.Section
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                <Tabs
                    defaultValue="project"
                    value={tab}
                    onTabChange={onTabChange}
                    keepMounted={false}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}
                >
                    <Tabs.List>
                        <Tabs.Tab value="details" icon={<IconInfoCircle size={16} />}>
                            {t('Details')}
                        </Tabs.Tab>

                        <Group position="right" sx={{ flexGrow: 1 }}></Group>
                    </Tabs.List>
                    <Tabs.Panel value="details" sx={{ height: '100%' }}>
                        <Stack spacing={0} align="stretch" justify="flex-start" sx={{ height: '100%' }}>
                            <Box px="md" sx={{ flexGrow: 1, position: 'relative' }}>
                                <ScrollArea
                                    type="hover"
                                    sx={{
                                        position: 'absolute !important' as 'absolute', // TODO: total hack for !important?
                                        top: 0,
                                        bottom: 0,
                                        width: '91vw',
                                    }}
                                >
                                    <Data label={t('Project ID')} value={String(displayedProject.id)} />
                                    <Data label={t('Info')} value={displayedProject.info} />
                                    <Data label={t('Target Install Date')} value={displayedProject.target} />
                                    <Data label={t('Carrier Install Date')} value={displayedProject.carrier} />
                                    <Data label={t('Notes & Updates')} value={displayedProject.notes} />
                                    <Group position="left" mt="sm">
                                        <LabeledText label={t('Priority')}>
                                            <ProjectPriorityBadge
                                                priority={displayedProject.priority}
                                            ></ProjectPriorityBadge>
                                        </LabeledText>
                                    </Group>
                                    <Data label={t('Underlying Carrier')} value={displayedProject.underlyingCarrier} />
                                    <Data label={t('Download Speed')} value={displayedProject.downloadSpeed} />
                                    <Data label={t('Upload Speed')} value={displayedProject.uploadSpeed} />
                                    <Data
                                        label={t('First Usable Static IP')}
                                        value={displayedProject.firstUsableStaticIp}
                                    />
                                    <Data
                                        label={t('Last Usable Static IP')}
                                        value={displayedProject.lastUsableStaticIp}
                                    />
                                    <Data label={t('Subnet Mask')} value={displayedProject.subnetMask} />
                                    <Data label={t('DNS 1')} value={displayedProject.dns1} />
                                    <Data label={t('DNS 2')} value={displayedProject.dns2} />
                                    <Space h="lg" />
                                </ScrollArea>
                            </Box>
                        </Stack>
                    </Tabs.Panel>
                </Tabs>
            </Card.Section>
        </View>
    );
};

interface DataProps {
    icon?: ReactNode;
    label?: string;
    value?: string;
}

const Data = ({ icon, label, value }: DataProps) => {
    const { t, i18n } = useTranslation();
    return (
        <Group position="left" mt="sm">
            {icon ? <Center>{icon}</Center> : null}
            <LabeledText label={label!}>
                {value && value != '-' ? <Text>{value}</Text> : <Text color="dimmed">{t('none')}</Text>}
            </LabeledText>
        </Group>
    );
};

export default Project;
