import { createApi, BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { IdentityStateSlice } from './identity/models';
import { selectAuthorization } from './identity/selectors';
import { EnvironmentStateSlice } from './environment/models';
import { selectApiUrl } from './environment/selectors';

export const rawBaseQuery = fetchBaseQuery({
    baseUrl: '/api/v1',
});

export const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    const authorization = selectAuthorization(api.getState() as IdentityStateSlice);
    const apiUrl = selectApiUrl(api.getState() as EnvironmentStateSlice);

    const adjustedArgs =
        typeof args === 'string' ? { url: apiUrl + `/${args}` } : { ...args, url: apiUrl + `/${args.url}` };

    adjustedArgs.headers = { ...adjustedArgs.headers, ...makeAuthorizationHeaders(authorization) };

    return rawBaseQuery(adjustedArgs, api, extraOptions);
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const rootApi = createApi({
    baseQuery: dynamicBaseQuery,
    endpoints: () => ({}),
});

export enum ApiParams {
    Order = 'order',
    Continue = 'continue',
}

export enum ApiHeaders {
    Authorization = 'Authorization',
    AuthorizationMode = 'Authorization-Mode',
    XHTTPMethod = 'X-HTTP-Method',
}

export enum ApiAuthorizationMode {
    NoChallenge = 'no-challenge',
}

export function makeAuthorization(authorization: string | { username: string; password?: string }) {
    if (typeof authorization === 'string') {
        return authorization;
    } else if (authorization) {
        return makeBasicAuthorization(authorization.username, authorization.password);
    } else {
        return '';
    }
}

const sleep = (ms: number): Promise<void> => {
    return new Promise<void>((resolve) => setTimeout(resolve, ms));
};

export function makeSessionAuthorization(sessionId: string) {
    return `Bearer ${sessionId}`;
}

export function makeBasicAuthorization(username: string, password?: string) {
    return `Basic ${btoa(`${username}:${password || ''}`)}`;
}

export function makeAuthorizationHeaders(authorization: string | { username: string; password?: string }) {
    let match: RegExpExecArray | null;
    if (authorization) {
        return {
            [ApiHeaders.Authorization]: makeAuthorization(authorization),
        };
    } else {
        return null;
    }
}
