import { MantineTheme } from '@mantine/core';

export const forPhoneOnly = <C extends unknown>(theme: MantineTheme, cssObject: C) => {
    return {
        [`@media (max-width: ${theme.breakpoints.xs}px)`]: cssObject,
    };
};

export const forTabletPortraitUp = <C extends unknown>(theme: MantineTheme, cssObject: C) => {
    return {
        [`@media (min-width: ${theme.breakpoints.sm}px)`]: cssObject,
    };
};

export const forTabletLandscapeUp = <C extends unknown>(theme: MantineTheme, cssObject: C) => {
    return {
        [`@media (min-width: ${theme.breakpoints.md}px)`]: cssObject,
    };
};

export const forDesktopUp = <C extends unknown>(theme: MantineTheme, cssObject: C) => {
    return {
        [`@media (min-width: ${theme.breakpoints.lg}px)`]: cssObject,
    };
};

export const forBigDesktopUp = <C extends unknown>(theme: MantineTheme, cssObject: C) => {
    return {
        [`@media (min-width: ${theme.breakpoints.xl}px)`]: cssObject,
    };
};
