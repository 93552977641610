import { Badge, BadgeProps, createStyles, DefaultProps, Selectors, useComponentDefaultProps } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    TicketStatus,
    TicketStatusNew,
    TicketStatusCloverleafResponseRequested,
    TicketStatusContactingCarrier,
    TicketStatusCarrierDispatched,
    TicketStatusCustomerInfoRequested,
    TicketStatusResolvingLeavingTicketOpen,
    TicketStatusClosed,
    TicketStatusEscalated,
} from 'src/support/models';

export interface TicketStatusBadgeStyleParams {}

const useStyles = createStyles((theme, params: TicketStatusBadgeStyleParams, getRef) => {
    return {
        root: {},
    };
});

type TicketStatusBadgeStyleNames = Selectors<typeof useStyles>;

export interface TicketStatusBadgeProps
    extends DefaultProps<TicketStatusBadgeStyleNames, TicketStatusBadgeStyleParams> {
    status?: TicketStatus;
    variant?: BadgeProps['variant'];
}

const componentName = 'CLUITicketStatusBadge';
const defaultProps: Partial<TicketStatusBadgeProps> = {
    variant: 'filled',
};

export const TicketStatusBadge = (props: TicketStatusBadgeProps) => {
    const { className, classNames, styles, unstyled, variant, status, ...rest } = useComponentDefaultProps(
        componentName,
        defaultProps,
        props,
    );
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    const statuses = useMemo<{ [key in TicketStatus]: [string, string] }>(
        () => ({
            [TicketStatusNew]: [t('New'), 'orange'],
            [TicketStatusCloverleafResponseRequested]: [t('CLN Response Requested'), 'pink'],
            [TicketStatusContactingCarrier]: [t('Contacting Carrier'), 'yellow'],
            [TicketStatusCarrierDispatched]: [t('Carrier Dispatched'), 'yellow'],
            [TicketStatusCustomerInfoRequested]: [t('Customer Info Requested'), 'blue'],
            [TicketStatusResolvingLeavingTicketOpen]: [t('Resolving Leaving Ticket Open'), 'cyan'],
            [TicketStatusClosed]: [t('Closed'), 'teal'],
            [TicketStatusEscalated]: [t('Escalated'), 'red'],
        }),
        [t],
    );
    const [label, color] = statuses[status!] ?? [t('Unknown'), 'gray'];
    return (
        <Badge variant={variant} className={cx(classes.root, className)} color={color} {...rest}>
            {label}
        </Badge>
    );
};

export default TicketStatusBadge;
