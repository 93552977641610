import { Avatar, Box, Text, Group, UnstyledButton, useMantineTheme, Menu } from '@mantine/core';
import { selectWhoAmI, useLogoutMutation } from '@/identity';
import { useSelector } from 'react-redux';
import { useGravatar } from '@/identity/hooks';
import { IconLogout } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

export interface NavWhoAmIProps {}

export const NavWhoAmI = (props: NavWhoAmIProps) => {
    const { t, i18n } = useTranslation();
    const {
        principal: { sub, name },
    } = useSelector(selectWhoAmI)!;
    const avatarUrl = useGravatar(sub);
    const theme = useMantineTheme();
    const [logout] = useLogoutMutation();

    const doLogout = async () => {
        try {
            const res = await logout({}).unwrap();
        } catch (err) {}
        window.location.pathname = '/';
    };

    return (
        <Box
            sx={{
                height: 64,
                borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]}`,
            }}
        >
            <Menu withArrow width={240} shadow="sm">
                <Menu.Target>
                    <UnstyledButton
                        sx={{
                            display: 'block',
                            width: '100%',
                            padding: theme.spacing.xs,
                            borderRadius: 0,
                            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                            '&:hover': {
                                backgroundColor:
                                    theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                            },
                        }}
                    >
                        <Group noWrap>
                            <Avatar src={avatarUrl} radius="xl" />
                            <Box sx={{ flex: 1, overflow: 'hidden' }}>
                                <Text
                                    size="sm"
                                    weight={500}
                                    sx={{
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {name}
                                </Text>
                                <Text
                                    color="dimmed"
                                    size="xs"
                                    sx={{
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {sub}
                                </Text>
                            </Box>
                        </Group>
                    </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item color="red" icon={<IconLogout size={14} />} onClick={doLogout}>
                        {t('Logout')}
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </Box>
    );
};

export default NavWhoAmI;
