import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { identitySlice } from '@/identity';
import { rootApi } from '@/api';
import { environmentSlice } from '@/environment';
import { appSlice } from './slice';
import { projectSlice } from '../support/slice';

// API imports
//import '@/tab';

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
    configureStore({
        reducer: {
            [rootApi.reducerPath]: rootApi.reducer,
            [environmentSlice.name]: environmentSlice.reducer,
            [identitySlice.name]: identitySlice.reducer,
            [appSlice.name]: appSlice.reducer,
            [projectSlice.name]: projectSlice.reducer,
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rootApi.middleware),
        ...options,
    });

export type StoreType = ReturnType<typeof createStore>;
export type AppDispatch = StoreType['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<StoreType['getState']>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
