import Drawer, { DrawerContext, DrawerProps } from '@/common/components/Drawer';
import { Text, useComponentDefaultProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import CreateContactForm from './CreateContactForm';

export interface CreateContactProps extends DrawerProps {}

const componentName = 'CLUICreateContact';
const defaultProps: Partial<CreateContactProps> = {
    size: '50vw',
    padding: 'md',
    position: 'right',
    closeOnClickOutside: false,
    overlayOpacity: 0.0,
    shadow: 'lg',
};

export const CreateContact = (props: CreateContactProps) => {
    const { onClose, ...rest } = useComponentDefaultProps(componentName, defaultProps, props);
    const { t, i18n } = useTranslation();
    return (
        <Drawer onClose={onClose} {...rest} shadow="lg" title={<Text weight="bold">{t('CreateContact')}</Text>}>
            <DrawerContext.Consumer>{({ onClose }) => <CreateContactForm onClose={onClose} />}</DrawerContext.Consumer>
        </Drawer>
    );
};

export default CreateContact;
