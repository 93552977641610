import { selectTheme, useTypedSelector } from '@/app';
import { MantineProvider, MantineProviderProps } from '@mantine/styles';

export interface ThemeProviderProps extends MantineProviderProps {}

export function ThemeProvider({ children, ...rest }: ThemeProviderProps) {
    const theme = useTypedSelector(selectTheme);
    return <MantineProvider theme={theme} children={children} {...rest} />;
}

export default ThemeProvider;
