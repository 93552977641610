import Drawer, { DrawerContext, DrawerProps } from '@/common/components/Drawer';
import { Text, useComponentDefaultProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import UpdateContactForm from './UpdateContactForm';
import { useLazyGetContactQuery } from '../../api';
import { Contact } from '../../models';
import { useParams } from 'react-router';
import { useEffect } from 'react';

export interface UpdateContactProps extends DrawerProps {}

const componentName = 'CLUIUpdateContact';
const defaultProps: Partial<UpdateContactProps> = {
    size: '50vw',
    padding: 'md',
    position: 'right',
    closeOnClickOutside: false,
    overlayOpacity: 0.0,
    shadow: 'lg',
};

export const UpdateContact = (props: UpdateContactProps) => {
    const { onClose, ...rest } = useComponentDefaultProps(componentName, defaultProps, props);
    const { t, i18n } = useTranslation();
    const { contactId } = useParams();
    const [getContact, { data: { contact = {} as Contact } = {} }] = useLazyGetContactQuery();
    useEffect(() => {
        if (contactId) {
            getContact({ id: contactId });
        }
    }, [getContact, contactId]);
    return (
        <Drawer onClose={onClose} {...rest} shadow="lg" title={<Text weight="bold">{t('Update Contact')}</Text>}>
            <DrawerContext.Consumer>
                {({ onClose }) => <UpdateContactForm contact={contact} contactId={contactId!} onClose={onClose} />}
            </DrawerContext.Consumer>
        </Drawer>
    );
};

export default UpdateContact;
