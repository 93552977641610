import { Box, createStyles, DefaultProps, Selectors, Text, TextProps, useComponentDefaultProps } from '@mantine/core';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface LabeledTextStyleParams {}

const useStyles = createStyles((theme, params: LabeledTextStyleParams, getRef) => {
    return {
        root: {},
        label: {},
        text: {},
    };
});

type LabeledTextStyleNames = Selectors<typeof useStyles>;

export interface LabeledTextProps
    extends DefaultProps<LabeledTextStyleNames, LabeledTextStyleParams>,
        Omit<TextProps, 'className' | 'classNames' | 'styles' | 'unstyled'> {
    label?: ReactElement | string;
    labelProps?: TextProps;
    raw?: boolean;
}

const componentName = 'CLUILabeledText';
const defaultProps: Partial<LabeledTextProps> = {
    labelProps: {
        size: 'sm',
        color: 'dimmed',
    },
    raw: false,
};

export const LabeledText = (props: LabeledTextProps) => {
    const { className, classNames, styles, unstyled, children, raw, label, labelProps, ...rest } =
        useComponentDefaultProps(componentName, defaultProps, props);
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { className: labelClassName, ...labelRest } = labelProps!;
    const { t, i18n } = useTranslation();
    return (
        <Box className={cx(classes.root, className)}>
            <Text className={cx(classes.label, labelClassName)} {...labelRest}>
                {label ?? t('Unknown')}
            </Text>
            {raw ? (
                children
            ) : (
                <Text className={cx(classes.text)} {...rest}>
                    {children ?? t('Unknown')}
                </Text>
            )}
        </Box>
    );
};

export default LabeledText;
