import Drawer, { DrawerContext, DrawerProps } from '@/common/components/Drawer';
import { Text, useComponentDefaultProps, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import CreateUserForm from './CreateUserForm';

export interface CreateUserProps extends DrawerProps {}

const componentName = 'CLUICreateUser';
const defaultProps: Partial<CreateUserProps> = {
    size: '50vw',
    position: 'right',
    closeOnClickOutside: false,
    overlayOpacity: 0.2,
    overlayBlur: 0,
    shadow: 'lg',
};

export const CreateUser = (props: CreateUserProps) => {
    const theme = useMantineTheme();
    const { onClose, ...rest } = useComponentDefaultProps(componentName, defaultProps, props);
    const { t, i18n } = useTranslation();
    return (
        <Drawer
            onClose={onClose}
            {...rest}
            shadow="lg"
            padding="md"
            title={<Text weight="bold">{t('Create User')}</Text>}
        >
            <DrawerContext.Consumer>{({ onClose }) => <CreateUserForm onClose={onClose} />}</DrawerContext.Consumer>
        </Drawer>
    );
};

export default CreateUser;
