import { rootApi } from '../api';
import {
    GetContactRequest,
    GetContactResponse,
    GetContactsRequest,
    GetContactsResponse,
    DeleteContactRequest,
    DeleteContactResponse,
    CreateContactRequest,
    CreateContactResponse,
    UpdateContactRequest,
    UpdateContactResponse,
    LookupContactsRequest,
    LookupContactsResponse,
} from './models';

const apiWithTag = rootApi.enhanceEndpoints({
    addTagTypes: ['Contact', 'ContactAssociation'],
});

export const crmApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
        getContacts: build.query<GetContactsResponse, GetContactsRequest>({
            query: (req: GetContactsRequest) => ({
                url: `contacts`,
                params: req,
            }),
            providesTags: (res, err, arg) =>
                res
                    ? [
                          ...res.contacts.map(({ id }) => ({
                              type: 'Contact' as const,
                              id,
                          })),
                          'Contact',
                      ]
                    : ['Contact'],
        }),
        getContact: build.query<GetContactResponse, GetContactRequest>({
            query: (req: GetContactRequest) => ({
                url: `contacts/${req.id}`,
            }),
            providesTags: (res, err, arg) => [{ type: 'Contact' as const, id: res?.contact.id }],
        }),
        createContact: build.mutation<CreateContactResponse, CreateContactRequest>({
            query: (req: CreateContactRequest) => ({
                url: `contacts`,
                method: 'POST',
                body: req,
            }),
            invalidatesTags: ['Contact'],
        }),
        updateContact: build.mutation<UpdateContactResponse, UpdateContactRequest>({
            query: (req: UpdateContactRequest) => ({
                url: `contacts/${req.contact.id}`,
                method: 'PATCH',
                body: req,
            }),
            invalidatesTags: (res, err, arg) => [{ type: 'Contact' as const, id: res?.contact.id }],
        }),
        deleteContact: build.mutation<DeleteContactResponse, DeleteContactRequest>({
            query: (req: DeleteContactRequest) => ({
                url: `contacts/${req.id}`,
                method: 'DELETE',
                body: req,
            }),
            invalidatesTags: ['Contact'],
        }),
        lookupContacts: build.query<LookupContactsResponse, LookupContactsRequest>({
            query: (req: LookupContactsRequest) => ({
                url: `contacts/lookup`,
                params: req,
            }),
            providesTags: (res, err, arg) =>
                res
                    ? [
                          ...res.contacts.map(({ crn }) => ({
                              type: 'ContactAssociation' as const,
                              crn,
                          })),
                          'ContactAssociation',
                      ]
                    : ['ContactAssociation'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetContactsQuery,
    useGetContactQuery,
    useDeleteContactMutation,
    useCreateContactMutation,
    useUpdateContactMutation,
    useLookupContactsQuery,
} = crmApi;

export const { useLazyGetContactQuery } = crmApi;
