import { Group, Policy } from '@/identity/models';
import { Box, Code, ScrollArea, Stack, useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';
import { useGetGroupPolicyQuery } from 'src/identity/api';

export interface GroupPolicyProps {
    group?: Group;
    groupId?: string;
}

export const GroupPolicy = ({ group, groupId, ...rest }: GroupPolicyProps) => {
    const theme = useMantineTheme();
    const { data: { policy = {} as Policy } = {} } = useGetGroupPolicyQuery({
        groupId: groupId!,
    });
    const json = useMemo(() => {
        return JSON.stringify(policy, null, 2);
    }, [policy]);
    return (
        <Stack spacing={0} align="stretch" justify="flex-start" sx={{ height: '100%' }} {...rest}>
            <Box px="md" sx={{ flexGrow: 1, position: 'relative' }}>
                <ScrollArea
                    type="hover"
                    sx={{
                        position: 'absolute !important' as 'absolute', // TODO: total hack for !important?
                        top: 0,
                        bottom: 0,
                        width: `calc(100% - ${theme.spacing.md * 2}px)`,
                    }}
                >
                    <Code my="md" block sx={{ fontFamily: 'monospace, monospace' }}>
                        {json}
                    </Code>
                </ScrollArea>
            </Box>
        </Stack>
    );
};

export default GroupPolicy;
