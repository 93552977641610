import { getErrorMessage } from '@/errors';
import { Accordion, ActionIcon, Alert, Button, Code, Group, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle, IconDeviceFloppy, IconUpload } from '@tabler/icons';
import { useCallback, useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContactSelectData } from 'src/crm';
import { $F, useFeatureCheck } from 'src/identity';
import { useCreateCustomerAttachmentMutation, useUpdateCustomerMutation } from 'src/management/api';
import { Customer } from 'src/management/models';

export interface UpdateCustomerFormProps {
    customer: Customer;
    customerId: string;
    onClose?: () => void;
}

type UpdateCustomerFormValues = {
    name: string;
    logoUrl: string;
    iconUrl: string;
    administrativeContactID: string;
    technicalContactID: string;
};

export const UpdateCustomerForm = ({ customer, customerId, onClose }: UpdateCustomerFormProps) => {
    const { t, i18n } = useTranslation();
    const feat = useFeatureCheck();
    const form = useForm<UpdateCustomerFormValues>({
        initialValues: {
            name: '',
            logoUrl: '',
            iconUrl: '',
            administrativeContactID: '',
            technicalContactID: '',
        },
        validate: {
            name: (value) => (value.length < 1 ? t('Name is required') : null),
        },
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [updateCustomer, { isLoading, isSuccess, isError }] = useUpdateCustomerMutation();

    const onSubmit = useCallback(
        async ({ name, logoUrl, iconUrl, administrativeContactID, technicalContactID }: UpdateCustomerFormValues) => {
            try {
                const res = await updateCustomer({
                    customer: {
                        id: customerId,
                        name,
                        logoUrl,
                        iconUrl,
                        administrativeContact: {
                            id: administrativeContactID,
                        },
                        technicalContact: {
                            id: technicalContactID,
                        },
                    },
                }).unwrap();
                if (onClose) {
                    onClose();
                }
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
            }
        },
        [updateCustomer, setErrorMessage, onClose],
    );

    useEffect(() => {
        if (customer) {
            form.setValues({
                name: customer.name,
                logoUrl: customer.logoUrl,
                iconUrl: customer.iconUrl,
                administrativeContactID: customer.administrativeContact?.id!,
                technicalContactID: customer.technicalContact?.id!,
            });
        }
    }, [customer]);

    const [createCustomerAttachment, { isLoading: isCreatingCustomerAttachment }] =
        useCreateCustomerAttachmentMutation();
    const onAttachmentChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0];
            const as = e.target.getAttribute('data-as') ?? '';
            if (file) {
                try {
                    const res = await createCustomerAttachment({
                        customerId,
                        file,
                        as,
                    }).unwrap();
                    // if (onClose) {
                    //     onClose();
                    // }
                } catch (err) {
                    setErrorMessage(getErrorMessage(err));
                }
            }
        },
        [createCustomerAttachment, setErrorMessage, onClose],
    );

    const logoFileId = useId();
    const logoFileRef = useRef<HTMLInputElement>(null);
    const iconFileId = useId();
    const iconFileRef = useRef<HTMLInputElement>(null);

    const [contactSelectData, setContactSearch, contactSearch] = useContactSelectData(
        customer.administrativeContact
            ? {
                  label: customer.administrativeContact.name,
                  value: customer.administrativeContact.id,
              }
            : null,
    );

    const [contactSelectDataTech, setContactSearchTech, contactSearchTech] = useContactSelectData(
        customer.technicalContact
            ? {
                  label: customer.technicalContact.name,
                  value: customer.technicalContact.id,
              }
            : null,
    );

    return (
        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
            <TextInput
                data-autofocus
                required
                label={t('Name')}
                placeholder={t('Name')}
                {...form.getInputProps('name')}
            />
            <input
                ref={logoFileRef}
                type="file"
                data-as={'logoUrl'}
                id={logoFileId}
                style={{ display: 'none' }}
                onChange={onAttachmentChange}
            />
            <TextInput
                label={t('Logo URL')}
                placeholder={t('URL')}
                {...form.getInputProps('logoUrl')}
                rightSection={
                    <label htmlFor={logoFileId}>
                        <ActionIcon
                            onClick={() => {
                                logoFileRef.current?.click();
                            }}
                        >
                            <IconUpload />
                        </ActionIcon>
                    </label>
                }
            />
            <input
                ref={iconFileRef}
                type="file"
                data-as={'iconUrl'}
                id={iconFileId}
                style={{ display: 'none' }}
                onChange={onAttachmentChange}
            />
            <TextInput
                label={t('Icon URL')}
                placeholder={t('URL')}
                {...form.getInputProps('iconUrl')}
                rightSection={
                    <label htmlFor={iconFileId}>
                        <ActionIcon
                            onClick={() => {
                                iconFileRef.current?.click();
                            }}
                        >
                            <IconUpload />
                        </ActionIcon>
                    </label>
                }
            />
            <Accordion variant="separated" mt="md" multiple defaultValue={['administrativeContact']}>
                <Accordion.Item value="administrativeContact">
                    <Accordion.Control>{t('Administrative Contact')}</Accordion.Control>
                    <Accordion.Panel>
                        <Select
                            placeholder={t('Search for contact')}
                            data={contactSelectData}
                            required
                            searchable
                            onSearchChange={setContactSearch}
                            nothingFound={contactSearch ? t('No contacts found') : t('Start typing to search')}
                            {...form.getInputProps('administrativeContactID')}
                            clearable
                        />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            <Accordion variant="separated" mt="md" multiple defaultValue={['technicalContact']}>
                <Accordion.Item value="technicalContact">
                    <Accordion.Control>{t('Technical Contact')}</Accordion.Control>
                    <Accordion.Panel>
                        <Select
                            placeholder={t('Search for contact')}
                            data={contactSelectDataTech}
                            required
                            searchable
                            onSearchChange={setContactSearchTech}
                            nothingFound={contactSearchTech ? t('No contacts found') : t('Start typing to search')}
                            {...form.getInputProps('technicalContactID')}
                            clearable
                        />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            {errorMessage ? (
                <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                    {errorMessage}
                </Alert>
            ) : null}
            <Group position="right" mt={'md'}>
                <Button color={'gray'} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button type="submit" color={'primary'} loading={isLoading} leftIcon={<IconDeviceFloppy />}>
                    {t('Save')}
                </Button>
            </Group>
            {feat($F.FormDebug) ? (
                <Code mt="md" block>
                    {JSON.stringify(form.values, null, 2)}
                </Code>
            ) : null}
        </form>
    );
};

export default UpdateCustomerForm;
