import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_NAME, AuthenticationMethods, AuthenticationMethodType, EnvironmentState } from './models';

const initialState: EnvironmentState = {
    authenticationMethod: AuthenticationMethods.LOCAL,
    apiUrl: process.env.REACT_APP_CLOE_URL ?? '',
};

export const environmentSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setAuthenticationMethod: (state, action: PayloadAction<AuthenticationMethodType>) => {
            state.authenticationMethod = action.payload;
        },
        setApiUrl: (state, action: PayloadAction<string>) => {
            state.apiUrl = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setAuthenticationMethod, setApiUrl } = environmentSlice.actions;

export default environmentSlice.reducer;
