import {
    useMantineTheme,
    Text,
    Title,
    Group,
    RingProgress,
    Card,
    Stack,
    Box,
    Anchor,
    ScrollArea,
    Button,
    Loader,
    Grid,
    Tooltip,
} from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import View from '@/common/components/View';
import { useGetDashboardQuery } from 'src/management/api';
import { DashboardProblem, GetDashboardResponse } from 'src/management/models';
import { createColumnHelper, useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import ConnectionStatusBadge from '../ConnectionStatusBadge';
import LocationsStatusMap from '../LocationsStatusMap';
import { $F, useFeatures } from '@/identity';
import { useFullscreen } from '@mantine/hooks';
import { IconWindowMaximize, IconRefresh } from '@tabler/icons';
import Logo from '@/common/components/Logo';

export interface DashboardProps {}

export const Dashboard = (props: DashboardProps) => {
    const [loading, setLoading] = useState(false);
    const theme = useMantineTheme();
    const { t, i18n } = useTranslation();
    const { hasSome } = useFeatures();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const isScreenSmall = screenWidth <= theme.breakpoints.xs;
    const {
        data: {
            connectionsCount = 0,
            connectionsDown = 0,
            connectionsPaused = 0,
            connectionsUnknown = 0,
            connectionsUp = 0,
            connectionsWarning = 0,
            errConnections = [],
            problems = [],
        } = {} as GetDashboardResponse,
        isLoading,
        isError,
        isFetching,
        refetch,
    } = useGetDashboardQuery(
        {},
        {
            pollingInterval: 60 * 1000,
        },
    );
    const columnHelper = createColumnHelper<DashboardProblem>();
    const columns = useMemo(
        () => [
            ...(hasSome($F.UxAdmin, $F.UxStaff)
                ? [
                      columnHelper.accessor((row) => row.customer?.name, {
                          id: 'customer.name',
                          header: (info) => <Text weight="bold">{t('Customer')}</Text>,
                          cell: (info) => (
                              <Anchor component={Link} to={`/customers/${info.row.original.customer?.id}`}>
                                  {info.getValue()}
                              </Anchor>
                          ),
                      }),
                  ]
                : []),
            columnHelper.accessor((row) => row.location?.name, {
                id: 'location.name',
                header: (info) => <Text weight="bold">{t('Location')}</Text>,
                cell: (info) => (
                    <Anchor component={Link} to={`/locations/${info.row.original.location?.id}`}>
                        {info.getValue()}
                    </Anchor>
                ),
            }),
            columnHelper.accessor((row) => row.connection?.name, {
                id: 'connection.name',
                header: () => <Text weight="bold">{t('Name')}</Text>,
                cell: (info) => (
                    <Anchor component={Link} to={`/connections/${info.row.original.connection?.id}`}>
                        {info.getValue()}
                    </Anchor>
                ),
            }),
            columnHelper.accessor((row) => row.connection?.status, {
                id: 'connection.status',
                header: () => <Text weight="bold">{t('Status')}</Text>,
                cell: (info) => (
                    <Anchor
                        component={Link}
                        to={`/connections/${info.row.original.connection?.id}`}
                        sx={{ textDecoration: 'none !important' }}
                    >
                        <ConnectionStatusBadge sx={{ cursor: 'pointer' }} status={info.getValue()} />
                    </Anchor>
                ),
            }),
        ],
        [columnHelper],
    );
    const gridTemplateColumns = hasSome($F.UxAdmin, $F.UxStaff)
        ? `1fr 1fr 1fr ${isScreenSmall ? '90px' : '128px'}`
        : `1fr 1fr ${isScreenSmall ? '90px' : '128px'}`;
    const table = useReactTable({
        data: problems,
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true,
    });

    const navigate = useNavigate();
    const filterConnectionsOnStatus = (status: string) => {
        navigate(`/connections?search=status:${status}`.toLocaleLowerCase());
    };

    const { toggle: toggleFullscreen, fullscreen: isFullscreen, ref: fullscreenRef } = useFullscreen();
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <View
            header={
                <Group position="apart" sx={{ minHeight: 36 }}>
                    <Title order={3}>{t('Welcome to CLOE!')}</Title>
                    <Group position="right">
                        <Tooltip label={t('Refresh')}>
                            <Button
                                variant="light"
                                color="pink"
                                onClick={() => !isFetching && refetch()}
                                disabled={isFetching}
                            >
                                {isFetching ? (
                                    <>
                                        <Loader color="pink" size="xs" />
                                        <IconRefresh />
                                    </>
                                ) : (
                                    <IconRefresh />
                                )}
                            </Button>
                        </Tooltip>
                        <Tooltip label={t('Toggle fullscreen')}>
                            <Button variant="light" color="pink" onClick={toggleFullscreen}>
                                <IconWindowMaximize />
                            </Button>
                        </Tooltip>
                    </Group>
                </Group>
            }
        >
            <Card.Section
                ref={fullscreenRef}
                sx={[
                    { flexGrow: 1, position: 'relative' },
                    { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white },
                ]}
            >
                {isFullscreen && (
                    <Logo
                        p="sm"
                        sx={{ width: 256, position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)' }}
                    />
                )}
                <ScrollArea
                    type="hover"
                    sx={{
                        position: 'absolute !important' as 'absolute', // TODO: total hack for !important?
                        top: 0,
                        bottom: 0,
                        width: `calc(100% - ${theme.spacing.md * 0}px)`,
                        marginTop: isFullscreen ? '30px' : '0',
                    }}
                >
                    <Grid sx={{ width: '100%' }}>
                        <Grid.Col xs="auto" span={12}>
                            <LocationsStatusMap
                                key={isFullscreen ? 'fullscreen' : 'normal'}
                                mt="md"
                                ml="md"
                                sx={{
                                    height: isScreenSmall ? 300 : isFullscreen ? 600 : 400,
                                    width: isScreenSmall ? '100%' : isFullscreen ? '90%' : '100%',
                                    flexGrow: 1,
                                    borderRadius: theme.radius.sm,
                                    overflow: 'hidden',
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col
                            xs="content"
                            span={12}
                            pl={isScreenSmall ? '15px' : 'xl'}
                            pr={isScreenSmall ? '25px' : 'xl'}
                            mt={isScreenSmall ? '0px' : '15px'}
                        >
                            <Grid
                                sx={
                                    isScreenSmall
                                        ? { flexGrow: 1 }
                                        : {
                                              width: '240px',
                                              transform: isFullscreen ? 'scale(1.5) translate(-20%, 20%)' : 'scale(1)',
                                          }
                                }
                            >
                                <Grid.Col
                                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    xs={12}
                                    span={4}
                                >
                                    <RingProgress
                                        label={
                                            <Text size="lg" weight="bold" align="center">
                                                {connectionsCount}
                                            </Text>
                                        }
                                        size={isScreenSmall ? 100 : 224}
                                        thickness={isScreenSmall ? 14 : 32}
                                        sections={[
                                            {
                                                value: 100 * (connectionsUp / (connectionsCount + 0.5)),
                                                color: 'teal',
                                            },
                                            {
                                                value: 100 * (connectionsDown / (connectionsCount + 0.5)),
                                                color: 'red',
                                            },
                                            {
                                                value: 100 * (connectionsWarning / (connectionsCount + 0.5)),
                                                color: 'yellow',
                                            },
                                            {
                                                value: 100 * (connectionsUnknown / (connectionsCount + 0.5)),
                                                color: 'gray',
                                            },
                                            {
                                                value: 100 * (connectionsPaused / (connectionsCount + 0.5)),
                                                color: 'violet',
                                            },
                                        ]}
                                    />
                                </Grid.Col>
                                <Grid.Col xs={12} span={4}>
                                    <Stack spacing="xs" mt={isScreenSmall ? '10px' : '-5px'}>
                                        <Button
                                            onClick={() => filterConnectionsOnStatus('UP')}
                                            color="teal"
                                            variant="filled"
                                            radius="xl"
                                            compact
                                            size="xs"
                                        >
                                            {connectionsUp} {t('UP')}
                                        </Button>
                                        <Button
                                            onClick={() => filterConnectionsOnStatus('DOWN')}
                                            color="red"
                                            variant="filled"
                                            radius="xl"
                                            compact
                                            size="xs"
                                        >
                                            {connectionsDown} {t('DOWN')}
                                        </Button>
                                        <Button
                                            onClick={() => filterConnectionsOnStatus('WARNING')}
                                            color="yellow"
                                            variant="filled"
                                            radius="xl"
                                            compact
                                            size="xs"
                                        >
                                            {connectionsWarning} {t('WARNING')}
                                        </Button>
                                    </Stack>
                                </Grid.Col>
                                <Grid.Col xs={12} span={4}>
                                    <Stack spacing="xs" mt={isScreenSmall ? '10px' : '-5px'}>
                                        <Button
                                            onClick={() => filterConnectionsOnStatus('UNKNOWN')}
                                            color="gray"
                                            variant="filled"
                                            radius="xl"
                                            compact
                                            size="xs"
                                        >
                                            {connectionsUnknown} {t('UNKNOWN')}
                                        </Button>
                                        <Button
                                            onClick={() => filterConnectionsOnStatus('PAUSED')}
                                            color="violet"
                                            variant="filled"
                                            radius="xl"
                                            compact
                                            size="xs"
                                        >
                                            {connectionsPaused} {t('PAUSED')}
                                        </Button>
                                    </Stack>
                                </Grid.Col>
                            </Grid>
                        </Grid.Col>
                    </Grid>
                    <Group position="apart" align="flex-start" px="md" sx={{ flexWrap: 'nowrap' }}>
                        <Stack sx={{ flexGrow: 1 }} spacing={0}>
                            <Text size="lg" weight="bold" mx="xs" mt="xs">
                                {t('Alerts')}
                            </Text>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns,
                                }}
                            >
                                {table.getHeaderGroups().map((headerGroup) =>
                                    headerGroup.headers.map((header) => (
                                        <Box key={header.id} px="xs" pt="xs">
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(header.column.columnDef.header, header.getContext())}
                                        </Box>
                                    )),
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns,
                                }}
                            >
                                {table.getRowModel().rows.map((row) =>
                                    row.getVisibleCells().map((cell) => (
                                        <Box
                                            key={cell.id}
                                            p="xs"
                                            sx={{
                                                borderBottom: `1px solid ${
                                                    theme.colorScheme === 'dark'
                                                        ? theme.colors.dark[4]
                                                        : theme.colors.gray[2]
                                                }`,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Box>
                                    )),
                                )}
                            </Box>
                        </Stack>
                    </Group>
                </ScrollArea>
            </Card.Section>
        </View>
    );
};

export default Dashboard;
