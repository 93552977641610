import { selectWhoAmI } from '@/identity';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

export const RequiresAuth = ({ children }: { children: JSX.Element }) => {
    const whoAmI = useSelector(selectWhoAmI);
    const location = useLocation();

    if (!whoAmI) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="login" state={{ from: location }} replace />;
    }

    return children;
};
