import { Group, ThemeIcon, UnstyledButton, Text, useMantineTheme, DefaultProps } from '@mantine/core';
import { Link, LinkProps, useLocation, useResolvedPath } from 'react-router-dom';

export interface NavRowProps extends LinkProps {
    icon?: React.ReactNode;
    color?: string;
    label?: string;
    element?: React.ReactNode;
    end?: boolean;
}

export const NavRow = ({
    icon,
    color,
    label,
    element,
    to,
    end,
    'aria-current': ariaCurrentProp = 'page',
    ...rest
}: NavRowProps & DefaultProps) => {
    const theme = useMantineTheme();
    let location = useLocation();
    let path = useResolvedPath(to);

    let locationPathname = location.pathname.toLowerCase();
    let toPathname = path.pathname.toLowerCase();

    let isActive =
        locationPathname === toPathname ||
        (!end && locationPathname.startsWith(toPathname) && locationPathname.charAt(toPathname.length) === '/');

    let ariaCurrent = isActive ? ariaCurrentProp : undefined;
    return (
        <UnstyledButton
            component={Link}
            data-active={isActive || undefined}
            to={to}
            p="xs"
            sx={{
                display: 'block',
                width: '100%',
                padding: theme.spacing.xs,
                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                borderRight: `solid ${theme.spacing.xs}px transparent`,
                '&:hover': {
                    ...theme.fn.hover({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
                    }),
                },
                '&[data-active]': {
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                    borderColor: theme.colorScheme === 'dark' ? theme.colors.clover[8] : theme.colors.clover[6],
                },
                '&[data-active]:hover': {
                    ...theme.fn.hover({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
                    }),
                },
            }}
            {...rest}
        >
            <Group>
                <ThemeIcon color={color} variant="light" size={32}>
                    {icon}
                </ThemeIcon>
                <Text size="sm">{label}</Text>
                {element}
            </Group>
        </UnstyledButton>
    );
};

export default NavRow;
