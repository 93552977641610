import { GetGroupsRequest, Group } from '@/identity/models';
import { Continuation } from '@/models';
import { Anchor, Card, Group as GroupEl, Text, Title } from '@mantine/core';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import List from '@/common/components/List';
import Pagination from '@/common/components/Pagination';
import { useGetGroupsQuery } from '@/identity/api';
import { useAllowsActionOnResource } from '@/identity';

export interface GroupsProps {}

export const Groups = ({}: GroupsProps) => {
    const location = useLocation();
    const { t } = useTranslation();
    const allowsActionOnResource = useAllowsActionOnResource();
    const [req, setRequest] = useState<GetGroupsRequest>({
        returnCount: true,
    });
    const [virtPageNumber, setVirtPageNumber] = useState(0);
    const {
        data: { groups = [], continue: paging = { count: -1, limit: 0 } as Continuation } = {},
        isLoading,
        isError,
    } = useGetGroupsQuery(req);
    const columnHelper = createColumnHelper<Group>();
    const columns = useMemo(
        () => [
            columnHelper.accessor('name', {
                header: () => <Text weight="bold">{t('Name')}</Text>,
                cell: (info) => (
                    <Anchor component={Link} to={`${info.row.original.id}`}>
                        {info.getValue()}
                    </Anchor>
                ),
            }),
        ],
        [columnHelper],
    );
    const gridTemplateColumns = '1fr';
    const table = useReactTable({
        data: groups,
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true,
    });

    const goToNextPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber + 1);
        setRequest((req) => ({
            ...req,
            continue: paging.next,
        }));
    };

    const goToPrevPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber - 1);
        setRequest((req) => ({
            ...req,
            continue: paging.prev,
        }));
    };

    // const [search, setSearch] = useState("");
    // const [searchQuery] = useDebouncedValue(search, 200);
    // if (searchQuery != req.search) {
    //     setRequest((req) => ({
    //         ...req,
    //         continue: "",
    //         search: searchQuery,
    //     }));
    // }

    return (
        <List
            table={table}
            gridTemplateColumns={gridTemplateColumns}
            header={
                <GroupEl position="apart" sx={{ minHeight: 36 }}>
                    <Title order={3}>{t('All Groups')}</Title>
                    <GroupEl position="right">
                        {/* {allowsActionOnResource(
                            $P.CanManageUser,
                            $P.UsersResource
                        ) ? (
                            <Button
                                component={Link}
                                to={"new"}
                                state={{ backgroundLocation: location }}
                                variant="light"
                                color="green"
                                px="xs"
                            >
                                <IconUser />
                                <IconCirclePlus />
                            </Button>
                        ) : null} */}
                        {/* <TextInput
                            placeholder="Search"
                            icon={<IconSearch size={16} stroke={1.5} />}
                            onChange={(evt) =>
                                setSearch(evt.currentTarget.value)
                            }
                        /> */}
                    </GroupEl>
                </GroupEl>
            }
            footer={
                <Card.Section withBorder>
                    <GroupEl position="apart" p="sm">
                        <Pagination
                            goToNextPage={goToNextPage}
                            goToPrevPage={goToPrevPage}
                            virtPageNumber={virtPageNumber}
                            limit={paging.limit}
                            count={paging.count}
                        />
                    </GroupEl>
                </Card.Section>
            }
        />
    );
};

export default Groups;
