import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const projectSlice = createSlice({
    name: 'projects',
    initialState: {
        installed: false,
        inprogress: true,
        search: '',
        virtPageNumber: 0,
        hubSpotPages: ['0'],
        allCompanies: [''],
        sortBy: 'createdate',
        direction: 'DESCENDING',
        continuePage: '0',
    },
    reducers: {
        setInstalled: (state, action: PayloadAction<boolean>) => {
            state.installed = action.payload;
        },
        setInProgress: (state, action: PayloadAction<boolean>) => {
            state.inprogress = action.payload;
        },
        setSearch: (state, action: PayloadAction<string>) => {
            state.search = action.payload;
        },
        setVirtPageNumber: (state, action: PayloadAction<number>) => {
            state.virtPageNumber = action.payload;
        },
        setHubSpotPages: (state, action: PayloadAction<string[]>) => {
            state.hubSpotPages = action.payload;
        },
        setAllCompanies: (state, action: PayloadAction<string[]>) => {
            state.allCompanies = action.payload;
        },
        setSortBy: (state, action: PayloadAction<string>) => {
            state.sortBy = action.payload;
        },
        setDirection: (state, action: PayloadAction<string>) => {
            state.direction = action.payload;
        },
        setContinuePage: (state, action: PayloadAction<string>) => {
            state.continuePage = action.payload;
        },
    },
});

export const {
    setInstalled,
    setInProgress,
    setSearch,
    setHubSpotPages,
    setVirtPageNumber,
    setAllCompanies,
    setSortBy,
    setContinuePage,
    setDirection,
} = projectSlice.actions;

export default projectSlice.reducer;
