import { useDebouncedValue } from '@mantine/hooks';
import { useMemo, useState } from 'react';
import { useGetCustomersQuery, useGetLocationsQuery } from './api';
import { GetCustomersRequest, GetLocationsRequest } from './models';

export const useCustomerSelectData = () => {
    const [req, setRequest] = useState<GetCustomersRequest>({
        search: '',
    });
    const { data: { customers = [] } = {} } = useGetCustomersQuery(req);
    const [search, setSearch] = useState('');
    const [searchQuery] = useDebouncedValue(search, 200);
    if (searchQuery != req.search) {
        setRequest((req) => ({
            ...req,
            search: searchQuery,
        }));
    }
    const customerSelectData = useMemo(() => {
        if (req.search == '') {
            return [];
        }
        return customers.map((c) => ({
            label: c.name,
            value: c.id,
        }));
    }, [customers, req]);
    return [customerSelectData, setSearch, search] as const;
};

export const useLocationSelectData = () => {
    const [req, setRequest] = useState<GetLocationsRequest>({
        search: '',
    });
    const { data: { locations = [] } = {} } = useGetLocationsQuery(req);
    const [search, setSearch] = useState('');
    const [searchQuery] = useDebouncedValue(search, 200);
    if (searchQuery != req.search) {
        setRequest((req) => ({
            ...req,
            search: searchQuery,
        }));
    }
    const locationSelectData = useMemo(() => {
        if (req.search == '') {
            return [];
        }
        return locations.map((l) => ({
            label: l.name,
            value: l.id,
        }));
    }, [locations, req]);
    return [locationSelectData, setSearch, search] as const;
};
