import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from '@/app/store';
import ThemeProvider from '@/app/components/ThemeProvider';
import App from '@/app/components/App';
import { GlobalStyles } from 'src/styles/GlobalStyles';

const root = createRoot(document.getElementById('root')!);
const store = createStore();

root.render(
    <StrictMode>
        <Provider store={store}>
            <ThemeProvider withNormalizeCSS>
                <GlobalStyles />
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
