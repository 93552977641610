import { Badge, Navbar, NavbarProps, ScrollArea } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useAllowsActionOnResource, useAllowsFor } from '@/identity';
import NavWhoAmI from './NavWhoAmI';
import * as $P from '@/identity/policy';
import NavRow from './NavRow';
import {
    IconAddressBook,
    IconAffiliate,
    IconBuildingSkyscraper,
    IconDashboard,
    IconListCheck,
    IconNetwork,
    IconSos,
    IconUser,
    IconUsers,
} from '@tabler/icons';

export interface NavProps extends Omit<NavbarProps, 'children'> {}

export const AppNav = ({ ...rest }: NavProps) => {
    const { t, i18n } = useTranslation();
    const allowsActionOnResource = useAllowsActionOnResource();
    const allowsFor = useAllowsFor();
    return (
        <Navbar sx={{ flexShrink: 0 }} {...rest}>
            <Navbar.Section grow component={ScrollArea} ml={-10} mr={-10} sx={{ paddingLeft: 10, paddingRight: 10 }}>
                {allowsFor([$P.CanListConnections, $P.ConnectionsResource]) ? (
                    <NavRow icon={<IconDashboard />} color="pink" label={t('Dashboard')} to="/" />
                ) : null}
                {allowsActionOnResource($P.CanListCustomers, $P.CustomersResource) ? (
                    <NavRow icon={<IconAffiliate />} color="cyan" label={t('Customers')} to="/customers" />
                ) : null}
                {allowsActionOnResource($P.CanListLocations, $P.LocationsResource) ? (
                    <NavRow icon={<IconBuildingSkyscraper />} color="grape" label={t('Locations')} to="/locations" />
                ) : null}
                {allowsActionOnResource($P.CanListConnections, $P.ConnectionsResource) ? (
                    <NavRow icon={<IconNetwork />} color="blue" label={t('Connections')} to="/connections" />
                ) : null}
                {allowsActionOnResource($P.CanListTickets, $P.TicketsResource) ? (
                    <NavRow icon={<IconSos />} color="yellow" label={t('Tickets')} to="/tickets" />
                ) : null}
                {allowsActionOnResource($P.CanListConnections, $P.ConnectionsResource) ? (
                    <NavRow
                        icon={<IconListCheck />}
                        color="green"
                        label={t('Projects')}
                        element={
                            <Badge color="green" variant="light">
                                Beta
                            </Badge>
                        }
                        to="/projects"
                    />
                ) : null}
                {allowsActionOnResource($P.CanListContacts, $P.ContactsResource) ? (
                    <NavRow icon={<IconAddressBook />} color="indigo" label={t('Contacts')} to="/contacts" />
                ) : null}
                {allowsActionOnResource($P.CanListUsers, $P.UsersResource) ? (
                    <NavRow icon={<IconUser />} color="red" label={t('Users')} to="/users" />
                ) : null}
                {allowsActionOnResource($P.CanListGroups, $P.GroupsResource) ? (
                    <NavRow icon={<IconUsers />} color="orange" label={t('Groups')} to="/groups" />
                ) : null}
            </Navbar.Section>
            <Navbar.Section>
                <NavWhoAmI />
            </Navbar.Section>
        </Navbar>
    );
};

export default AppNav;
