import { rootApi } from '@/api';
import {
    GetTicketsRequest,
    GetTicketsResponse,
    GetTicketRequest,
    GetTicketResponse,
    GetProjectsRequest,
    GetProjectsResponse,
    GetProjectRequest,
    GetProjectResponse,
} from './models';

const apiWithTag = rootApi.enhanceEndpoints({ addTagTypes: ['Ticket', 'Project', 'ChildCompanies'] });

export const supportApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
        getTickets: build.query<GetTicketsResponse, GetTicketsRequest>({
            query: (req: GetTicketsRequest) => ({
                url: `tickets`,
                params: req,
            }),
            providesTags: (res) =>
                res
                    ? [
                          ...res.tickets.map(({ id }) => ({
                              type: 'Ticket' as const,
                              id,
                          })),
                          'Ticket',
                      ]
                    : ['Ticket'],
        }),
        getTicket: build.query<GetTicketResponse, GetTicketRequest>({
            query: (req: GetTicketRequest) => ({
                url: `tickets/${req.id}`,
            }),
            providesTags: (res) => [{ type: 'Ticket' as const, id: res?.ticket.id }],
        }),
        getProjects: build.query<GetProjectsResponse, GetProjectsRequest>({
            query: (req: GetProjectsRequest) => ({
                url: `projects`,
                method: 'POST',
                body: req,
            }),
            providesTags: (res) =>
                res
                    ? [
                          ...res.projects.map(({ id }) => ({
                              type: 'Project' as const,
                              id,
                          })),
                          'Project',
                      ]
                    : ['Project'],
        }),
        getProject: build.query<GetProjectResponse, GetProjectRequest>({
            query: (req: GetProjectRequest) => ({
                url: `projects/${req.id}`,
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetTicketsQuery,
    useGetTicketQuery,
    useGetProjectsQuery,
    useGetProjectQuery,
    useLazyGetProjectQuery,
} = supportApi;
