import Drawer, { DrawerContext, DrawerProps } from '@/common/components/Drawer';
import { Text, useComponentDefaultProps } from '@mantine/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useLazyGetCustomerQuery } from 'src/management/api';
import { Customer } from 'src/management/models';
import UpdateCustomerForm from './UpdateCustomerForm';

export interface UpdateCustomerProps extends DrawerProps {}

const componentName = 'CLUIUpdateCustomer';
const defaultProps: Partial<UpdateCustomerProps> = {
    size: '50vw',
    padding: 'md',
    position: 'right',
    closeOnClickOutside: false,
    overlayOpacity: 0.0,
    shadow: 'lg',
};

export const UpdateCustomer = (props: UpdateCustomerProps) => {
    const { onClose, ...rest } = useComponentDefaultProps(componentName, defaultProps, props);
    const { customerId } = useParams();
    const { t, i18n } = useTranslation();
    const [getCustomer, { data: { customer = {} as Customer } = {} }] = useLazyGetCustomerQuery();
    useEffect(() => {
        if (customerId) {
            getCustomer({ id: customerId });
        }
    }, [customerId]);
    return (
        <Drawer
            onClose={onClose}
            {...rest}
            shadow="lg"
            title={
                <Text weight="bold">
                    {t('Update')}: {customer?.name ?? t('Unknown')}
                </Text>
            }
        >
            <DrawerContext.Consumer>
                {({ onClose }) => <UpdateCustomerForm customer={customer} customerId={customerId!} onClose={onClose} />}
            </DrawerContext.Consumer>
        </Drawer>
    );
};

export default UpdateCustomer;
