import { rootApi } from '../api';
import {
    CreateUserRequest,
    CreateUserResponse,
    GetGroupMembersRequest,
    GetGroupMembersResponse,
    GetGroupsRequest,
    GetGroupsResponse,
    GetUserRequest,
    GetUserResponse,
    DeleteUserRequest,
    DeleteUserResponse,
    GetUsersRequest,
    GetUsersResponse,
    LoginRequest,
    LoginResponse,
    SLICE_NAME,
    UpdateUserRequest,
    UpdateUserResponse,
    GetUserGroupsRequest,
    GetUserGroupsResponse,
    GetUserPolicyRequest,
    GetUserPolicyResponse,
    SetUserGroupsRequest,
    SetUserGroupsResponse,
    SetUserPolicyRequest,
    SetUserPolicyResponse,
    GetGroupPolicyRequest,
    GetGroupPolicyResponse,
    SetGroupPolicyRequest,
    SetGroupPolicyResponse,
    CreateGroupRequest,
    CreateGroupResponse,
    DeleteGroupRequest,
    DeleteGroupResponse,
    UpdateGroupRequest,
    UpdateGroupResponse,
    LogoutRequest,
    LogoutResponse,
    WhoAmIRequest,
    WhoAmIResponse,
    GetGroupRequest,
    GetGroupResponse,
    NewPasswordRequest,
    NewPasswordResponse,
    PasswordResetRequest,
    PasswordResetResponse,
    VerifyRecaptchaRequest,
    VerifyRecaptchaResponse,
} from './models';

const apiWithTag = rootApi.enhanceEndpoints({
    addTagTypes: ['User', 'UserRelation', 'Group', 'GroupRelation', 'Policy'],
});

export const identityApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<LoginResponse, LoginRequest>({
            query: (req: LoginRequest) => ({
                url: `login`,
                method: 'POST',
                body: req,
                credentials: 'include',
            }),
        }),
        logout: build.mutation<LogoutResponse, LogoutRequest>({
            query: (req: LogoutRequest) => ({
                url: `logout`,
                method: 'POST',
                body: req,
                credentials: 'include',
            }),
        }),
        whoAmI: build.mutation<WhoAmIResponse, WhoAmIRequest>({
            query: (req: WhoAmIRequest) => ({
                url: `whoami`,
                method: 'POST',
                body: req,
            }),
        }),
        getUsers: build.query<GetUsersResponse, GetUsersRequest>({
            query: (req: GetUsersRequest) => ({
                url: `users`,
                params: req,
            }),
            providesTags: (res, err, arg) =>
                res
                    ? [
                          ...res.users.map(({ id }) => ({
                              type: 'User' as const,
                              id,
                          })),
                          'User',
                      ]
                    : ['User'],
        }),
        getUser: build.query<GetUserResponse, GetUserRequest>({
            query: (req: GetUserRequest) => ({
                url: `users/${req.id}`,
            }),
            providesTags: (res, err, arg) => [{ type: 'User' as const, id: res?.user.id }],
        }),
        getUserGroups: build.query<GetUserGroupsResponse, GetUserGroupsRequest>({
            query: ({ userId, ...params }: GetUserGroupsRequest) => ({
                url: `users/${userId}/groups`,
                params: params,
            }),
            providesTags: (res, err, arg) =>
                res
                    ? [
                          ...res.groups.map(({ id }) => ({
                              type: 'GroupRelation' as const,
                              id,
                          })),
                          'GroupRelation',
                      ]
                    : ['GroupRelation'],
        }),
        setUserGroups: build.mutation<SetUserGroupsResponse, SetUserGroupsRequest>({
            query: (req: SetUserGroupsRequest) => ({
                url: `users/${req.userId}/groups`,
                method: 'POST',
                body: req,
            }),
            invalidatesTags: ['GroupRelation'],
        }),
        getUserPolicy: build.query<GetUserPolicyResponse, GetUserPolicyRequest>({
            query: (req: GetUserPolicyRequest) => ({
                url: `users/${req.userId}/policy`,
            }),
            providesTags: ['Policy'],
        }),
        setUserPolicy: build.mutation<SetUserPolicyResponse, SetUserPolicyRequest>({
            query: (req: SetUserPolicyRequest) => ({
                url: `users/${req.userId}/policy`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: ['Policy'],
        }),
        createUser: build.mutation<CreateUserResponse, CreateUserRequest>({
            query: (req: CreateUserRequest) => ({
                url: `users`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: ['User'],
        }),
        updateUser: build.mutation<UpdateUserResponse, UpdateUserRequest>({
            query: (req: UpdateUserRequest) => ({
                url: `users/${req.user.id}`,
                method: 'PATCH',
                body: req,
            }),
            invalidatesTags: (res, err, arg) => [{ type: 'User' as const, id: res?.user.id }, 'GroupRelation'],
        }),
        deleteUser: build.mutation<DeleteUserResponse, DeleteUserRequest>({
            query: (req: DeleteUserRequest) => ({
                url: `users/${req.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (res, err, arg) => ['User', 'GroupRelation'],
        }),
        getGroups: build.query<GetGroupsResponse, GetGroupsRequest>({
            query: (req: GetGroupsRequest) => ({
                url: `groups`,
                params: req,
            }),
            providesTags: ['Group'],
        }),
        getGroupMembers: build.query<GetGroupMembersResponse, GetGroupMembersRequest>({
            query: (req: GetGroupMembersRequest) => ({
                url: `groups/${req.groupId}/members`,
                params: req,
            }),
            providesTags: (res, err, arg) =>
                res
                    ? [
                          ...res.users.map(({ id }) => ({
                              type: 'UserRelation' as const,
                              id,
                          })),
                          'UserRelation',
                      ]
                    : ['UserRelation'],
        }),
        createGroup: build.mutation<CreateGroupResponse, CreateGroupRequest>({
            query: (req: CreateGroupRequest) => ({
                url: `groups`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: ['Group'],
        }),
        updateGroup: build.mutation<UpdateGroupResponse, UpdateGroupRequest>({
            query: (req: UpdateGroupRequest) => ({
                url: `groups/${req.group.id}`,
                method: 'PATCH',
                body: req,
            }),
            invalidatesTags: (res, err, arg) => [{ type: 'Group' as const, id: res?.group.id }],
        }),
        deleteGroup: build.mutation<DeleteGroupResponse, DeleteGroupRequest>({
            query: (req: DeleteGroupRequest) => ({
                url: `groups/${req.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Group'],
        }),
        getGroupPolicy: build.query<GetGroupPolicyResponse, GetGroupPolicyRequest>({
            query: (req: GetGroupPolicyRequest) => ({
                url: `groups/${req.groupId}/policy`,
            }),
            providesTags: ['Policy'],
        }),
        setGroupPolicy: build.mutation<SetGroupPolicyResponse, SetGroupPolicyRequest>({
            query: (req: SetGroupPolicyRequest) => ({
                url: `groups/${req.groupId}/policy`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: ['Policy'],
        }),
        getGroup: build.query<GetGroupResponse, GetGroupRequest>({
            query: (req: GetGroupRequest) => ({
                url: `groups/${req.id}`,
            }),
            providesTags: (res, err, arg) => [{ type: 'Group' as const, id: res?.group.id }],
        }),
        newPassword: build.mutation<NewPasswordResponse, NewPasswordRequest>({
            query: (req: NewPasswordRequest) => ({
                url: `password-reset/verify`,
                method: 'POST',
                body: req,
                credentials: 'include',
            }),
        }),
        passwordReset: build.mutation<PasswordResetResponse, PasswordResetRequest>({
            query: (req: PasswordResetRequest) => ({
                url: `password-reset/send`,
                method: 'POST',
                body: req,
                credentials: 'include',
            }),
        }),
        verifyRecaptcha: build.mutation<VerifyRecaptchaResponse, VerifyRecaptchaRequest>({
            query: (req: VerifyRecaptchaRequest) => ({
                url: `recaptcha/verify`,
                method: 'POST',
                body: req,
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetUserQuery,
    useGetUsersQuery,
    useDeleteUserMutation,
    useCreateUserMutation,
    useUpdateUserMutation,
    useGetGroupsQuery,
    useGetGroupMembersQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useGetUserGroupsQuery,
    useSetUserGroupsMutation,
    useDeleteGroupMutation,
    useGetUserPolicyQuery,
    useSetUserPolicyMutation,
    useGetGroupPolicyQuery,
    useSetGroupPolicyMutation,
    useLogoutMutation,
    useWhoAmIMutation,
    useGetGroupQuery,
    useNewPasswordMutation,
    usePasswordResetMutation,
    useVerifyRecaptchaMutation,
} = identityApi;

export const {
    useLazyGetUserQuery,
    useLazyGetGroupQuery,
    useLazyGetGroupsQuery,
    useLazyGetGroupMembersQuery,
    useLazyGetUserGroupsQuery,
    useLazyGetGroupPolicyQuery,
    useLazyGetUserPolicyQuery,
} = identityApi;

export const useLoginMutation = (options?: Parameters<typeof identityApi.useLoginMutation>[0]) => {
    return identityApi.useLoginMutation({
        ...options,
        fixedCacheKey: `${SLICE_NAME}/login`,
    });
};
