import {
    useMantineTheme,
    Title,
    Center,
    Group,
    Card,
    Stack,
    Image,
    Tabs,
    Button,
    Menu,
    TextInput,
    Anchor,
    Modal,
    Alert,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import View from '@/common/components/View';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { $F, useAllowsActionOnResource, useFeatures } from '@/identity';
import { useGetLocationQuery, useDeleteLocationMutation } from '@/management/api';
import { Location as LocationType } from '@/management/models';
import {
    IconAffiliate,
    IconDots,
    IconMapPin,
    IconNetwork,
    IconPencil,
    IconSearch,
    IconSquareX,
    IconAlertCircle,
} from '@tabler/icons';
import { Link } from 'react-router-dom';
import * as $P from '@/identity/policy';
import Address from 'src/common/components/Address';
import LocationConnections from '../LocationConnections';
import { useState } from 'react';
import LabeledText from 'src/common/components/LabeledText';
import AssociatedContact from 'src/crm/components/AssociatedContact';
import { useDisclosure } from '@mantine/hooks';
import { getErrorMessage } from '@/errors';

export interface LocationProps {}

// https://picsum.photos/200/200

export const Location = (props: LocationProps) => {
    const theme = useMantineTheme();
    const allowsActionOnResource = useAllowsActionOnResource();
    const { hasSome } = useFeatures();
    const { locationId, tab } = useParams();
    const { t, i18n } = useTranslation();
    const rtrLocation = useLocation();
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);
    const [deleteLocation, { isSuccess }] = useDeleteLocationMutation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const handleDelete = async () => {
        setErrorMessage(null);
        try {
            await deleteLocation({ id: location.id }).unwrap();
            navigate(-1);
        } catch (err) {
            setErrorMessage(getErrorMessage(err));
        }
    };
    const {
        data: { location = {} as LocationType } = {},
        isLoading,
        isError,
    } = useGetLocationQuery({ id: locationId! });
    const [search, setSearch] = useState('');

    function onTabChange(value: string) {
        navigate(`./../${value}`, { replace: true });
    }

    // goto default tab
    if (!tab) {
        return <Navigate to={'connections'} replace />;
    }

    return (
        <View
            header={
                <Group position="apart" sx={{ minHeight: 36 }}>
                    <Title order={3}>{location.name ?? t('Unknown')}</Title>
                    <Group position="right">
                        <Menu position="left-start" shadow="md" width={200}>
                            <Menu.Target>
                                <Button variant="light" color="grape" px="xs">
                                    <IconDots />
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>Actions</Menu.Label>
                                {allowsActionOnResource($P.CanManageLocation, $P.LocationResourceFmt(locationId!)) ? (
                                    <>
                                        <Menu.Item
                                            component={Link}
                                            icon={<IconPencil size={14} />}
                                            state={{
                                                backgroundLocation: rtrLocation,
                                            }}
                                            to="./../edit"
                                        >
                                            {t('Edit')}
                                        </Menu.Item>
                                        <Menu.Item component="button" icon={<IconSquareX size={14} />} onClick={open}>
                                            {t('Delete')}
                                        </Menu.Item>
                                    </>
                                ) : null}
                                {allowsActionOnResource($P.CanManageConnection, $P.ConnectionsResource) ? (
                                    <Menu.Item
                                        component={Link}
                                        icon={<IconNetwork size={14} />}
                                        to={`./../connections/new`}
                                        state={{
                                            backgroundLocation: rtrLocation,
                                        }}
                                    >
                                        {t('Create Connection')}
                                    </Menu.Item>
                                ) : null}
                            </Menu.Dropdown>
                        </Menu>
                        <TextInput
                            placeholder="Search"
                            icon={<IconSearch size={16} stroke={1.5} />}
                            onChange={(evt) => setSearch(evt.currentTarget.value)}
                        />
                        {allowsActionOnResource($P.CanManageLocation, $P.LocationResourceFmt(locationId!)) ? (
                            <Modal
                                opened={opened}
                                onClose={close}
                                title={
                                    <>
                                        Are you sure?
                                        <br />
                                        Location {location.name} will be permanently deleted.
                                    </>
                                }
                                centered
                                withCloseButton={false}
                            >
                                <p>
                                    This will also remove all associated:
                                    <ul>
                                        <li>Connections</li>
                                    </ul>
                                </p>
                                {errorMessage ? (
                                    <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                                        {errorMessage}
                                    </Alert>
                                ) : null}
                                <Group position="right" mt={'md'}>
                                    <Button
                                        color={'gray'}
                                        onClick={() => {
                                            setErrorMessage(null);
                                            close();
                                        }}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color={'red'}
                                        loading={isLoading}
                                        leftIcon={<IconSquareX />}
                                        onClick={handleDelete}
                                    >
                                        {t('Delete')}
                                    </Button>
                                </Group>
                            </Modal>
                        ) : null}
                    </Group>
                </Group>
            }
        >
            <Card.Section>
                <Group position="left" m="md" align="flex-start">
                    <Image
                        radius="md"
                        width={200}
                        height={200}
                        fit="contain"
                        src={location?.logoUrl || location?.customer?.logoUrl}
                        withPlaceholder
                    />
                    <Stack spacing="xs">
                        {hasSome($F.UxAdmin, $F.UxStaff) ? (
                            <Group position="left">
                                <Center>
                                    <IconAffiliate />
                                </Center>
                                <LabeledText label={t('Customer')} raw>
                                    <Anchor component={Link} to={`/customers/${location?.customer?.id}`}>
                                        {location?.customer?.name ?? t('Unknown')}
                                    </Anchor>
                                </LabeledText>
                            </Group>
                        ) : null}
                        <Group position="left">
                            <Center>
                                <IconMapPin />
                            </Center>
                            <LabeledText label={t('Address')} raw>
                                <Address address={location.address} />
                            </LabeledText>
                        </Group>
                        {hasSome($F.UxStaff, $F.UxAdmin) ? (
                            <AssociatedContact
                                label={t('Administrative Contact')}
                                contact={location?.administrativeContact}
                            />
                        ) : null}

                        {hasSome($F.UxStaff, $F.UxAdmin) ? (
                            <AssociatedContact label={t('Technical Contact')} contact={location?.technicalContact} />
                        ) : null}
                    </Stack>
                </Group>
            </Card.Section>
            <Card.Section
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                <Tabs
                    defaultValue="connections"
                    value={tab}
                    onTabChange={onTabChange}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}
                >
                    <Tabs.List>
                        <Tabs.Tab value="connections" icon={<IconNetwork size={16} />}>
                            {t('Connections')}
                        </Tabs.Tab>
                        {/* <Tabs.Tab value="other" icon={<IconQuestionMark size={16} />}>
                            {t("Other")}
                        </Tabs.Tab> */}
                        <Group position="right" sx={{ flexGrow: 1 }}></Group>
                    </Tabs.List>
                    <Tabs.Panel value="connections" sx={{ height: '100%' }}>
                        {locationId ? (
                            <LocationConnections locationId={locationId} location={location} search={search} />
                        ) : null}
                    </Tabs.Panel>
                    {/* <Tabs.Panel value="other" sx={{ height: "100%" }}>
                        <Center sx={{ height: "100%" }}>Other</Center>
                    </Tabs.Panel> */}
                </Tabs>
            </Card.Section>
        </View>
    );
};

export default Location;
