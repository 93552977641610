import Drawer, { DrawerContext, DrawerProps } from '@/common/components/Drawer';
import { Text, useComponentDefaultProps } from '@mantine/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useLazyGetUserPolicyQuery, useLazyGetUserQuery } from 'src/identity/api';
import { Policy, User } from 'src/identity/models';
import SetGroupPolicyForm from './SetUserPolicyForm';

export interface SetUserPolicyProps extends DrawerProps {}

const componentName = 'CLUISetUserPolicy';
const defaultProps: Partial<SetUserPolicyProps> = {
    size: '50vw',
    padding: 'md',
    position: 'right',
    closeOnClickOutside: false,
    overlayOpacity: 0.0,
    shadow: 'lg',
};

export const SetUserPolicy = (props: SetUserPolicyProps) => {
    const { onClose, ...rest } = useComponentDefaultProps(componentName, defaultProps, props);
    const { t, i18n } = useTranslation();
    const { userId } = useParams();
    const [getUser, { data: { user = {} as User } = {} }] = useLazyGetUserQuery();
    const [getUserPolicy, { data: { policy = {} as Policy } = {} }] = useLazyGetUserPolicyQuery();
    useEffect(() => {
        if (userId) {
            getUser({ id: userId });
            getUserPolicy({ userId });
        }
    }, [userId]);
    return (
        <Drawer
            onClose={onClose}
            {...rest}
            shadow="lg"
            title={
                <Text weight="bold">
                    {t('Set User Policy:')} {user?.name ?? t('Unknown')}
                </Text>
            }
        >
            <DrawerContext.Consumer>
                {({ onClose }) => <SetGroupPolicyForm userId={userId} userPolicy={policy} onClose={onClose} />}
            </DrawerContext.Consumer>
        </Drawer>
    );
};

export default SetUserPolicy;
