import { useTranslation } from 'react-i18next';
import { Continuation } from '@/models';
import { Anchor, Button, Card, Group, Text, TextInput, Title, ActionIcon, Tooltip } from '@mantine/core';
import { IconAddressBook, IconCirclePlus, IconSearch, IconSquareX } from '@tabler/icons';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import List from '@/common/components/List';
import Pagination from '@/common/components/Pagination';
import { useDebouncedValue } from '@mantine/hooks';
import { useAllowsFor, $P } from '@/identity';
import { Contact, GetContactsRequest } from 'src/crm/models';
import { useGetContactsQuery } from 'src/crm/api';

export interface ContactsProps {}

export const Contacts = (props: ContactsProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const allowsFor = useAllowsFor();
    const [req, setRequest] = useState<GetContactsRequest>({
        returnCount: true,
    });
    const [virtPageNumber, setVirtPageNumber] = useState(0);
    const {
        data: { contacts = [], continue: paging = { count: -1, limit: 0 } as Continuation } = {},
        isLoading,
        isError,
    } = useGetContactsQuery(req);
    const columnHelper = createColumnHelper<Contact>();
    const columns = useMemo(
        () => [
            columnHelper.accessor('name', {
                header: () => <Text weight="bold">{t('Name')}</Text>,
                cell: (info) => (
                    <Anchor component={Link} to={`${info.row.original.id}`}>
                        {info.getValue()}
                    </Anchor>
                ),
            }),
        ],
        [columnHelper],
    );
    const gridTemplateColumns = '1fr';
    const table = useReactTable({
        data: contacts,
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true,
    });

    const goToNextPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber + 1);
        setRequest((req) => ({
            ...req,
            continue: paging.next,
        }));
    };

    const goToPrevPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber - 1);
        setRequest((req) => ({
            ...req,
            continue: paging.prev,
        }));
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState(searchParams.get('search') ?? '');
    const [searchQuery] = useDebouncedValue(search, 200);
    if (searchQuery != req.search) {
        setRequest((req) => ({
            ...req,
            continue: '',
            search: searchQuery,
        }));
    }

    return (
        <List
            table={table}
            gridTemplateColumns={gridTemplateColumns}
            header={
                <Group position="apart" sx={{ minHeight: 36 }}>
                    <Title order={3}>{t('All Contacts')}</Title>
                    <Group position="right">
                        {allowsFor([$P.CanManageContact, $P.ContactsResource]) ? (
                            <Tooltip label={t('Add Contact')}>
                                <Button
                                    component={Link}
                                    to={'new'}
                                    state={{ backgroundLocation: location }}
                                    variant="light"
                                    color="indigo"
                                    px="xs"
                                >
                                    <IconAddressBook />
                                    <IconCirclePlus />
                                </Button>
                            </Tooltip>
                        ) : null}
                        <TextInput
                            placeholder={t('Search')}
                            value={search}
                            icon={<IconSearch size={16} stroke={1.5} />}
                            onChange={(evt) => setSearch(evt.currentTarget.value)}
                            rightSection={
                                search ? (
                                    <ActionIcon onClick={() => setSearch('')}>
                                        <IconSquareX color="red" size={16} stroke={1.5} />
                                    </ActionIcon>
                                ) : (
                                    ''
                                )
                            }
                        />
                    </Group>
                </Group>
            }
            footer={
                <Card.Section withBorder>
                    <Group position="apart" p="sm" sx={{ minHeight: 60 }}>
                        <Pagination
                            goToNextPage={goToNextPage}
                            goToPrevPage={goToPrevPage}
                            virtPageNumber={virtPageNumber}
                            limit={paging.limit}
                            count={paging.count}
                        />
                    </Group>
                </Card.Section>
            }
        />
    );
};

export default Contacts;
