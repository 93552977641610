import { Box, ScrollArea, Space, Stack, useComponentDefaultProps, useMantineTheme } from '@mantine/core';
import { flexRender, Table } from '@tanstack/react-table';
import { ReactNode } from 'react';

export interface ListProps<T> {
    table: Table<T>;
    gridTemplateColumns: string;
    header?: ReactNode;
    footer?: ReactNode;
    headerActions?: React.ReactNode;
    footerPagination?: React.ReactNode;
    footerActions?: React.ReactNode;
}

const componentName = 'CLUIRelationList';
const defaultProps: Partial<Pick<ListProps<unknown>, 'gridTemplateColumns'>> = {
    gridTemplateColumns: '1fr',
};

export const RelationList = <T,>(props: ListProps<T>) => {
    const { table, gridTemplateColumns, header, footer, headerActions, footerPagination, footerActions, ...rest } =
        useComponentDefaultProps<ListProps<T>>(componentName, defaultProps, props);
    const theme = useMantineTheme();
    return (
        <Stack spacing={0} align="stretch" justify="flex-start" sx={{ height: '100%' }} {...rest}>
            {header}
            <Box
                px="md"
                sx={{
                    display: 'grid',
                    gridTemplateColumns,
                    borderBottom: `1px solid ${
                        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
                    }`,
                }}
            >
                {table.getHeaderGroups().map((headerGroup) =>
                    headerGroup.headers.map((header) => (
                        <Box key={header.id} p="xs">
                            {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())}
                        </Box>
                    )),
                )}
            </Box>
            <Box px="md" sx={{ flexGrow: 1, position: 'relative' }}>
                <ScrollArea
                    type="hover"
                    sx={{
                        position: 'absolute !important' as 'absolute', // TODO: total hack for !important?
                        top: 0,
                        bottom: 0,
                        width: `calc(100% - ${theme.spacing.md * 2}px)`,
                    }}
                >
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns,
                        }}
                    >
                        {table.getRowModel().rows.map((row) =>
                            row.getVisibleCells().map((cell) => (
                                <Box
                                    key={cell.id}
                                    p="xs"
                                    sx={{
                                        borderBottom: `1px solid ${
                                            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
                                        }`,
                                    }}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </Box>
                            )),
                        )}
                    </Box>
                    <Space h="lg" />
                </ScrollArea>
            </Box>
            {footer}
        </Stack>
    );
};

export default RelationList;
