import { Box, createStyles, DefaultProps, Selectors, useComponentDefaultProps } from '@mantine/core';
// import the core library.
import ReactEChartsCore from 'echarts-for-react/lib/core';
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import {
    // LineChart,
    BarChart,
    // PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    // TreemapChart,
    // GraphChart,
    // GaugeChart,
    // FunnelChart,
    // ParallelChart,
    // SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    LinesChart,
    LineChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    // ToolboxComponent,
    TooltipComponent,
    // AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    LegendComponent,
    // LegendScrollComponent,
    // LegendPlainComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    // VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    // TransformComponent,
    DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';
import { useCallback, useMemo, useRef } from 'react';
import { useElementSize } from '@mantine/hooks';
import { EChartsOption as _EChartsOption } from 'echarts';
export type EChartsOption = _EChartsOption;

// Register the required components
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    BarChart,
    LinesChart,
    LineChart,
    CanvasRenderer,
    DatasetComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
]);

export interface ChartStyleParams {}

const useStyles = createStyles((theme, params: ChartStyleParams, getRef) => {
    return {
        root: {
            display: 'flex',
            alignContent: 'stretch',
            justifyContent: 'stretch',
            overflow: 'hidden',
        },
        wrapper: {
            position: 'relative',
            flexGrow: 1,
            flexShrink: 1,
        },
        chart: {
            position: 'absolute',
            height: '100% !important',
            width: '100% !important',
        },
    };
});

type ChartStyleNames = Selectors<typeof useStyles>;

export interface ChartProps extends DefaultProps<ChartStyleNames, ChartStyleParams> {
    // TODO: switch to using this
    option?: EChartsOption;
    locale?: string; // TODO: this should include `| LocaleOption` but the react shim does not support it;
    renderer?: RendererType;
    devicePixelRatio?: number;
    useDirtyRect?: boolean;
    useCoarsePointer?: boolean;
    pointerSize?: number;
    ssr?: boolean;
    notMerge?: boolean;
    lazyUpdate?: boolean;
    // NOTE: automatically sizing to Box size, so no need to specify width/height in ECharts terms.
    // width?: number;
    // height?: number;

    // animation?: boolean;
    // dataset?: DataSet;
    // grid?: Grid;
    // series?: Series[];
    // xAxis?: XAxis | XAxis[];
    // yAxis?: YAxis | YAxis[];
    // tooltip?: Tooltip;
    // legend?: Legend;
    // notMerge?: boolean;
    // lazyUpdate?: boolean;
}

const componentName = 'CLUIChart';
const defaultProps: Partial<ChartProps> = {
    renderer: 'canvas' as const,
    notMerge: true,
    lazyUpdate: true,
};

export const Chart = (props: ChartProps) => {
    const {
        className,
        classNames,
        styles,
        unstyled,
        option,
        locale,
        renderer,
        devicePixelRatio,
        useCoarsePointer,
        useDirtyRect,
        pointerSize,
        ssr,
        notMerge,
        lazyUpdate,
        ...rest
    } = useComponentDefaultProps(componentName, defaultProps, props);
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { ref: boxRef, width = 'auto' as const, height = 'auto' as const } = useElementSize();
    const opts = useMemo(() => {
        return {
            renderer,
            height,
            width,
            locale,
        };
    }, [height, width, renderer, locale]);
    const echartsRef = useRef<any>(null);
    const echartsRefCap = useCallback((ref: any) => {
        if (ref) {
            echartsRef.current = ref.getEchartsInstance();
        } else {
            echartsRef.current = null;
        }
    }, []);
    return (
        <Box className={cx(classes.root, className)} {...rest}>
            <div ref={boxRef} className={classes.wrapper}>
                <ReactEChartsCore
                    ref={echartsRefCap}
                    className={cx(classes.chart)}
                    echarts={echarts}
                    option={option}
                    opts={opts}
                    notMerge={notMerge}
                    lazyUpdate={lazyUpdate}
                    theme={theme.colorScheme === 'dark' ? 'dark' : 'light'}
                />
            </div>
        </Box>
    );
};

export default Chart;

// TODO: why aren't these exported?
export type RendererType = 'canvas' | 'svg';
export type LocaleOption = {
    time: {
        month: string[];
        monthAbbr: string[];
        dayOfWeek: string[];
        dayOfWeekAbbr: string[];
    };
    legend: {
        selector: {
            all: string;
            inverse: string;
        };
    };
    toolbox: {
        brush: {
            title: {
                rect: string;
                polygon: string;
                lineX: string;
                lineY: string;
                keep: string;
                clear: string;
            };
        };
        dataView: {
            title: string;
            lang: string[];
        };
        dataZoom: {
            title: {
                zoom: string;
                back: string;
            };
        };
        magicType: {
            title: {
                line: string;
                bar: string;
                stack: string;
                tiled: string;
            };
        };
        restore: {
            title: string;
        };
        saveAsImage: {
            title: string;
            lang: string[];
        };
    };
    series: {
        typeNames: {
            pie: string;
            bar: string;
            line: string;
            scatter: string;
            effectScatter: string;
            radar: string;
            tree: string;
            treemap: string;
            boxplot: string;
            candlestick: string;
            k: string;
            heatmap: string;
            map: string;
            parallel: string;
            lines: string;
            graph: string;
            sankey: string;
            funnel: string;
            gauge: string;
            pictorialBar: string;
            themeRiver: string;
            sunburst: string;
        };
    };
    aria: {
        general: {
            withTitle: string;
            withoutTitle: string;
        };
        series: {
            single: {
                prefix: string;
                withName: string;
                withoutName: string;
            };
            multiple: {
                prefix: string;
                withName: string;
                withoutName: string;
                separator: {
                    middle: string;
                    end: string;
                };
            };
        };
        data: {
            allData: string;
            partialData: string;
            withName: string;
            withoutName: string;
            separator: {
                middle: string;
                end: string;
            };
        };
    };
};

// export type EChartsInitOpts = {
//     locale?: string | LocaleOption;
//     renderer?: RendererType;
//     devicePixelRatio?: number;
//     useDirtyRect?: boolean;
//     useCoarsePointer?: boolean;
//     pointerSize?: number;
//     ssr?: boolean;
//     width?: number;
//     height?: number;
// };

// // TODO: just use this
// let a: EChartsOption = {};

// export default Chart;

// export type Grid = {
//     containLabel?: boolean;
//     left?: number | string;
//     right?: number | string;
//     top?: number | string;
//     bottom?: number | string;
// };

// export type DataSet = {
//     source: any[] | Float64Array;
//     dimensions: string[];
// };

// export type AxisLabel = {
//     formatter?: string;
// };

// export type XAxis = {
//     type: string;
//     data?: any[];
//     axisLabel?: AxisLabel;
// };

// export type Legend = {
//     data?: string[];
//     left?: number | string;
//     right?: number | string;
//     top?: number | string;
//     bottom?: number | string;
// };

// export type YAxis = {
//     type: string;
//     axisLabel?: AxisLabel;
// };

// export type LineStyle = {
//     width: number;
// };

// export type Emphasis = {
//     lineStyle: LineStyle;
// };

// export type Encode = {
//     x: string;
//     y: string;
// };

// export type Series = {
//     type: string;
//     name?: string;
//     data?: any[];
//     z?: number;
//     showSymbol?: boolean;
//     sampling?: string;
//     lineStyle?: LineStyle;
//     emphasis?: Emphasis;
//     encode?: Encode;
// };

// export type Tooltip = {
//     trigger: "axis" | string;
// };
