import {
    Anchor,
    Box,
    Center,
    createStyles,
    DefaultProps,
    Group,
    HoverCard,
    Selectors,
    useComponentDefaultProps,
    Text,
} from '@mantine/core';
import { IconAt } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import LabeledText from '@/common/components/LabeledText';
import { ContactAssociation } from '@/crm/models';
import { Link } from 'react-router-dom';

export interface AssociatedContactStyleParams {}

const useStyles = createStyles((theme, params: AssociatedContactStyleParams, getRef) => {
    return {
        root: {},
    };
});

type AssociatedContactStyleNames = Selectors<typeof useStyles>;

export interface AssociatedContactProps
    extends DefaultProps<AssociatedContactStyleNames, AssociatedContactStyleParams> {
    label?: string;
    contact?: ContactAssociation;
}

const componentName = 'CLUIAssociatedContact';
const defaultProps: Partial<AssociatedContactProps> = {};

// TODO: move to common?
function getRemoteId(crn: string): string {
    const m = crn?.match(/crn:cloe:contact:(\d+)/i);
    return m?.[1] ?? '-1';
}

export const AssociatedContact = (props: AssociatedContactProps) => {
    const { className, classNames, styles, unstyled, label, contact, ...rest } = useComponentDefaultProps(
        componentName,
        defaultProps,
        props,
    );
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    return (
        <Group className={cx(classes.root, className)} {...rest} position="left">
            <Center>
                <IconAt />
            </Center>
            <HoverCard position="bottom" width={320} shadow="lg" offset={0} withinPortal withArrow>
                <HoverCard.Target>
                    <Box sx={{ cursor: 'pointer' }}>
                        <LabeledText label={label ?? t('Contact')}>
                            {contact?.name ?? <Text color="dimmed">-</Text>}
                        </LabeledText>
                    </Box>
                </HoverCard.Target>
                <HoverCard.Dropdown sx={{}}>
                    <LabeledText label={t('Name')} raw={!!contact?.crn}>
                        {contact?.crn ? (
                            <Anchor component={Link} to={`/contacts/${getRemoteId(contact.crn)}`}>
                                {contact?.name ?? <Text color="dimmed">-</Text>}
                            </Anchor>
                        ) : (
                            <Text color="dimmed">-</Text>
                        )}
                    </LabeledText>
                    <LabeledText label={t('Email')} raw>
                        {contact?.emailAddress ? (
                            <Anchor href={`mailto:${contact.emailAddress}`}>{contact.emailAddress}</Anchor>
                        ) : (
                            <Text color="dimmed">-</Text>
                        )}
                    </LabeledText>
                    <LabeledText label={t('Main #')} raw>
                        <div>{contact?.mainPhone || <Text color="dimmed">-</Text>}</div>
                    </LabeledText>
                    <LabeledText label={t('Mobile #')} raw>
                        <div>{contact?.mobilePhone || <Text color="dimmed">-</Text>}</div>
                    </LabeledText>
                </HoverCard.Dropdown>
            </HoverCard>
        </Group>
    );
};

export default AssociatedContact;
