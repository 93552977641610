import Drawer, { DrawerContext, DrawerProps } from '@/common/components/Drawer';
import { Text, useComponentDefaultProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import UpdateLocationForm from './UpdateLocationForm';
import { Location } from '@/management/models';
import { useLazyGetLocationQuery } from 'src/management/api';
import { useEffect } from 'react';

export interface UpdateLocationProps extends DrawerProps {}

const componentName = 'CLUIUpdateLocation';
const defaultProps: Partial<UpdateLocationProps> = {
    size: '50vw',
    padding: 'md',
    position: 'right',
    closeOnClickOutside: false,
    overlayOpacity: 0.0,
    shadow: 'lg',
};

export const UpdateLocation = (props: UpdateLocationProps) => {
    const { onClose, ...rest } = useComponentDefaultProps(componentName, defaultProps, props);
    const { locationId } = useParams();
    const { t, i18n } = useTranslation();
    const [getLocation, { data: { location = {} as Location } = {} }] = useLazyGetLocationQuery();
    useEffect(() => {
        if (locationId) {
            getLocation({ id: locationId });
        }
    }, [locationId]);
    return (
        <Drawer
            onClose={onClose}
            {...rest}
            shadow="lg"
            title={
                <Text weight="bold">
                    {t('Update')}: {location?.name ?? t('Unknown')}
                </Text>
            }
        >
            <DrawerContext.Consumer>
                {({ onClose }) => <UpdateLocationForm location={location} locationId={locationId!} onClose={onClose} />}
            </DrawerContext.Consumer>
        </Drawer>
    );
};

export default UpdateLocation;
