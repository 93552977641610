import { Global, useMantineTheme } from '@mantine/core';

export function GlobalStyles() {
    const theme = useMantineTheme();
    return (
        <Global
            styles={{
                '*, *::before, *::after': {
                    boxSizing: 'border-box',
                },
                body: {
                    margin: 0,
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    position: 'absolute',
                    ...theme.fn.fontStyles(),
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                    lineHeight: theme.lineHeight,
                    fontSize: theme.fontSizes.md,
                } as any,
                '#root': {
                    margin: 0,
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                },
            }}
        />
    );
}
