import {
    Anchor,
    Avatar,
    Box,
    createStyles,
    DefaultProps,
    HoverCard,
    Selectors,
    useComponentDefaultProps,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useGetDashboardMapMarkersQuery } from 'src/management/api';
import Map, { Marker, ViewState } from '@/common/components/Map';
import {
    ConnectionStatus,
    ConnectionStatusDown,
    ConnectionStatusPaused,
    ConnectionStatusUnknown,
    ConnectionStatusUp,
    ConnectionStatusWarning,
} from '@/management/models';
import { IconMapPin } from '@tabler/icons';
import LabeledText from 'src/common/components/LabeledText';
import { Link } from 'react-router-dom';
import { $F, useFeatures } from 'src/identity';

export interface LocationsStatusMapStyleParams {}

const useStyles = createStyles((theme, params: LocationsStatusMapStyleParams, getRef) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});

type LocationsStatusMapStyleNames = Selectors<typeof useStyles>;

export interface LocationsStatusMapProps
    extends DefaultProps<LocationsStatusMapStyleNames, LocationsStatusMapStyleParams> {}

const componentName = 'CLUILocationsStatusMap';
const defaultProps: Partial<LocationsStatusMapProps> = {};

export const LocationsStatusMap = (props: LocationsStatusMapProps) => {
    const { className, classNames, styles, unstyled, ...rest } = useComponentDefaultProps(
        componentName,
        defaultProps,
        props,
    );
    const { hasSome } = useFeatures();
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    const initialViewState = useMemo<Partial<ViewState>>(
        () => ({
            longitude: -95.49634981835038,
            latitude: 38.15115629772592,
            zoom: 3.2,
        }),
        [],
    );
    const stat = useMemo<{ [key in ConnectionStatus]: [string, string, number] }>(
        () => ({
            [ConnectionStatusUnknown]: [t('Unknown'), 'gray', 0],
            [ConnectionStatusDown]: [t('Down'), 'red', 99],
            [ConnectionStatusWarning]: [t('Warning'), 'yellow', 50],
            [ConnectionStatusUp]: [t('Up'), 'teal', 25],
            [ConnectionStatusPaused]: [t('Paused'), 'blue', 5],
        }),
        [t],
    );
    const { data: { markers = [] } = {} } = useGetDashboardMapMarkersQuery(
        {},
        {
            pollingInterval: 60 * 1000,
        },
    );
    const pins = useMemo(
        () =>
            markers.map((marker) => (
                <Marker
                    key={marker.location.crn}
                    latitude={marker.location.location.lat ?? 0}
                    longitude={marker.location.location.lng ?? 0}
                    style={{ zIndex: stat[marker.status][2] }}
                >
                    <HoverCard position="top" width={200} shadow="lg" offset={0} withinPortal withArrow>
                        <HoverCard.Target>
                            <Box
                                sx={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: '50%',
                                    backgroundColor: theme.fn.themeColor(
                                        stat[marker.status][1],
                                        theme.colorScheme === 'dark' ? 8 : 6,
                                    ),
                                    boxShadow: '0px 0px 2px 2px rgb(0 0 0 / 20%)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Avatar
                                    size={18}
                                    radius="xl"
                                    src={
                                        marker.location && marker.location.iconUrl
                                            ? marker.location.iconUrl
                                            : marker.customer && marker.customer.iconUrl
                                              ? marker.customer.iconUrl
                                              : null
                                    }
                                    sx={{ backgroundColor: theme.white, transform: 'translate(0.5px, 0.5px)' }}
                                    variant="filled"
                                    color={'slate'}
                                >
                                    <IconMapPin size={15} />
                                </Avatar>
                            </Box>
                        </HoverCard.Target>
                        <HoverCard.Dropdown sx={{}}>
                            {marker.customer && hasSome($F.UxAdmin, $F.UxStaff) ? (
                                <LabeledText label={t('Customer')} raw>
                                    <Anchor component={Link} to={`/customers/${marker.customer.id}`}>
                                        {marker.customer.name ?? t('Unknown')}
                                    </Anchor>
                                </LabeledText>
                            ) : null}
                            <LabeledText label={t('Location')} raw>
                                <Anchor component={Link} to={`/locations/${marker.location.id}`}>
                                    {marker.location.name ?? t('Unknown')}
                                </Anchor>
                            </LabeledText>
                        </HoverCard.Dropdown>
                    </HoverCard>
                </Marker>
            )),
        [markers],
    );
    return (
        <Box className={cx(classes.root, className)} {...rest}>
            <Map sx={{ height: '100%', width: '100%' }} initialViewState={initialViewState}>
                {pins}
            </Map>
        </Box>
    );
};

export default LocationsStatusMap;
