import { Button, Group, TextInput, PasswordInput, Checkbox, TransferList, Alert } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import { getErrorMessage } from '@/errors';
import { useTranslation } from 'react-i18next';
import { IconAlertCircle, IconDeviceFloppy } from '@tabler/icons';
import { useUserGroupsTransferListData } from '@/identity/hooks';
import { useUpdateUserMutation } from '@/identity/api';
import { User } from '@/identity/models';

export interface UpdateUserFormProps {
    user: User;
    userId: string;
    onClose: () => void;
}

type UpdateUserValues = {
    emailAddress: string;
    password: string;
    confirmPassword: string;
    setPassword: boolean;
    name: string;
};

export const UpdateUserForm = ({ user, userId, onClose }: UpdateUserFormProps) => {
    const { t, i18n } = useTranslation();
    const form = useForm<UpdateUserValues>({
        initialValues: {
            emailAddress: '',
            password: '',
            confirmPassword: '',
            setPassword: false,
            name: '',
        },
        validate: {
            confirmPassword: (value, values) => (value !== values.password ? t('Passwords did not match') : null),
            name: (value) => (value.length < 1 ? t('Name is required') : null),
            emailAddress: (value) => (value.length < 1 ? t('Email address is required') : null),
            password: (value, values) => (values.setPassword && value.length < 1 ? t('Password is required') : null),
        },
    });
    const [groupData, setGroupData] = useUserGroupsTransferListData(userId!);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [updateUser, { isLoading, isSuccess, isError }] = useUpdateUserMutation();

    const onSubmit = useCallback(
        async ({ emailAddress, password, name }: UpdateUserValues) => {
            try {
                const res = await updateUser({
                    user: {
                        id: userId!,
                        emailAddress,
                        password,
                        name,
                    },
                    groupIds: { values: groupData[1].map((group) => group.value) },
                }).unwrap();
                onClose();
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
            }
        },
        [updateUser, groupData, onClose],
    );

    useEffect(() => {
        if (user) {
            form.setValues({
                emailAddress: user.emailAddress,
                name: user.name,
                password: '',
                confirmPassword: '',
                setPassword: false,
            });
        }
    }, [user]);

    return (
        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
            <TextInput
                data-autofocus
                required
                label={t('Name')}
                placeholder={t('Name')}
                {...form.getInputProps('name')}
            />
            <TextInput
                mt="md"
                required
                label={t('Email Address')}
                placeholder={t('user@email.com')}
                {...form.getInputProps('emailAddress')}
            />
            <Checkbox
                mt="md"
                label={t('Set Password')}
                {...form.getInputProps('setPassword', {
                    type: 'checkbox',
                })}
            />
            {form.values.setPassword ? (
                <PasswordInput
                    mt="md"
                    disabled={!form.values.setPassword}
                    required={form.values.setPassword}
                    label={t('Password')}
                    placeholder=""
                    {...form.getInputProps('password')}
                />
            ) : null}
            {form.values.setPassword ? (
                <PasswordInput
                    mt="md"
                    disabled={!form.values.setPassword}
                    required={form.values.setPassword}
                    label={t('Confirm Password')}
                    placeholder=""
                    {...form.getInputProps('confirmPassword')}
                />
            ) : null}
            <TransferList
                listHeight={360}
                titles={[t('Groups'), t('Assigned')]}
                value={groupData}
                mt="md"
                onChange={setGroupData}
            />
            {errorMessage ? (
                <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                    {errorMessage}
                </Alert>
            ) : null}
            <Group position="right" mt={'md'}>
                <Button color={'gray'} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button type="submit" color={'primary'} loading={isLoading} leftIcon={<IconDeviceFloppy />}>
                    {t('Save')}
                </Button>
            </Group>
        </form>
    );
};

export default UpdateUserForm;
