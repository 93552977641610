import { useState, useEffect } from 'react';
import { useAppDispatch } from '@/app/store';
import {
    selectHasPreviousSession,
    selectNonInteractiveLoginAttempted,
    setNonInteractiveLoginAttempted,
    useLoginMutation,
} from '@/identity';

import { useNewPasswordMutation } from '@/identity/api';

import { forDesktopUp } from '@/styles/mixins';
import {
    Anchor,
    Box,
    Button,
    Center,
    createStyles,
    Group,
    Paper,
    Stack,
    TextInput,
    Title,
    useMantineTheme,
    Loader,
    PasswordInput,
    Progress,
    Popover,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from 'src/common/components/Logo';
import { PasswordRequirement, getStrength, requirements } from './PassRequirements';
import CLOEClover from '../../../../public/icon.png';
import '../Login/Login.css';

const useStyles = createStyles((theme, params, getRef) => {
    return {};
});

type NewPassword = {
    newPassword: string;
    newPasswordAgain: string;
    newPasswordResult: {
        result: string;
        message: string;
    };
    sessionId: string;
};

export const NewPassword = () => {
    const { classes, cx } = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const [changeResult, setChangeResult] = useState('');

    const [newPassword, setNewPassword] = useState<string>('');
    const [newPasswordAgain, setNewPasswordAgain] = useState<string>('');
    const [btnClicked, setBtnClicked] = useState(false);

    const location = useLocation();
    let email = new URLSearchParams(location.search).get('email') as string;
    const token = new URLSearchParams(location.search).get('token') as string;

    const [login, { isLoading, isSuccess, isError, isUninitialized }] = useLoginMutation();
    const dispatch = useAppDispatch();
    const hasPreviousSession = useSelector(selectHasPreviousSession);
    const nonInteractiveLoginAttempted = useSelector(selectNonInteractiveLoginAttempted);
    const [resetWithNewPassword] = useNewPasswordMutation();

    const [popoverOpened, setPopoverOpened] = useState(false);

    const form = useForm<any>({
        initialValues: {
            newPassword: '',
            newPasswordAgain: '',
            resetPasswordResult: {
                result: '',
                message: '',
            },
            complete: false,
        },
        validate: (values: any) => {
            const errors: Record<string, string> = {};

            if (!values.complete) {
                errors.newPassword = t('Password does not meet requirements');
                return errors;
            }
            if (values.newPassword !== values.newPasswordAgain) {
                errors.newPasswordAgain = t('Passwords do not match');
            }
            return errors;
        },
    });

    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement
            key={index}
            label={requirement.label}
            meets={requirement.re.test(form.values.newPassword)}
        />
    ));
    const strength = getStrength(form.values.newPassword);
    const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

    useEffect(() => {
        if (strength === 100) {
            form.setFieldValue('complete', true);
        } else {
            form.setFieldValue('complete', false);
        }
    }, [form.values.newPassword]);

    const onSubmit = async ({ newPassword, newPasswordAgain, newPasswordResult }: NewPassword) => {
        if (!btnClicked) {
            setBtnClicked(true);

            // to handle emails that use +
            if (email !== null) {
                email = email.replace(/ /g, '+');
            }

            try {
                let resp = await resetWithNewPassword({
                    password: newPassword,
                    passwordVerify: newPasswordAgain,
                    email,
                    token,
                }).unwrap();
                setNewPassword(newPassword);
                setNewPasswordAgain(newPasswordAgain);
                setChangeResult('SUCCESS');
            } catch {
                setChangeResult('ERROR');
            }
            setBtnClicked(false);
        }
    };

    if (hasPreviousSession) {
        if (isUninitialized) {
            dispatch(setNonInteractiveLoginAttempted(true));
            login({});
        }
        if (isUninitialized || (nonInteractiveLoginAttempted && isLoading)) {
            return (
                <Center style={{ minHeight: '100vh', minWidth: '100vw' }}>
                    <Box>
                        <img
                            src={CLOEClover}
                            alt="loading symbol"
                            className="my-img"
                            style={{ width: '150px', height: 'auto' }}
                        />
                    </Box>
                </Center>
            );
        }
    }

    if (changeResult === 'SUCCESS') {
        return (
            <Center sx={{ width: '100%' }}>
                <Stack align="center" justify="center">
                    <Logo mb={theme.spacing.lg * 2} />
                    <Paper
                        withBorder
                        shadow={'md'}
                        p={'md'}
                        sx={{
                            width: '400px',
                            ...forDesktopUp(theme, {
                                width: '400px',
                            }),
                        }}
                    >
                        <Title order={3}>{t(`Password was successfully changed`)}</Title>
                        <Anchor component={Link} to={`/login`}>
                            {t('Back to Login')}
                        </Anchor>
                    </Paper>
                </Stack>
            </Center>
        );
    } else if (changeResult === 'ERROR') {
        return (
            <Center sx={{ width: '100%' }}>
                <Stack align="center" justify="center">
                    <Logo mb={theme.spacing.lg * 2} />
                    <Paper
                        withBorder
                        shadow={'md'}
                        p={'md'}
                        sx={{
                            width: '400px',
                            ...forDesktopUp(theme, {
                                width: '400px',
                            }),
                        }}
                    >
                        <Title order={3}>
                            {t(`Password reset failed - please try again or contact Cloverleaf Support`)}
                        </Title>
                        <Anchor component={Link} to={`/login`}>
                            {t('Back to Login')}
                        </Anchor>
                    </Paper>
                </Stack>
            </Center>
        );
    } else {
        return (
            <Center sx={{ width: '100%' }}>
                <Stack align="center" justify="center">
                    <Logo mb={theme.spacing.lg * 2} />
                    <Paper
                        withBorder
                        shadow={'md'}
                        p={'md'}
                        sx={{
                            width: '400px',
                            ...forDesktopUp(theme, {
                                width: '400px',
                            }),
                        }}
                    >
                        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
                            <Title order={3}>
                                {t(`Reset Password`)} {`for CLOE`}
                            </Title>

                            <Box mx="auto">
                                <Popover opened={popoverOpened} position="bottom" width="target">
                                    <Popover.Target>
                                        <div
                                            onFocusCapture={() => setPopoverOpened(true)}
                                            onBlurCapture={() => setPopoverOpened(false)}
                                        >
                                            <PasswordInput
                                                mt="md"
                                                label={t(`New Password`)}
                                                placeholder={t(`New Password`)}
                                                required
                                                withAsterisk
                                                {...form.getInputProps('newPassword')}
                                                toggleTabIndex={0}
                                                aria-label="Enter New Password"
                                                name="unique-password-field-1"
                                                autoComplete="new-password"
                                            />
                                        </div>
                                    </Popover.Target>
                                    <Popover.Dropdown>
                                        <Progress color={color} value={strength} size={5} mb="xs" />
                                        {checks}
                                    </Popover.Dropdown>
                                </Popover>
                            </Box>

                            <TextInput
                                mt="md"
                                label={t(`Password Confirmation`)}
                                placeholder={t(`Password Confirmation`)}
                                required
                                withAsterisk
                                {...form.getInputProps('newPasswordAgain')}
                                aria-label="Confirm New Password"
                                type="password"
                                name="unique-password-field-2"
                                autoComplete="new-password"
                            />

                            <Group mt="md" position="right">
                                <Button type="submit" color={'primary'} loading={isLoading}>
                                    {t('Send Reset Request')}
                                </Button>
                            </Group>
                        </form>
                    </Paper>
                    {btnClicked && <Loader color="green" size="xl"></Loader>}
                </Stack>
            </Center>
        );
    }
};

export default NewPassword;
