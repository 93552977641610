import {
    useMantineTheme,
    Title,
    Center,
    Group as GroupEl,
    Menu,
    Button,
    Card,
    Stack,
    Tabs,
    Modal,
    Alert,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import View from '@/common/components/View';
import { Group as GroupType } from '../../models';
import { useAllowsActionOnResource } from 'src/identity/hooks';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { useGetGroupQuery, useDeleteGroupMutation } from 'src/identity/api';
import { IconAt, IconDots, IconInfoCircle, IconShieldLock, IconAlertCircle, IconSquareX } from '@tabler/icons';
import { $P } from 'src/identity';
import { Link } from 'react-router-dom';
import LabeledText from 'src/common/components/LabeledText';
import GroupPolicy from '../GroupPolicy';
import GroupUsers from '../GroupUsers';
import { useDisclosure } from '@mantine/hooks';
import { getErrorMessage } from '@/errors';
import { useState } from 'react';

export interface GroupProps {}

export const Group = (props: GroupProps) => {
    const theme = useMantineTheme();
    const allowsActionOnResource = useAllowsActionOnResource();
    const { groupId, tab } = useParams();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);
    const [deleteGroup, { isSuccess }] = useDeleteGroupMutation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const handleDelete = async () => {
        setErrorMessage(null);
        try {
            await deleteGroup({ id: group.id }).unwrap();
            navigate(-1);
        } catch (err) {
            setErrorMessage(getErrorMessage(err));
        }
    };

    const { data: { group = {} as GroupType } = {}, isLoading, isError } = useGetGroupQuery({ id: groupId! });

    function onTabChange(value: string) {
        navigate(`./../${value}`, { replace: true });
    }

    // goto default tab
    if (!tab) {
        return <Navigate to={'users'} replace />;
    }

    return (
        <View
            header={
                <GroupEl position="apart" sx={{ minHeight: 36 }}>
                    <Title order={3}>{group.name ?? t('Unknown')}</Title>
                    <GroupEl position="right">
                        <Menu position="left-start" shadow="md" width={200}>
                            <Menu.Target>
                                <Button variant="light" color="orange" px="xs">
                                    <IconDots />
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>{t('Actions')}</Menu.Label>
                                {allowsActionOnResource($P.CanManageGroup, $P.GroupResourceFmt(groupId!)) ? (
                                    <>
                                        {/* disabled edit for now
                                        <Menu.Item
                                            component={Link}
                                            icon={<IconPencil size={14} />}
                                            state={{ backgroundLocation: location }}
                                            to="./../edit"
                                        >
                                            {t('Edit')}
                                        </Menu.Item>
                                        */}
                                        {/* disabled the delete for now
                                        <Menu.Item component="button" icon={<IconSquareX size={14} />} onClick={open}>
                                            {t('Delete')}
                                        </Menu.Item>
                                        */}
                                    </>
                                ) : null}
                                {allowsActionOnResource($P.CanAdministerGroup, $P.GroupResourceFmt(groupId!)) ? (
                                    <Menu.Item
                                        component={Link}
                                        icon={<IconShieldLock size={14} />}
                                        state={{ backgroundLocation: location }}
                                        to="./../policy/set"
                                    >
                                        {t('Set Policy')}
                                    </Menu.Item>
                                ) : null}
                            </Menu.Dropdown>
                        </Menu>
                        {allowsActionOnResource($P.CanManageGroup, $P.GroupResourceFmt(groupId!)) ? (
                            <Modal
                                opened={opened}
                                onClose={close}
                                title={
                                    <>
                                        Are you sure?
                                        <br />
                                        Group {group.name} will be permanently deleted.
                                    </>
                                }
                                centered
                                withCloseButton={false}
                            >
                                {errorMessage ? (
                                    <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                                        {errorMessage}
                                    </Alert>
                                ) : null}
                                <GroupEl position="right" mt={'md'}>
                                    <Button
                                        color={'gray'}
                                        onClick={() => {
                                            setErrorMessage(null);
                                            close();
                                        }}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color={'red'}
                                        loading={isLoading}
                                        leftIcon={<IconSquareX />}
                                        onClick={handleDelete}
                                    >
                                        {t('Delete')}
                                    </Button>
                                </GroupEl>
                            </Modal>
                        ) : null}
                    </GroupEl>
                </GroupEl>
            }
        >
            {/* unused header showing the currently unused email field
            <Card.Section sx={{ minHeight: 200 }}>
                <GroupEl position="left" m="md" align="flex-start">
                    <Stack>
                        <GroupEl position="left">
                            <Center>
                                <IconAt />
                            </Center>
                            <LabeledText label={t('Email')}>{t('unknown@unknown')}</LabeledText>
                        </GroupEl>
                    </Stack>
                </GroupEl>
            </Card.Section>
            */}
            <Card.Section
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                <Tabs
                    defaultValue="connections"
                    value={tab}
                    onTabChange={onTabChange}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}
                >
                    <Tabs.List>
                        <Tabs.Tab value="users" icon={<IconInfoCircle size={16} />}>
                            {t('Users')}
                        </Tabs.Tab>
                        <Tabs.Tab value="policy" icon={<IconShieldLock size={16} />}>
                            {t('Policy')}
                        </Tabs.Tab>
                        <GroupEl position="right" sx={{ flexGrow: 1 }}></GroupEl>
                    </Tabs.List>
                    <Tabs.Panel value="users" sx={{ height: '100%' }}>
                        <GroupUsers group={group} groupId={groupId!} />
                    </Tabs.Panel>
                    <Tabs.Panel value="policy" sx={{ height: '100%' }}>
                        <GroupPolicy group={group} groupId={groupId!} />
                    </Tabs.Panel>
                </Tabs>
            </Card.Section>
        </View>
    );
};

export default Group;
