import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_NAME, IdentityState } from './models';
import { makeSessionAuthorization } from '../api';
import { identityApi } from './api';

// TODO: do this in a more appropriate manner
const loadDeveloperFeatures = (): string[] => {
    const features = localStorage.getItem('developerFeatures');
    if (features) {
        return features.split(',').map((f) => f.trim());
    }
    return [];
};

const initialState: IdentityState = {
    authorization: '',
    whoAmI: null,
    hasPreviousSession: localStorage.getItem('hasPreviousSession') === '1',
    nonInteractiveLoginAttempted: false,
    developerFeatures: loadDeveloperFeatures(),
};

export const identitySlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setAuthorization: (state, action: PayloadAction<string>) => {
            state.authorization = action.payload;
        },
        setNonInteractiveLoginAttempted: (state, action: PayloadAction<boolean>) => {
            state.nonInteractiveLoginAttempted = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(identityApi.endpoints.login.matchFulfilled, (state, { payload }) => {
            localStorage.setItem('hasPreviousSession', '1');
            state.authorization = makeSessionAuthorization(payload.token);
            state.whoAmI = payload;
            state.hasPreviousSession = true;
        });
    },
});

export const { setAuthorization, setNonInteractiveLoginAttempted } = identitySlice.actions;

export default identitySlice.reducer;
