import { SelectItem } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useMemo, useState } from 'react';
import { useLookupContactsQuery } from './api';
import { LookupContactsRequest } from './models';

export const useContactSelectData = (defaultValue?: SelectItem | null) => {
    const [req, setRequest] = useState<LookupContactsRequest>({
        search: '',
    });
    const { data: { contacts = [] } = {} } = useLookupContactsQuery(req);
    const [search, setSearch] = useState('');
    const [searchQuery] = useDebouncedValue(search, 200);
    if (searchQuery != req.search) {
        setRequest((req) => ({
            ...req,
            search: searchQuery,
        }));
    }
    const contactSelectData = useMemo(() => {
        if (req.search == '') {
            return defaultValue ? [defaultValue] : [];
        }
        return contacts.map((c) => ({
            label: c.name,
            value: c.id,
        }));
    }, [contacts, req]);
    return [contactSelectData, setSearch, search] as const;
};
