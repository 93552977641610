import {
    ActionIcon,
    createStyles,
    DefaultProps,
    Group,
    Selectors,
    Text,
    useComponentDefaultProps,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconChevronRight, IconChevronLeft } from '@tabler/icons';

export interface PaginationStyleParams {}

const useStyles = createStyles((theme, params: PaginationStyleParams, getRef) => {
    return {
        root: {},
    };
});

type PaginationStyleNames = Selectors<typeof useStyles>;

export interface PaginationProps extends DefaultProps<PaginationStyleNames, PaginationStyleParams> {
    goToNextPage: () => void;
    goToPrevPage: () => void;
    virtPageNumber: number;
    count: number;
    limit: number;
}

const componentName = 'CLUIPagination';
const defaultProps: Partial<PaginationProps> = {};

export const Pagination = (props: PaginationProps) => {
    const {
        className,
        classNames,
        styles,
        unstyled,
        goToNextPage,
        goToPrevPage,
        virtPageNumber,
        count,
        limit,
        ...rest
    } = useComponentDefaultProps(componentName, defaultProps, props);
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    return (
        <Group className={cx(classes.root, className)} {...rest}>
            <ActionIcon variant="filled" onClick={goToPrevPage} disabled={virtPageNumber <= 0}>
                <IconChevronLeft />
            </ActionIcon>
            {limit > 0 ? (
                <Text sx={{ minWidth: 128, textAlign: 'center' }}>
                    {`${virtPageNumber * limit + 1}-${Math.min((virtPageNumber + 1) * limit, count)}`} {t('out of')}{' '}
                    {count}
                </Text>
            ) : null}
            <ActionIcon variant="filled" onClick={goToNextPage} disabled={(virtPageNumber + 1) * limit >= count}>
                <IconChevronRight />
            </ActionIcon>
        </Group>
    );
};

export default Pagination;
