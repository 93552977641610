import {
    Badge,
    BadgeProps,
    BadgeVariant,
    createStyles,
    DefaultProps,
    Selectors,
    useComponentDefaultProps,
} from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ProjectStatus,
    ProjectStatusNewInQueue,
    ProjectStatusNewOnHold,
    ProjectStatusNewPMApproved,
    ProjectStatusNewPMLongHold,
    ProjectStatusNewWelcome,
    ProjectStatusOnHold,
    ProjectStatusContactingCarrier,
    ProjectStatusCarrierOrdered,
    ProjectStatusInstallDateReceived,
    ProjectStatusProvisioningServices,
    ProjectStatusInstalled,
    ProjectStatusCancelled,
    ProjectStatusCompany,
} from 'src/support/models';

export interface ProjectStatusBadgeStyleParams {}

const useStyles = createStyles((theme, params: ProjectStatusBadgeStyleParams, getRef) => {
    return {
        root: {},
    };
});

type ProjectStatusBadgeStyleNames = Selectors<typeof useStyles>;

export interface ProjectStatusBadgeProps
    extends DefaultProps<ProjectStatusBadgeStyleNames, ProjectStatusBadgeStyleParams> {
    status?: ProjectStatus;
    variant?: BadgeProps['variant'];
}

const componentName = 'CLUIProjectStatusBadge';
const defaultProps: Partial<ProjectStatusBadgeProps> = {
    variant: 'filled',
};

export const ProjectStatusBadge = (props: ProjectStatusBadgeProps) => {
    let { className, classNames, styles, unstyled, variant, status, ...rest } = useComponentDefaultProps(
        componentName,
        defaultProps,
        props,
    );
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    const statuses = useMemo<{ [key in ProjectStatus]: [string, string, BadgeVariant] }>(
        () => ({
            [ProjectStatusCompany]: [t('COMPANY'), 'indigo', 'light'],
            [ProjectStatusNewInQueue]: [t('New-In Queue'), 'indigo', 'light'],
            [ProjectStatusNewOnHold]: [t('New-On Hold'), 'yellow', 'outline'],
            [ProjectStatusNewPMApproved]: [t('New-PM Approved'), 'cyan', 'light'],
            [ProjectStatusNewPMLongHold]: [t('New-Longhold'), 'orange', 'outline'],
            [ProjectStatusNewWelcome]: [t('New_Welcome'), 'blue', 'light'],
            [ProjectStatusOnHold]: [t('On Hold'), 'yellow', 'filled'],
            [ProjectStatusContactingCarrier]: [t('Contacting Carrier'), 'grape', 'filled'],
            [ProjectStatusCarrierOrdered]: [t('Ordered'), 'teal', 'filled'],
            [ProjectStatusInstallDateReceived]: [t('Install Date Received'), 'green', 'filled'],
            [ProjectStatusProvisioningServices]: [t('Provisioning Service'), 'pink', 'filled'],
            [ProjectStatusInstalled]: [t('Installed'), 'lime', 'filled'],
            [ProjectStatusCancelled]: [t('Cancelled'), 'red', 'filled'],
        }),
        [t],
    );
    let label = '';
    let color = '';
    [label, color, variant] = statuses[status!] ?? [t('Unknown'), 'gray', 'filled'];

    if (label == 'COMPANY') {
        return <></>;
    }
    return (
        <Badge variant={variant} className={cx(classes.root, className)} color={color} {...rest}>
            {label}
        </Badge>
    );
};

export default ProjectStatusBadge;
