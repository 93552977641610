import View from '@/common/components/View';
import { $P, useAllowsFor, useAllowsActionOnResource } from '@/identity';
import { Anchor, Button, Card, Center, Group, Menu, Stack, Title, Modal, Alert, Text } from '@mantine/core';
import { IconAt, IconDots, IconPencil, IconPhone, IconDeviceMobile, IconSquareX, IconAlertCircle } from '@tabler/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import LabeledText from 'src/common/components/LabeledText';
import { useGetContactQuery, useDeleteContactMutation } from '../../api';
import { useDisclosure } from '@mantine/hooks';
import { Contact as ContactType } from '../../models';
import { getErrorMessage } from '@/errors';

export interface ContactProps {}

export const Contact = (props: ContactProps) => {
    const location = useLocation();
    const allowsFor = useAllowsFor();
    const { contactId, tab } = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);
    const [deleteContact, { isSuccess }] = useDeleteContactMutation();
    const allowsActionOnResource = useAllowsActionOnResource();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const handleDelete = async () => {
        setErrorMessage(null);
        try {
            await deleteContact({ id: contact.id }).unwrap();
            navigate(-1);
        } catch (err) {
            setErrorMessage(getErrorMessage(err));
        }
    };
    const { data: { contact = {} as ContactType } = {}, isLoading, isError } = useGetContactQuery({ id: contactId! });
    const [search, setSearch] = useState('');

    function onTabChange(value: string) {
        navigate(`./../${value}`, { replace: true });
    }

    // goto default tab
    if (!tab) {
        return <Navigate to={'other'} replace />;
    }

    return (
        <View
            header={
                <Group position="apart" sx={{ minHeight: 36 }}>
                    <Title order={3}>{contact.name ?? t('Unknown')}</Title>
                    <Group position="right">
                        <Menu position="left-start" shadow="md" width={200}>
                            <Menu.Target>
                                <Button variant="light" color="indigo" px="xs">
                                    <IconDots />
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>{t('Actions')}</Menu.Label>
                                {allowsFor([$P.CanManageContact, $P.ContactResourceFmt(contactId!)]) ? (
                                    <>
                                        <Menu.Item
                                            component={Link}
                                            icon={<IconPencil size={14} />}
                                            state={{
                                                backgroundLocation: location,
                                            }}
                                            to="./../edit"
                                        >
                                            {t('Edit')}
                                        </Menu.Item>
                                        <Menu.Item component="button" icon={<IconSquareX size={14} />} onClick={open}>
                                            {t('Delete')}
                                        </Menu.Item>
                                    </>
                                ) : null}
                            </Menu.Dropdown>
                        </Menu>
                        {allowsActionOnResource($P.CanManageContact, $P.ContactResourceFmt(contactId!)) ? (
                            <Modal
                                opened={opened}
                                onClose={close}
                                title={
                                    <>
                                        Are you sure?
                                        <br />
                                        Contact {contact.name} will be permanently deleted.
                                    </>
                                }
                                centered
                                withCloseButton={false}
                            >
                                {errorMessage ? (
                                    <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                                        {errorMessage}
                                    </Alert>
                                ) : null}
                                <Group position="right" mt={'md'}>
                                    <Button
                                        color={'gray'}
                                        onClick={() => {
                                            setErrorMessage(null);
                                            close();
                                        }}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color={'red'}
                                        loading={isLoading}
                                        leftIcon={<IconSquareX />}
                                        onClick={handleDelete}
                                    >
                                        {t('Delete')}
                                    </Button>
                                </Group>
                            </Modal>
                        ) : null}
                    </Group>
                </Group>
            }
        >
            <Card.Section>
                <Group position="left" m="md" align="flex-start">
                    <Stack spacing="xs">
                        <Group position="left">
                            <Center>
                                <IconAt />
                            </Center>
                            <LabeledText label={t('Email')} raw>
                                {contact.emailAddress ? (
                                    <Anchor href={`mailto:${contact.emailAddress}`}>{contact.emailAddress}</Anchor>
                                ) : (
                                    <Text color="dimmed">-</Text>
                                )}
                            </LabeledText>
                        </Group>
                        <Group>
                            <Center>
                                <IconPhone />
                            </Center>
                            <LabeledText label={t('Main Phone')} raw>
                                {contact.mainPhone || <Text color="dimmed">-</Text>}
                            </LabeledText>
                        </Group>
                        <Group>
                            <Center>
                                <IconDeviceMobile />
                            </Center>
                            <LabeledText label={t('Mobile Phone')} raw>
                                {contact.mobilePhone || <Text color="dimmed">-</Text>}
                            </LabeledText>
                        </Group>
                    </Stack>
                </Group>
            </Card.Section>
            <Card.Section
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                {/* <Tabs
                    defaultValue="other"
                    value={tab}
                    onTabChange={onTabChange}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                    }}
                >
                    <Tabs.List>
                        <Tabs.Tab value="other" icon={<IconQuestionMark size={16} />}>
                            {t("Other")}
                        </Tabs.Tab>
                        <Group position="right" sx={{ flexGrow: 1 }}></Group>
                    </Tabs.List>
                    <Tabs.Panel value="other" sx={{ height: "100%" }}>
                        <Center sx={{ height: "100%" }}>Other</Center>
                    </Tabs.Panel>
                </Tabs> */}
            </Card.Section>
        </View>
    );
};

export default Contact;
