import { useGetCustomerLocationsQuery } from '@/management/api';
import { Customer, GetCustomerLocationsRequest, Location } from '@/management/models';
import { Continuation } from '@/models';
import { Anchor, Box, Group, Text, useMantineTheme } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Pagination from 'src/common/components/Pagination';
import RelationList from 'src/common/components/RelationList';

export interface CustomerLocationsProps {
    customer?: Customer;
    customerId: string;
    search?: string;
}

export const CustomerLocations = ({ customer, customerId, search }: CustomerLocationsProps) => {
    const theme = useMantineTheme();
    const location = useLocation();
    const { t } = useTranslation();
    const [req, setRequest] = useState<GetCustomerLocationsRequest>({
        customerId,
        search: search ?? '',
        returnCount: true,
    });
    const [virtPageNumber, setVirtPageNumber] = useState(0);
    const {
        data: { locations = [], continue: paging = { count: -1, limit: 0 } as Continuation } = {},
        isLoading,
        isError,
    } = useGetCustomerLocationsQuery(req);
    const columnHelper = createColumnHelper<Location>();
    const columns = useMemo(
        () => [
            columnHelper.accessor('name', {
                header: () => <Text weight="bold">{t('Name')}</Text>,
                cell: (info) => (
                    <Anchor component={Link} to={`/locations/${info.row.original.id}`}>
                        {info.getValue()}
                    </Anchor>
                ),
            }),
            columnHelper.accessor((row) => row.address?.line1, {
                id: 'address.line1',
                header: (info) => <Text weight="bold">{t('Address')}</Text>,
                cell: (info) => <Text>{info.getValue()}</Text>,
            }),
            columnHelper.accessor((row) => row.address?.locality, {
                id: 'address.locality',
                header: (info) => <Text weight="bold">{t('City')}</Text>,
                cell: (info) => <Text>{info.getValue()}</Text>,
            }),
            columnHelper.accessor((row) => row.address?.administrativeArea, {
                id: 'address.administrativeArea',
                cell: (info) => <Text>{info.getValue()}</Text>,
                header: (info) => <Text weight="bold">{t('State')}</Text>,
            }),
        ],
        [columnHelper],
    );
    const gridTemplateColumns = '30% 1fr 1fr 1fr';
    const table = useReactTable({
        data: locations,
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true,
    });

    const goToNextPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber + 1);
        setRequest((req) => ({
            ...req,
            continue: paging.next,
        }));
    };

    const goToPrevPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber - 1);
        setRequest((req) => ({
            ...req,
            continue: paging.prev,
        }));
    };

    const [searchQuery] = useDebouncedValue(search, 200);
    if (searchQuery != req.search) {
        setRequest((req) => ({
            ...req,
            continue: '',
            search: searchQuery,
        }));
    }

    return (
        <RelationList
            table={table}
            gridTemplateColumns={gridTemplateColumns}
            footer={
                <Box
                    sx={{
                        borderTop: `1px solid ${
                            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
                        }`,
                    }}
                >
                    <Group position="apart" p="sm">
                        <Pagination
                            goToNextPage={goToNextPage}
                            goToPrevPage={goToPrevPage}
                            virtPageNumber={virtPageNumber}
                            limit={paging.limit}
                            count={paging.count}
                        />
                    </Group>
                </Box>
            }
        />
    );
};

export default CustomerLocations;
