import { useLocation, Location } from 'react-router-dom';
import { klona } from 'klona';

export const useTypedLocation = <TState>(): Location & { state: TState } => {
    return useLocation() as Location & { state: TState };
};

export type Primitive = bigint | boolean | null | number | string | symbol | undefined;

export type PlainObject = Record<string, Primitive>;

export function isPlainObject(value: any): value is PlainObject {
    if (typeof value === 'object' && value !== null) {
        const proto = Object.getPrototypeOf(value);
        return proto === Object.prototype || proto === null;
    }
    return false;
}

const PATH_SEGMENTS_RE = /[^\[\.]+|\[\d+\]/g;

export function getPathSegments(path: string | number | symbol): Array<string | number | symbol> | undefined {
    if (typeof path === 'string') {
        return path.match(PATH_SEGMENTS_RE)?.map((prop) => {
            if (prop.startsWith('[')) {
                return parseInt(prop.slice(1, -1));
            }
            return prop;
        });
    } else {
        return [path];
    }
}

export function getPath(path: string | number | symbol, values: unknown) {
    const s = getPathSegments(path);
    if (!s || s.length === 0 || !values) {
        return undefined;
    }

    let target: any = values;
    for (let idx = 0, end = s.length - 1; idx < end; idx++) {
        // can just use `!` here because any intermediary path should *not* be a falsy primitive value.
        if (!target[s[idx]]) {
            return undefined;
        }
        target = target[s[idx]];
    }

    return target[s[s.length - 1]];
}

export function setPath<T>(path: string | number | symbol, value: unknown, values: T, create: boolean = true): T {
    const s = getPathSegments(path);
    if (!s || s.length === 0) {
        return values;
    }

    let clone = klona(values) as any;
    if (!clone) {
        if (create) {
            clone = typeof s[1] === 'number' ? [] : {};
        } else {
            return values;
        }
    }

    let target = clone;
    for (let idx = 0, end = s.length - 1; idx < end; idx++) {
        // can just use `!` here because any intermediary path should *not* be a falsy primitive value.
        if (!target[s[idx]]) {
            if (!create) {
                return values;
            }
            target[s[idx]] = typeof s[idx + 1] === 'number' ? [] : {};
        }
        target = target[s[idx]];
    }

    target[s[s.length - 1]] = value;

    return clone;
}
