import { Badge, BadgeProps, createStyles, DefaultProps, Selectors, useComponentDefaultProps } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ProjectPriority,
    ProjectPriorityHigh,
    ProjectPriorityLow,
    ProjectPriorityMedium,
    ProjectPriorityNotSet,
} from 'src/support/models';

export interface ProjectPriorityBadgeStyleParams {}

const useStyles = createStyles((theme, params: ProjectPriorityBadgeStyleParams, getRef) => {
    return {
        root: {},
    };
});

type ProjectPriorityBadgeStyleNames = Selectors<typeof useStyles>;

export interface ProjectPriorityBadgeProps
    extends DefaultProps<ProjectPriorityBadgeStyleNames, ProjectPriorityBadgeStyleParams> {
    priority?: ProjectPriority;
    variant?: BadgeProps['variant'];
}

const componentName = 'CLUIProjectPriorityBadge';
const defaultProps: Partial<ProjectPriorityBadgeProps> = {
    variant: 'filled',
};

export const ProjectPriorityBadge = (props: ProjectPriorityBadgeProps) => {
    const { className, classNames, styles, unstyled, variant, priority, ...rest } = useComponentDefaultProps(
        componentName,
        defaultProps,
        props,
    );
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    const priorities = useMemo<{ [key in ProjectPriority]: [string, string] }>(
        () => ({
            [ProjectPriorityHigh]: [t('High'), 'red'],
            [ProjectPriorityMedium]: [t('Medium'), 'orange'],
            [ProjectPriorityLow]: [t('Low'), 'yellow'],
            [ProjectPriorityNotSet]: [t('Not Set'), 'gray'],
        }),
        [t],
    );
    const [label, color] = priorities[priority!] ?? [t('Not Set'), 'gray'];

    return (
        <Badge variant={variant} className={cx(classes.root, className)} color={color} {...rest}>
            {label}
        </Badge>
    );
};

export default ProjectPriorityBadge;
