import { Box, Text, createStyles, DefaultProps, Selectors, useComponentDefaultProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Address as AddressType } from 'src/models';

export interface AddressStyleParams {}

const useStyles = createStyles((theme, params: AddressStyleParams, getRef) => {
    return {
        root: {},
    };
});

type AddressStyleNames = Selectors<typeof useStyles>;

export interface AddressProps extends DefaultProps<AddressStyleNames, AddressStyleParams> {
    address?: AddressType;
}

const componentName = 'CLUIAddress';
const defaultProps: Partial<AddressProps> = {};

export const Address = (props: AddressProps) => {
    const {
        className,
        classNames,
        styles,
        unstyled,
        address = {},
        ...rest
    } = useComponentDefaultProps(componentName, defaultProps, props);
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    return (
        <Box className={cx(classes.root, className)} {...rest}>
            {address && Object.values(address).some((value) => value && value.trim() !== '') ? (
                <>
                    <Text>{address.line1}</Text>
                    {address.line2 ? <Text>{address.line2}</Text> : null}
                    <Text>
                        {address.locality}
                        {address.locality && (address.administrativeArea || address.postalCode) ? `, ` : ''}
                        {address.administrativeArea} {address.postalCode}
                    </Text>
                </>
            ) : (
                <Text color="dimmed">-</Text>
            )}
        </Box>
    );
};

export default Address;
