import { useGetProjectsQuery } from '@/support/api';
import { Project, GetProjectsRequest } from '@/support/models';
import {
    Alert,
    Anchor,
    Card,
    Chip,
    Group,
    Text,
    TextInput,
    Title,
    ActionIcon,
    Badge,
    Loader,
    Button,
} from '@mantine/core';
import {
    IconAlertCircle,
    IconSearch,
    IconSquareX,
    IconRefresh,
    IconChevronLeft,
    IconChevronRight,
    IconCaretDown,
    IconCaretUp,
} from '@tabler/icons';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import List from '@/common/components/List';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import ProjectStatusBadge from '../ProjectStatusBadge';
import {
    setInstalled,
    setInProgress,
    setSearch,
    setHubSpotPages,
    setVirtPageNumber,
    setAllCompanies,
    setSortBy,
    setContinuePage,
    setDirection,
} from '../../slice';
import { useDispatch, useSelector } from 'react-redux';
import { Continuation } from 'src/models';
import { RootState } from '@/app/store';
import { supportApi } from '../../api';

export interface ProjectProps {}

export const Projects = ({}: ProjectProps) => {
    const dispatch = useDispatch();
    const {
        installed,
        inprogress,
        search,
        virtPageNumber,
        hubSpotPages,
        allCompanies,
        sortBy,
        direction,
        continuePage,
    } = useSelector((state: RootState) => state.projects);
    const { t } = useTranslation();
    const [searchQuery] = useDebouncedValue(search, 200);
    const [opened, { toggle }] = useDisclosure(false);
    const prefetchPage = supportApi.usePrefetch('getProjects');
    const [installedChange] = useDebouncedValue(installed, 200);
    const [inprogressChange] = useDebouncedValue(inprogress, 200);

    const [req, setRequest] = useState<GetProjectsRequest>({
        continue: hubSpotPages[virtPageNumber] ? hubSpotPages[virtPageNumber] : '0',
        search: search,
        installed: installed,
        inProgress: inprogress,
        allCompanies: allCompanies,
        order: sortBy,
        direction: direction,
    });
    let {
        data: {
            projects = [],
            continue: paging = { count: -1, limit: 0 } as Continuation,
            next: next = '',
            allCompanies: allCompaniesRes = [''],
        } = {},
        isFetching,
        refetch,
    } = useGetProjectsQuery(req, {});

    useEffect(() => {
        if (sortBy != req.order) {
            setRequest((req) => ({
                ...req,
                order: sortBy,
            }));
        }
    }, [sortBy]);

    useEffect(() => {
        if (next != '' && !hubSpotPages.includes(next)) {
            dispatch(setHubSpotPages([...hubSpotPages, next]));
        }
    }, [next]);

    useEffect(() => {
        if (allCompaniesRes[0] != '' && allCompanies[0] == '') {
            dispatch(setAllCompanies(allCompaniesRes));
        }
    }, [allCompaniesRes]);

    useEffect(() => {
        if (installedChange !== req.installed || inprogressChange !== req.inProgress) {
            dispatch(setHubSpotPages(['0', '20']));
            dispatch(setVirtPageNumber(0));
            dispatch(setContinuePage('0'));
            setRequest((req) => ({
                ...req,
                continue: '0',
                installed: installedChange,
                inProgress: inprogressChange,
                allCompanies: allCompanies,
            }));
        }
    }, [installedChange, inprogressChange]);

    useEffect(() => {
        if (searchQuery !== req.search) {
            dispatch(setHubSpotPages(['0', '20']));
            dispatch(setVirtPageNumber(0));
            dispatch(setContinuePage('0'));
            setRequest((req) => ({
                ...req,
                continue: '0',
                search: searchQuery,
                allCompanies: allCompanies,
            }));
        }
    }, [searchQuery]);

    const goToNextPage = () => {
        dispatch(setVirtPageNumber(virtPageNumber + 1));
        dispatch(setContinuePage(hubSpotPages[virtPageNumber + 1]));
        if (hubSpotPages[virtPageNumber] !== '') {
            setRequest((req) => ({
                ...req,
                continue: hubSpotPages[virtPageNumber + 1],
                allCompanies: allCompanies,
            }));
        }
    };

    const goToPrevPage = () => {
        dispatch(setVirtPageNumber(virtPageNumber - 1));
        dispatch(setContinuePage(hubSpotPages[virtPageNumber - 1]));
        if (virtPageNumber > 0) {
            setRequest((req) => ({
                ...req,
                continue: hubSpotPages[virtPageNumber - 1],
                allCompanies: allCompanies,
            }));
        }
    };

    const handleSortClick = (sortBy: string, direction: string) => {
        dispatch(setSortBy(sortBy));
        dispatch(setDirection(direction));

        setRequest((req) => ({
            ...req,
            order: sortBy,
            direction: direction,
        }));
    };

    const columnHelper = createColumnHelper<Project>();
    const columns = useMemo(
        () => [
            columnHelper.accessor('companyName', {
                header: () => (
                    <Group spacing={5}>
                        <Text mr={0} weight="bold">
                            {t('Location')}
                        </Text>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <HoverableCaret
                                handleSort={() => handleSortClick('company_name', 'ASCENDING')}
                                color="grey"
                                direction="ASCENDING"
                                size="15px"
                            />
                            <HoverableCaret
                                handleSort={() => handleSortClick('company_name', 'DESCENDING')}
                                color="grey"
                                direction="DESCENDING"
                                size="15px"
                            />
                        </div>
                    </Group>
                ),
                cell: (info) => {
                    if (info.row.original.pipeline == 'COMPANY') {
                        return (
                            <Text style={{ fontWeight: 700 }} size="lg">
                                {info.getValue()}
                            </Text>
                        );
                    } else {
                        return <Text size="md">{info.getValue()}</Text>;
                    }
                },
            }),
            columnHelper.accessor('name', {
                header: () => (
                    <Group spacing={5}>
                        <Text weight="bold">{t('Project')}</Text>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <HoverableCaret
                                handleSort={() => handleSortClick('subject', 'ASCENDING')}
                                color="grey"
                                direction="ASCENDING"
                                size="15px"
                            />
                            <HoverableCaret
                                handleSort={() => handleSortClick('subject', 'DESCENDING')}
                                color="grey"
                                direction="DESCENDING"
                                size="15px"
                            />
                        </div>
                    </Group>
                ),
                cell: (info) => (
                    <Anchor component={Link} to={`${info.row.original.id}/details`}>
                        <Text size="md">{info.getValue()}</Text>
                    </Anchor>
                ),
            }),
            columnHelper.accessor('status', {
                header: () => (
                    <Group spacing={5}>
                        <Text weight="bold">{t('Status')}</Text>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <HoverableCaret
                                handleSort={() => handleSortClick('hs_pipeline_stage', 'ASCENDING')}
                                color="grey"
                                direction="ASCENDING"
                                size="15px"
                            />
                            <HoverableCaret
                                handleSort={() => handleSortClick('hs_pipeline_stage', 'DESCENDING')}
                                color="grey"
                                direction="DESCENDING"
                                size="15px"
                            />
                        </div>
                    </Group>
                ),
                cell: (info) => {
                    if (info.row.original.pipeline == 'COMPANY' || info.row.original.pipeline == 'NO PROJECTS') {
                        return <></>;
                    } else {
                        return <ProjectStatusBadge sx={{ maxWidth: '100%' }} status={info.getValue()} />;
                    }
                },
            }),
            columnHelper.accessor('target', {
                header: () => (
                    <Group spacing={5}>
                        <Text weight="bold">{t('Target Install Date')}</Text>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <HoverableCaret
                                handleSort={() => handleSortClick('target_install_date', 'ASCENDING')}
                                color="grey"
                                direction="ASCENDING"
                                size="15px"
                            />
                            <HoverableCaret
                                handleSort={() => handleSortClick('target_install_date', 'DESCENDING')}
                                color="grey"
                                direction="DESCENDING"
                                size="15px"
                            />
                        </div>
                    </Group>
                ),
                cell: (info) => <Text>{info.getValue()}</Text>,
            }),
            columnHelper.accessor('carrier', {
                header: () => (
                    <Group spacing={5}>
                        <Text weight="bold">{t('Carrier Install Date')}</Text>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <HoverableCaret
                                handleSort={() => handleSortClick('carrier_scheduled_install_date', 'ASCENDING')}
                                color="grey"
                                direction="ASCENDING"
                                size="15px"
                            />
                            <HoverableCaret
                                handleSort={() => handleSortClick('carrier_scheduled_install_date', 'DESCENDING')}
                                color="grey"
                                direction="DESCENDING"
                                size="15px"
                            />
                        </div>
                    </Group>
                ),
                cell: (info) => <Text>{info.getValue()}</Text>,
            }),
        ],
        [columnHelper, t],
    );
    const gridTemplateColumns =
        'minmax(180px, 320px) minmax(240px, 450px) minmax(110px, 200px) minmax(200px, 210px) minmax(200px, 1fr)';

    const table = useReactTable({
        data: !inprogress && !installed ? [] : projects,
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true,
    });

    return (
        <List
            table={table}
            gridTemplateColumns={gridTemplateColumns}
            header={
                <>
                    <Group position="apart" sx={{ minHeight: 36, minWidth: '740px' }}>
                        <Group>
                            <Title order={3}>{t('All Projects')}</Title>
                            <Badge color="green" variant="light">
                                Beta
                            </Badge>
                            {inprogress || installed ? (
                                <></>
                            ) : (
                                <Alert
                                    pt="0.4rem"
                                    pb="0rem"
                                    icon={<IconAlertCircle size="1rem" />}
                                    title="No filters selected!"
                                    color="green"
                                >
                                    <></>
                                </Alert>
                            )}
                        </Group>

                        <Group position="right">
                            <Button
                                variant="light"
                                color="green"
                                onClick={() => !isFetching && refetch()}
                                disabled={isFetching}
                            >
                                {isFetching ? (
                                    <>
                                        <Loader color="green" size="xs" />
                                        <IconRefresh />
                                    </>
                                ) : (
                                    <IconRefresh />
                                )}
                            </Button>
                            <Chip size="xs" checked={inprogress} onChange={() => dispatch(setInProgress(!inprogress))}>
                                In Progress
                            </Chip>
                            <Chip size="xs" checked={installed} onChange={() => dispatch(setInstalled(!installed))}>
                                Installed
                            </Chip>

                            <TextInput
                                placeholder="Search"
                                value={search}
                                icon={<IconSearch size={16} stroke={1.5} />}
                                onChange={(evt) => dispatch(setSearch(evt.currentTarget.value))}
                                rightSection={
                                    search ? (
                                        <ActionIcon onClick={() => dispatch(setSearch(''))}>
                                            <IconSquareX color="red" size={16} stroke={1.5} />
                                        </ActionIcon>
                                    ) : (
                                        ''
                                    )
                                }
                            />
                        </Group>
                    </Group>
                </>
            }
            footer={
                <Card.Section withBorder>
                    <Group position="apart" p="sm" sx={{ minHeight: 60 }}>
                        <Group>
                            <ActionIcon
                                variant="filled"
                                onClick={goToPrevPage}
                                disabled={isFetching ? isFetching : virtPageNumber <= 0}
                            >
                                <IconChevronLeft />
                            </ActionIcon>
                            {paging.limit > 0 ? (
                                <Text sx={{ minWidth: 128, textAlign: 'center' }}>
                                    {`${virtPageNumber * paging.limit + 1}-${Math.min(
                                        (virtPageNumber + 1) * paging.limit,
                                        paging.count,
                                    )}`}{' '}
                                    {t('out of')} {paging.count}
                                </Text>
                            ) : null}
                            <ActionIcon
                                variant="filled"
                                onClick={goToNextPage}
                                disabled={isFetching ? isFetching : (virtPageNumber + 1) * paging.limit >= paging.count}
                                onMouseOver={() =>
                                    prefetchPage(
                                        {
                                            ...req,
                                            continue: hubSpotPages[virtPageNumber + 1],
                                            allCompanies: allCompanies,
                                        },
                                        {},
                                    )
                                }
                            >
                                <IconChevronRight />
                            </ActionIcon>
                        </Group>
                    </Group>
                </Card.Section>
            }
        />
    );
};

interface HoverableCaretProps {
    color: string;
    size: string;
    direction: 'ASCENDING' | 'DESCENDING';
    handleSort: () => void;
}

const HoverableCaret = ({ color, size, direction, handleSort }: HoverableCaretProps) => {
    const [isHovered, setHovered] = useState(false);
    const handleMouseEnter = () => {
        setHovered(true);
    };
    const handleMouseLeave = () => {
        setHovered(false);
    };
    const iconStyle = {
        color: isHovered ? 'green' : color,
        transform: isHovered ? 'scale(1.5)' : 'scale(1)',
        transition: 'color 0.3s ease, transform 0.2s ease',
    };
    if (direction == 'ASCENDING') {
        return (
            <IconCaretUp
                onClick={() => handleSort()}
                style={iconStyle}
                stroke={2}
                size={size}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                cursor="pointer"
            />
        );
    } else {
        return (
            <IconCaretDown
                onClick={() => handleSort()}
                style={iconStyle}
                stroke={2}
                size={size}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                cursor="pointer"
            />
        );
    }
};

export default Projects;
