import {
    useMantineTheme,
    Title,
    Center,
    Group,
    Card,
    Stack,
    Tabs,
    Button,
    Anchor,
    Menu,
    Modal,
    Alert,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import View from '@/common/components/View';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { useGetConnectionQuery, useLazyGetCustomerQuery, useDeleteConnectionMutation } from '@/management/api';
import { Connection as ConnectionType, Customer } from '@/management/models';
import {
    IconBuildingSkyscraper,
    IconChartBar,
    IconDots,
    IconInfoCircle,
    IconMapPin,
    IconPencil,
    IconSwitchVertical,
    IconSquareX,
    IconBuildingCommunity,
    IconAlertCircle,
} from '@tabler/icons';
import { Link } from 'react-router-dom';
import Address from 'src/common/components/Address';
import { ConnectionDetails } from '../ConnectionDetails/ConnectionDetails';
import { useAllowsActionOnResource } from '@/identity';
import * as $P from '@/identity/policy';
import ConnectionStatus from '../ConnectionStatus';
import ConnectionStatusBadge from '../ConnectionStatusBadge';
import LabeledText from 'src/common/components/LabeledText';
import { useDisclosure } from '@mantine/hooks';
import { useEffect } from 'react';
import { getErrorMessage } from '@/errors';
import { useState } from 'react';

export interface ConnectionProps {}

// https://picsum.photos/200/200

export const Connection = (props: ConnectionProps) => {
    const theme = useMantineTheme();
    const allowsActionOnResource = useAllowsActionOnResource();
    const { connectionId, tab } = useParams();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);
    const [deleteConnection, { isSuccess }] = useDeleteConnectionMutation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const handleDelete = async () => {
        setErrorMessage(null);
        try {
            await deleteConnection({ id: connection.id }).unwrap();
            navigate(-1);
        } catch (err) {
            setErrorMessage(getErrorMessage(err));
        }
    };
    const {
        data: { connection = {} as ConnectionType } = {},
        isLoading,
        isError,
    } = useGetConnectionQuery(
        { id: connectionId! },
        {
            pollingInterval: 60 * 1000,
        },
    );
    const [getCustomer, { data: { customer = {} as Customer } = {} }] = useLazyGetCustomerQuery();
    if (allowsActionOnResource($P.CanManageConnection, $P.ConnectionResourceFmt(connectionId!))) {
        useEffect(() => {
            if (connection.crn) {
                const regex = /customer:(\d+)/;
                const match = regex.exec(connection.crn);
                if (match && match[1]) {
                    getCustomer({ id: match[1] });
                }
            }
        }, [connection.crn]);
    }

    function onTabChange(value: string) {
        navigate(`./../${value}`, { replace: true });
    }

    // goto default tab
    if (!tab) {
        return <Navigate to={'details'} replace />;
    }

    return (
        <View
            header={
                <Group position="apart" sx={{ minHeight: 36 }}>
                    <Title order={3}>{connection.name ?? t('Unknown')}</Title>
                    <Group position="right">
                        <Menu position="left-start" shadow="md" width={200}>
                            <Menu.Target>
                                <Button variant="light" color="blue" px="xs">
                                    <IconDots />
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>Actions</Menu.Label>
                                {allowsActionOnResource(
                                    $P.CanManageConnection,
                                    $P.ConnectionResourceFmt(connectionId!),
                                ) ? (
                                    <>
                                        <Menu.Item
                                            component={Link}
                                            icon={<IconPencil size={14} />}
                                            state={{
                                                backgroundLocation: location,
                                            }}
                                            to="./../edit"
                                        >
                                            {t('Edit')}
                                        </Menu.Item>
                                        <Menu.Item component="button" icon={<IconSquareX size={14} />} onClick={open}>
                                            {t('Delete')}
                                        </Menu.Item>
                                    </>
                                ) : null}
                            </Menu.Dropdown>
                        </Menu>
                        {allowsActionOnResource($P.CanManageConnection, $P.ConnectionResourceFmt(connectionId!)) ? (
                            <Modal
                                opened={opened}
                                onClose={close}
                                title={
                                    <>
                                        Are you sure?
                                        <br />
                                        Connection {connection.name} will be permanently deleted.
                                    </>
                                }
                                centered
                                withCloseButton={false}
                            >
                                {errorMessage ? (
                                    <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                                        {errorMessage}
                                    </Alert>
                                ) : null}
                                <Group position="right" mt={'md'}>
                                    <Button
                                        color={'gray'}
                                        onClick={() => {
                                            setErrorMessage(null);
                                            close();
                                        }}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color={'red'}
                                        loading={isLoading}
                                        leftIcon={<IconSquareX />}
                                        onClick={handleDelete}
                                    >
                                        {t('Delete')}
                                    </Button>
                                </Group>
                            </Modal>
                        ) : null}
                    </Group>
                </Group>
            }
        >
            <Card.Section>
                <Group position="left" m="md" align="flex-start">
                    <Stack spacing="xs">
                        <Group position="left">
                            <Center>
                                <IconBuildingSkyscraper />
                            </Center>
                            <LabeledText label={t('Location')} raw>
                                <Anchor component={Link} to={`/locations/${connection?.location?.id}`}>
                                    {connection?.location?.name ?? t('Unknown')}
                                </Anchor>
                            </LabeledText>
                        </Group>
                        <Group position="left">
                            <Center>
                                <IconMapPin />
                            </Center>
                            <LabeledText label={t('Address')} raw>
                                <Address address={connection.location?.address} />
                            </LabeledText>
                        </Group>
                    </Stack>
                    <Stack ml="md" spacing="xs">
                        <Group position="left">
                            <Center>
                                <IconSwitchVertical />
                            </Center>
                            <LabeledText label={t('Status')} raw>
                                <ConnectionStatusBadge status={connection.status} />
                            </LabeledText>
                        </Group>
                        {allowsActionOnResource($P.CanManageConnection, $P.ConnectionResourceFmt(connectionId!)) ? (
                            <Group position="left">
                                <Center>
                                    <IconBuildingCommunity />
                                </Center>
                                <LabeledText label={t('Customer')} raw>
                                    <Anchor component={Link} to={`/customers/${customer?.id}`}>
                                        {customer?.name ?? t('Unknown')}
                                    </Anchor>
                                </LabeledText>
                            </Group>
                        ) : null}
                    </Stack>
                </Group>
            </Card.Section>
            <Card.Section
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                <Tabs
                    defaultValue="connections"
                    value={tab}
                    onTabChange={onTabChange}
                    keepMounted={false}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}
                >
                    <Tabs.List>
                        <Tabs.Tab value="details" icon={<IconInfoCircle size={16} />}>
                            {t('Details')}
                        </Tabs.Tab>
                        <Tabs.Tab value="status" icon={<IconChartBar size={16} />}>
                            {t('Status')}
                        </Tabs.Tab>
                        {/* <Tabs.Tab
                            value="other"
                            icon={<IconQuestionMark size={16} />}
                        >
                            {t("Other")}
                        </Tabs.Tab> */}
                        <Group position="right" sx={{ flexGrow: 1 }}></Group>
                    </Tabs.List>
                    <Tabs.Panel value="details" sx={{ height: '100%' }}>
                        <ConnectionDetails details={connection.details} />
                    </Tabs.Panel>
                    <Tabs.Panel value="status" sx={{ height: '100%' }}>
                        <ConnectionStatus connection={connection} connectionId={connectionId} />
                    </Tabs.Panel>
                    {/* <Tabs.Panel value="other" sx={{ height: "100%" }}>
                        <Center sx={{ height: "100%" }}>Other</Center>
                    </Tabs.Panel> */}
                </Tabs>
            </Card.Section>
        </View>
    );
};

export default Connection;
