import { getErrorMessage } from '@/errors';
import { Accordion, Alert, Button, Code, Group, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle, IconDeviceFloppy } from '@tabler/icons';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectionDetailsInput from '@/management/components/ConnectionDetailsInput';
import ConnectionMonitorInput from '@/management/components/ConnectionMonitorInput';
import { useUpdateConnectionMutation } from '@/management/api';
import {
    Connection,
    ConnectionDetails,
    ConnectionMonitor,
    ConnectionStatus,
    ConnectionStatusDown,
    ConnectionStatusPaused,
    ConnectionStatusUnknown,
    ConnectionStatusUp,
    ConnectionStatusWarning,
} from '@/management/models';
import ConnectionStatusBadge from '../ConnectionStatusBadge';
import { useFeatureCheck, $F } from '@/identity';

export interface UpdateConnectionFormProps {
    connection: Connection;
    connectionId: string;
    onClose?: () => void;
}

type UpdateConnectionFormValues = {
    name: string;
    details: ConnectionDetails;
    monitor: ConnectionMonitor;
    status?: ConnectionStatus;
};

interface StatusSelectItemProps extends React.ComponentPropsWithoutRef<'div'> {
    value: ConnectionStatus;
}

const StatusSelectItem = forwardRef<HTMLDivElement, StatusSelectItemProps>(
    ({ value, ...rest }: StatusSelectItemProps, ref) => {
        return (
            <div ref={ref} {...rest}>
                <ConnectionStatusBadge status={value} />
            </div>
        );
    },
);

export const UpdateConnectionForm = ({ connection, connectionId, onClose }: UpdateConnectionFormProps) => {
    const { t, i18n } = useTranslation();
    const feat = useFeatureCheck();
    const form = useForm<UpdateConnectionFormValues>({
        initialValues: {
            name: '',
            details: {},
            monitor: {},
            status: undefined,
        },
        validate: {
            name: (value) => (value.length < 1 ? t('Name is required') : null),
        },
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [updateConnection, { isLoading: isSaving, isSuccess, isError }] = useUpdateConnectionMutation();

    const onSubmit = useCallback(
        async ({ name, details, monitor, status }: UpdateConnectionFormValues) => {
            try {
                const res = await updateConnection({
                    connection: {
                        id: connectionId,
                        name,
                        details,
                        monitor,
                        status,
                    },
                }).unwrap();
                if (onClose) {
                    onClose();
                }
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
            }
        },
        [updateConnection, onClose],
    );

    const connectionStatusMap = useMemo(
        () => ({
            [ConnectionStatusUnknown]: [t('Unknown'), 'gray'],
            [ConnectionStatusDown]: [t('Down'), 'red'],
            [ConnectionStatusWarning]: [t('Warning'), 'yellow'],
            [ConnectionStatusUp]: [t('Up'), 'teal'],
            [ConnectionStatusPaused]: [t('Paused'), 'gray'],
        }),
        [t],
    );

    const connectionStatusData = useMemo(() => {
        return Object.keys(connectionStatusMap).map((key) => ({
            value: key,
            label: connectionStatusMap[key as ConnectionStatus][0],
            color: connectionStatusMap[key as ConnectionStatus][1],
        }));
    }, [connectionStatusMap]);

    useEffect(() => {
        if (connection) {
            form.setValues({
                name: connection.name,
                details: connection.details ?? {},
                monitor: connection.monitor ?? {},
            });
        }
    }, [connection]);

    return (
        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
            <TextInput label={t('Location')} disabled value={connection?.location?.name ?? t('Unknown')} />
            <TextInput
                mt="md"
                data-autofocus
                required
                label={t('Name')}
                placeholder={t('Name')}
                {...form.getInputProps('name')}
            />
            <Select
                mt="md"
                label={t('Status')}
                placeholder={t('Manually set status')}
                allowDeselect
                data={connectionStatusData}
                itemComponent={StatusSelectItem}
                {...form.getInputProps('status')}
            />
            <Accordion variant="separated" mt="md">
                <Accordion.Item value="details">
                    <Accordion.Control>{t('Details')}</Accordion.Control>
                    <Accordion.Panel>
                        <ConnectionDetailsInput {...form.getInputProps('details')} />
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="monitor">
                    <Accordion.Control>{t('Monitor')}</Accordion.Control>
                    <Accordion.Panel>
                        <ConnectionMonitorInput {...form.getInputProps('monitor')} />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            {errorMessage ? (
                <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                    {errorMessage}
                </Alert>
            ) : null}
            <Group position="right" mt={'md'}>
                <Button color={'gray'} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button type="submit" color={'primary'} loading={isSaving} leftIcon={<IconDeviceFloppy />}>
                    {t('Save')}
                </Button>
            </Group>
            {feat($F.FormDebug) ? (
                <Code mt="md" block>
                    {JSON.stringify(form.values, null, 2)}
                </Code>
            ) : null}
        </form>
    );
};

export default UpdateConnectionForm;
