export const SLICE_NAME = 'environment' as const;

export const AUTHENTICATION_METHOD_LOCAL = 'local';
export const AUTHENTICATION_METHOD_REMOTE = 'remote';

export type AuthenticationMethodType = typeof AUTHENTICATION_METHOD_LOCAL | typeof AUTHENTICATION_METHOD_REMOTE;

export const AuthenticationMethods = Object.freeze({
    LOCAL: AUTHENTICATION_METHOD_LOCAL as AuthenticationMethodType,
    REMOTE: AUTHENTICATION_METHOD_REMOTE as AuthenticationMethodType,
});

export interface EnvironmentState {
    authenticationMethod: AuthenticationMethodType;
    apiUrl: string;
}

export type EnvironmentStateSlice = {
    [SLICE_NAME]: EnvironmentState;
};
