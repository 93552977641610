import { useGetTicketsQuery } from '@/support/api';
import { Ticket, GetTicketsRequest, TicketStatusClosed } from '@/support/models';
import { Continuation } from '@/models';
import { Anchor, Card, Group, Text, TextInput, Title } from '@mantine/core';
import { IconSearch } from '@tabler/icons';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import List from '@/common/components/List';
import Pagination from '@/common/components/Pagination';
import { useDebouncedValue } from '@mantine/hooks';
import TicketStatusBadge from '../TicketStatusBadge';
import { formatDistance, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import TicketPriorityBadge from '../TicketPriorityBadge';
import TicketTypeBadge from '../TicketTypeBadge';

export interface TicketsProps {}

export const Tickets = ({}: TicketsProps) => {
    const { t } = useTranslation();
    const [req, setRequest] = useState<GetTicketsRequest>({
        search: '',
    });
    const [virtPageNumber, setVirtPageNumber] = useState(0);
    const { data: { tickets = [], continue: paging = { count: -1, limit: 0 } as Continuation } = {} } =
        useGetTicketsQuery(req, {
            pollingInterval: 60 * 1000,
        });
    const columnHelper = createColumnHelper<Ticket>();
    const columns = useMemo(
        () => [
            columnHelper.accessor('id', {
                header: () => <Text weight="bold">{t('ID')}</Text>,
                cell: (info) => <Text>#{info.getValue()}</Text>,
            }),
            columnHelper.accessor('subject', {
                header: () => <Text weight="bold">{t('Subject')}</Text>,
                cell: (info) => (
                    <Anchor component={Link} to={`${info.row.original.id}`}>
                        {info.getValue()}
                    </Anchor>
                ),
            }),
            columnHelper.accessor('priority', {
                header: () => <Text weight="bold">{t('Priority')}</Text>,
                cell: (info) => (
                    <TicketPriorityBadge
                        variant={info.row.original.status != TicketStatusClosed ? 'filled' : 'light'}
                        priority={info.getValue()}
                    />
                ),
            }),
            columnHelper.accessor('status', {
                header: () => <Text weight="bold">{t('Status')}</Text>,
                cell: (info) => <TicketStatusBadge sx={{ maxWidth: '100%' }} status={info.getValue()} />,
            }),
            columnHelper.accessor('type', {
                header: () => <Text weight="bold">{t('Type')}</Text>,
                cell: (info) => <TicketTypeBadge sx={{ maxWidth: '100%' }} type={info.getValue()} />,
            }),
            columnHelper.accessor('updatedAt', {
                header: () => <Text weight="bold">{t('Updated')}</Text>,
                cell: (info) => (
                    <Text
                        sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {formatDistance(parseISO(info.getValue()), Date.now(), {
                            addSuffix: true,
                        })}
                    </Text>
                ),
            }),
        ],
        [columnHelper, t],
    );
    const gridTemplateColumns =
        '100px minmax(256px, 1fr) 128px minmax(100px, 208px) minmax(100px, 208px) minmax(100px, 208px)'; //"100px 1fr 128px 208px 208px 208px";
    const table = useReactTable({
        data: tickets,
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true,
    });

    const goToNextPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber + 1);
        setRequest((req) => ({
            ...req,
            continue: paging.next,
        }));
    };

    const goToPrevPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber - 1);
        setRequest((req) => ({
            ...req,
            continue: paging.prev,
        }));
    };

    const [search, setSearch] = useState('');
    const [searchQuery] = useDebouncedValue(search, 200);
    if (searchQuery !== req.search) {
        setRequest((req) => ({
            ...req,
            continue: '',
            search: searchQuery,
        }));
    }

    return (
        <List
            table={table}
            gridTemplateColumns={gridTemplateColumns}
            header={
                <Group position="apart" sx={{ minHeight: 36 }}>
                    <Title order={3}>{t('All Tickets')}</Title>
                    <Group position="right">
                        <TextInput
                            placeholder="Search"
                            icon={<IconSearch size={16} stroke={1.5} />}
                            onChange={(evt) => setSearch(evt.currentTarget.value)}
                        />
                    </Group>
                </Group>
            }
            footer={
                <Card.Section withBorder>
                    <Group position="apart" p="sm" sx={{ minHeight: 60 }}>
                        <Pagination
                            goToNextPage={goToNextPage}
                            goToPrevPage={goToPrevPage}
                            virtPageNumber={virtPageNumber}
                            limit={paging.limit}
                            count={paging.count}
                        />
                    </Group>
                </Card.Section>
            }
        />
    );
};

export default Tickets;
