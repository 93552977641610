import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface GoogleReCaptchaProps {
    sitekey: string;
    onVerify: (token: string | null) => void;
    onExpired?: () => void;
    theme?: 'light' | 'dark';
    size?: 'normal' | 'compact' | 'invisible';
    tabindex?: number;
}

export default function GoogleReCaptcha({ onVerify }: GoogleReCaptchaProps) {
    const [reCaptchaResult, setReCaptchaResult] = useState<string | null>(null);

    const handleVerify = (token: string | null) => {
        setReCaptchaResult(token);
        onVerify(token);
    };

    return <ReCAPTCHA sitekey="6LfMilolAAAAADyVTNFfMGZ9cy9uNvRDMhq5-uL7" onChange={handleVerify} />;
}
