import { parsePhoneNumber } from 'libphonenumber-js';

export function createAttachmentFormData({ as, file }: { as: string; file: File }): FormData {
    const data = new FormData();
    data.append('as', as);
    data.append('attachment', file);
    return data;
}

export const validatePhoneNumber = (number: string) => {
    if (number) {
        try {
            const numberObject = parsePhoneNumber(number, 'US');
            if (!numberObject.isValid()) return 'Not a valid phone number';
        } catch {
            return 'Not a valid phone number';
        }
    }
    return null;
};

export const formatPhoneNumber = (number: string) => {
    if (number) {
        const numberObject = parsePhoneNumber(number, 'US');
        return numberObject.formatInternational().replace(/(\d+)\s(\d+)\s(\d+)\s(\d+)/, '$1 $2-$3-$4');
    } else {
        return '';
    }
};
