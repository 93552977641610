import { MantineSizes, MantineThemeOverride } from '@mantine/core';

const SHARED: MantineThemeOverride = {
    fontFamily: 'Poppins, sans-serif',
    headings: {
        fontFamily: 'Poppins, sans-serif',
    },
    colors: {
        // lighter+10% < color[6] < darker+20%
        // https://maketintsandshades.com/#11883B
        clover: [
            '#a0cfb1',
            '#88c49d',
            '#70b889',
            '#58ac76',
            '#41a062',
            '#29944f',
            '#11883B',
            '#0e6d2f',
            '#0a5223',
            '#073618',
        ],
        slate: [
            '#b0b7bb',
            '#9ca5aa',
            '#889298',
            '#748087',
            '#616e76',
            '#4d5c65',
            '#394A54',
            '#2e3b43',
            '#222c32',
            '#171e22',
        ],
        fog: [
            '#bfc5c8',
            '#b0b7bb',
            '#a0a8ad',
            '#909a9f',
            '#808b91',
            '#707d84',
            '#606E76',
            '#4d585e',
            '#3a4247',
            '#262c2f',
        ],
    },
    primaryColor: 'clover',
    primaryShade: { light: 6, dark: 8 },
    /*
    $zdepth-shadow-1: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    $zdepth-shadow-2: 0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23);
    $zdepth-shadow-3: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);
    $zdepth-shadow-4: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
    $zdepth-shadow-5: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
    */
    // shadows: {
    //     xs: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)',
    //     sm: '0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23)',
    //     md: '0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23)',
    //     lg: '0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22)',
    //     xl: '0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22)',
    // },
    shadows: {
        xs: '0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1)',
        sm: '0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 10px 15px -5px, rgba(0, 0, 0, 0.04) 0px 7px 7px -5px',
        md: '0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
        lg: '0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 28px 23px -7px, rgba(0, 0, 0, 0.04) 0px 12px 12px -7px',
        xl: '0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 36px 28px -7px, rgba(0, 0, 0, 0.04) 0px 17px 17px -7px',
    },
    other: {
        icons: {
            sizes: {
                xs: 12,
                sm: 16,
                md: 24,
                lg: 32,
                xl: 48,
            },
        },
    },
};

export type ThemeMode = 'light' | 'dark';

export const getThemeIndex = (mode: ThemeMode) => (mode === 'dark' ? 1 : 0);

export const DARK: MantineThemeOverride = {
    ...SHARED,
    colorScheme: 'dark',
};

export const LIGHT: MantineThemeOverride = {
    ...SHARED,
    colorScheme: 'light',
};

export const THEMES: Record<string, [MantineThemeOverride, MantineThemeOverride]> = {
    cloverleaf: [LIGHT, DARK],
};

export * from './ScopedGlobalStyles';
export * from './ScopedNormalizeCSS';

declare module '@mantine/core' {
    interface MantineThemeOther {
        navDecoration?: 'glass' | 'darken' | 'none';
        icons: { sizes: MantineSizes };
    }
}
