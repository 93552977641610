import { BadgeProps, createStyles, DefaultProps, Selectors, useComponentDefaultProps, Badge } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    TicketType,
    TicketTypeServiceDownFlapping,
    TicketTypeMoveAddChangeDelete,
    TicketTypeBillingRelated,
    TicketTypeGeneralInquiry,
    TicketTypeFeatureRequest,
} from 'src/support/models';

export interface TicketTypeBadgeStyleParams {}

const useStyles = createStyles((theme, params: TicketTypeBadgeStyleParams, getRef) => {
    return {
        root: {},
    };
});

type TicketTypeBadgeStyleNames = Selectors<typeof useStyles>;

export interface TicketTypeBadgeProps extends DefaultProps<TicketTypeBadgeStyleNames, TicketTypeBadgeStyleParams> {
    type?: TicketType;
    variant?: BadgeProps['variant'];
}

const componentName = 'CLUITicketTypeBadge';
const defaultProps: Partial<TicketTypeBadgeProps> = {
    variant: 'outline',
};

export const TicketTypeBadge = (props: TicketTypeBadgeProps) => {
    const { className, classNames, styles, unstyled, variant, type, ...rest } = useComponentDefaultProps(
        componentName,
        defaultProps,
        props,
    );
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    const types = useMemo<{ [key in TicketType]: [string, string] }>(
        () => ({
            [TicketTypeServiceDownFlapping]: [t('Service Down/Flapping'), 'gray'],
            [TicketTypeMoveAddChangeDelete]: [t('Move/Add/Change/Delete'), 'gray'],
            [TicketTypeBillingRelated]: [t('Billing Related'), 'gray'],
            [TicketTypeGeneralInquiry]: [t('General Inquiry'), 'gray'],
            [TicketTypeFeatureRequest]: [t('Feature Request'), 'gray'],
        }),
        [t],
    );
    const [label, color] = types[type!] ?? [t('Unknown'), 'gray'];
    return (
        <Badge className={cx(classes.root, className)} color={color} {...rest}>
            {label}
        </Badge>
    );
};

export default TicketTypeBadge;
