import { Box, createStyles, DefaultProps, Selectors, useComponentDefaultProps } from '@mantine/core';
import MapComponent, { MapProps as MapComponentProps } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

// TODO: why is this fix the only one that worked for:
// - https://github.com/mapbox/mapbox-gl-js/issues/10173
// - https://github.com/visgl/react-map-gl/issues/1266

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export interface MapStyleParams {}

const useStyles = createStyles((theme, params: MapStyleParams, getRef) => {
    return {
        root: {},
    };
});

type MapStyleNames = Selectors<typeof useStyles>;

export interface MapProps extends DefaultProps<MapStyleNames, MapStyleParams> {}

const componentName = 'CLUIMapboxGl';
const defaultProps: Partial<Omit<MapProps, '_'> & Omit<MapComponentProps, 'style'>> = {};
const schemeToStyle = {
    light: 'mapbox://styles/mapbox/streets-v11',
    dark: 'mapbox://styles/mapbox/dark-v10', //"mapbox://styles/mapbox/navigation-guidance-night-v4", // mapbox://styles/mapbox/dark-v10
};

export const Map = (props: Omit<MapProps, '_'> & Omit<MapComponentProps, 'style'>) => {
    const { className, classNames, styles, unstyled, fog, terrain, ...rest } = useComponentDefaultProps(
        componentName,
        defaultProps,
        props,
    );
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    return (
        <Box
            component={MapComponent}
            className={cx(classes.root, className)}
            {...rest}
            mapboxAccessToken={
                'pk.eyJ1IjoiY2xvdmVybGVhZm5ldHdvcmtzIiwiYSI6ImNsOGUwdjR0ajA0b2czeXFkcGN3eXR0bTEifQ.y7ARPkqAHEKlzF11hSypcg'
            }
            mapStyle={schemeToStyle[theme.colorScheme]}
            fog={fog!}
            terrain={terrain!}
        />
    );
};

// https://api.mapbox.com/styles/v1/mapbox/navigation-guidance-night-v4/static/2.3459,48.8513,8.75,0/840x464?access_token=pk.eyJ1IjoibWFwYm94LW1hcC1kZXNpZ24iLCJhIjoiY2syeHpiaHlrMDJvODNidDR5azU5NWcwdiJ9.x0uSqSWGXdoFKuHZC5Eo_Q

export default Map;

export * from 'react-map-gl';
