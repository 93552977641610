/* NOTE: normally these can exist with the slice, but in this case, to prevent circular
 *       dependencies around `dynamicBaseQuery`, it must be defined here.
 */
import { IdentityStateSlice } from './models';

const NO_FEATURES: readonly string[] = Object.freeze([]);

export const selectAuthorization = (state: IdentityStateSlice) => state.identity.authorization;
export const selectWhoAmI = (state: IdentityStateSlice) => state.identity.whoAmI;
export const selectHasPreviousSession = (state: IdentityStateSlice) => state.identity.hasPreviousSession;
export const selectNonInteractiveLoginAttempted = (state: IdentityStateSlice) =>
    state.identity.nonInteractiveLoginAttempted;
export const selectFeatures = (state: IdentityStateSlice) => state.identity.whoAmI?.features ?? NO_FEATURES;
export const selectDeveloperFeatures = (state: IdentityStateSlice) => state.identity.developerFeatures;
