import { useGetLocationConnectionsQuery } from '@/management/api';
import { Connection, GetLocationConnectionsRequest, Location } from '@/management/models';
import { Continuation } from '@/models';
import { Anchor, Box, Group, Text, useMantineTheme } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Pagination from 'src/common/components/Pagination';
import RelationList from 'src/common/components/RelationList';
import ConnectionStatusBadge from '../ConnectionStatusBadge';

export interface LocationsConnectionsProps {
    location?: Location;
    locationId: string;
    search?: string;
}

export const LocationConnections = ({ location, locationId, search }: LocationsConnectionsProps) => {
    const theme = useMantineTheme();
    const loc = useLocation();
    const { t } = useTranslation();
    const [req, setRequest] = useState<GetLocationConnectionsRequest>({
        locationId,
        search: search ?? '',
        returnCount: true,
    });
    const [virtPageNumber, setVirtPageNumber] = useState(0);
    const {
        data: { connections = [], continue: paging = { count: -1, limit: 0 } as Continuation } = {},
        isLoading,
        isError,
    } = useGetLocationConnectionsQuery(req);
    const columnHelper = createColumnHelper<Connection>();
    const columns = useMemo(
        () => [
            columnHelper.accessor('name', {
                header: () => <Text weight="bold">{t('Name')}</Text>,
                cell: (info) => (
                    <Anchor component={Link} to={`/connections/${info.row.original.id}`}>
                        {info.getValue()}
                    </Anchor>
                ),
            }),
            columnHelper.accessor('status', {
                header: () => <Text weight="bold">{t('Status')}</Text>,
                cell: (info) => <ConnectionStatusBadge status={info.getValue()} />,
            }),
        ],
        [columnHelper],
    );
    const gridTemplateColumns = '1fr 200px';
    const table = useReactTable({
        data: connections,
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true,
    });

    const goToNextPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber + 1);
        setRequest((req) => ({
            ...req,
            continue: paging.next,
        }));
    };

    const goToPrevPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber - 1);
        setRequest((req) => ({
            ...req,
            continue: paging.prev,
        }));
    };

    const [searchQuery] = useDebouncedValue(search, 200);
    if (searchQuery != req.search) {
        setRequest((req) => ({
            ...req,
            continue: '',
            search: searchQuery,
        }));
    }

    return (
        <RelationList
            table={table}
            gridTemplateColumns={gridTemplateColumns}
            footer={
                <Box
                    sx={{
                        borderTop: `1px solid ${
                            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
                        }`,
                    }}
                >
                    <Group position="apart" p="sm">
                        <Pagination
                            goToNextPage={goToNextPage}
                            goToPrevPage={goToPrevPage}
                            virtPageNumber={virtPageNumber}
                            limit={paging.limit}
                            count={paging.count}
                        />
                    </Group>
                </Box>
            }
        />
    );
};

export default LocationConnections;
