import { Box } from '@mantine/core';
import { Selectors, createStyles, DefaultProps } from '@mantine/styles';
import { ReactNode } from 'react';

export type ScopedGlobalCSSStyleNames = Selectors<typeof useStyles>;

export interface ScopedGlobalCSSProps extends DefaultProps<ScopedGlobalCSSStyleNames> {
    children: ReactNode;
}

const useStyles = createStyles((theme, params, getRef) => {
    const root = getRef('root');
    return {
        root: {
            ref: root,
            ...theme.fn.fontStyles(),
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
            lineHeight: theme.lineHeight,
            fontSize: theme.fontSizes.md,
        },
    };
});

export function ScopedGlobalStyles({ children, className, ...rest }: ScopedGlobalCSSProps) {
    const { classes, cx } = useStyles();
    return (
        <Box className={cx(classes.root, className)} {...rest}>
            {children}
        </Box>
    );
}

export default ScopedGlobalStyles;
