import { UseFormReturnType } from '@mantine/form';
import { getPath, setPath } from 'src/utils';

interface InputPropsWithValue extends Record<string, any> {
    value: any;
    onChange: any;
}

export function getInputOnChange<Value>(setValue: (value: Value | ((current: Value) => Value)) => void) {
    return (val: Value | React.ChangeEvent<unknown> | ((current: Value) => Value)) => {
        if (!val) {
            setValue(val as Value);
        } else if (typeof val === 'function') {
            setValue(val);
        } else if (typeof val === 'object' && 'nativeEvent' in val) {
            const { currentTarget } = val;
            if (currentTarget instanceof HTMLTextAreaElement) {
                setValue(currentTarget.value as any);
            } else if (currentTarget instanceof HTMLInputElement) {
                if (currentTarget.type === 'checkbox') {
                    setValue(currentTarget.checked as any);
                } else {
                    setValue(currentTarget.value as any);
                }
            }
        } else {
            setValue(val);
        }
    };
}

export type UseControlledFormInput<Values extends Record<string, unknown>> = {
    defaultValues?: Values;
    value?: Values;
    onChange?: (value: Values) => void;
};

export type GetInputProps<Values> = UseFormReturnType<Values>['getInputProps'];
export type UseControlledFormReturnType<Values> = Pick<UseFormReturnType<Values>, 'getInputProps'>;

export const useControlledForm = <Values extends Record<string, unknown>>({
    defaultValues: defaultValue = {} as Values,
    value = {} as Values,
    onChange = () => {},
}: UseControlledFormInput<Values>): UseControlledFormReturnType<Values> => {
    const getInputProps: GetInputProps<Values> = (
        path,
        { type = 'input', withError = type === 'input', withFocus = true } = {},
    ) => {
        const inputOnChange = getInputOnChange((inputValue) => {
            onChange(setPath(path, inputValue, value));
        });
        const payload: InputPropsWithValue = { onChange: inputOnChange, value: '' };
        const valueToSet = getPath(path, value) ?? getPath(path, defaultValue) ?? undefined;
        if (type === 'checkbox') {
            payload.checked = valueToSet;
        } else {
            payload.value = valueToSet;
        }
        return payload;
    };

    return {
        getInputProps,
    };
};
