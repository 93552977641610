import {
    useMantineTheme,
    Title,
    Group,
    Card,
    Stack,
    Image,
    Tabs,
    Button,
    Menu,
    TextInput,
    Modal,
    Alert,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import View from '@/common/components/View';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { $F, useAllowsActionOnResource, useFeatures } from '@/identity';
import { useGetCustomerQuery, useDeleteCustomerMutation } from '@/management/api';
import { Customer as CustomerType } from '@/management/models';
import {
    IconBuildingSkyscraper,
    IconDots,
    IconMapPin,
    IconPencil,
    IconSearch,
    IconSquareX,
    IconAlertCircle,
} from '@tabler/icons';
import CustomerLocations from '../CustomerLocations';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import * as $P from '@/identity/policy';
import AssociatedContact from 'src/crm/components/AssociatedContact';
import { useDisclosure } from '@mantine/hooks';
import { getErrorMessage } from '@/errors';

export interface CustomerProps {}

// https://picsum.photos/200/200

export const Customer = (props: CustomerProps) => {
    const theme = useMantineTheme();
    const location = useLocation();
    const allowsActionOnResource = useAllowsActionOnResource();
    const { hasSome } = useFeatures();
    const { customerId, tab } = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);
    const [deleteCustomer, { isSuccess }] = useDeleteCustomerMutation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const handleDelete = async () => {
        setErrorMessage(null);
        try {
            await deleteCustomer({ id: customer.id }).unwrap();
            navigate(-1);
        } catch (err) {
            setErrorMessage(getErrorMessage(err));
        }
    };
    const {
        data: { customer = {} as CustomerType } = {},
        isLoading,
        isError,
    } = useGetCustomerQuery({ id: customerId! });
    const [search, setSearch] = useState('');

    function onTabChange(value: string) {
        navigate(`./../${value}`, { replace: true });
    }

    // goto default tab
    if (!tab) {
        return <Navigate to={'locations'} replace />;
    }

    return (
        <View
            header={
                <Group position="apart" sx={{ minHeight: 36 }}>
                    <Title order={3}>{customer.name ?? t('Unknown')}</Title>
                    <Group position="right">
                        <Menu position="left-start" shadow="md" width={200}>
                            <Menu.Target>
                                <Button variant="light" color="cyan" px="xs">
                                    <IconDots />
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>{t('Actions')}</Menu.Label>
                                {allowsActionOnResource($P.CanManageCustomer, $P.CustomerResourceFmt(customerId!)) ? (
                                    <>
                                        <Menu.Item
                                            component={Link}
                                            icon={<IconPencil size={14} />}
                                            state={{
                                                backgroundLocation: location,
                                            }}
                                            to="./../edit"
                                        >
                                            {t('Edit')}
                                        </Menu.Item>
                                        <Menu.Item component="button" icon={<IconSquareX size={14} />} onClick={open}>
                                            {t('Delete')}
                                        </Menu.Item>
                                    </>
                                ) : null}
                                {allowsActionOnResource($P.CanManageLocation, $P.LocationsResource) ? (
                                    <Menu.Item
                                        component={Link}
                                        icon={<IconBuildingSkyscraper size={14} />}
                                        to={`./../locations/new`}
                                        state={{ backgroundLocation: location }}
                                    >
                                        {t('Create Location')}
                                    </Menu.Item>
                                ) : null}
                            </Menu.Dropdown>
                        </Menu>
                        <TextInput
                            placeholder="Search"
                            icon={<IconSearch size={16} stroke={1.5} />}
                            onChange={(evt) => setSearch(evt.currentTarget.value)}
                        />
                        {allowsActionOnResource($P.CanManageCustomer, $P.CustomerResourceFmt(customerId!)) ? (
                            <Modal
                                opened={opened}
                                onClose={close}
                                title={
                                    <>
                                        Are you sure?
                                        <br />
                                        Customer {customer.name} will be permanently deleted.
                                    </>
                                }
                                centered
                                withCloseButton={false}
                            >
                                <p>
                                    This will also remove all associated:
                                    <ul>
                                        <li>Locations</li>
                                        <li>Connections</li>
                                        <li>Groups</li>
                                    </ul>
                                </p>
                                {errorMessage ? (
                                    <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                                        {errorMessage}
                                    </Alert>
                                ) : null}
                                <Group position="right" mt={'md'}>
                                    <Button
                                        color={'gray'}
                                        onClick={() => {
                                            setErrorMessage(null);
                                            close();
                                        }}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color={'red'}
                                        loading={isLoading}
                                        leftIcon={<IconSquareX />}
                                        onClick={handleDelete}
                                    >
                                        {t('Delete')}
                                    </Button>
                                </Group>
                            </Modal>
                        ) : null}
                    </Group>
                </Group>
            }
        >
            <Card.Section>
                <Group position="left" m="md" align="flex-start">
                    <Image radius="md" width={200} height={200} fit="contain" src={customer?.logoUrl} withPlaceholder />
                    <Stack spacing="xs">
                        {hasSome($F.UxStaff, $F.UxAdmin) ? (
                            <AssociatedContact
                                label={t('Administrative Contact')}
                                contact={customer?.administrativeContact}
                            />
                        ) : null}

                        {hasSome($F.UxStaff, $F.UxAdmin) ? (
                            <AssociatedContact label={t('Technical Contact')} contact={customer?.technicalContact} />
                        ) : null}
                    </Stack>
                </Group>
            </Card.Section>
            <Card.Section
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                <Tabs
                    defaultValue="locations"
                    value={tab}
                    onTabChange={onTabChange}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}
                >
                    <Tabs.List>
                        <Tabs.Tab value="locations" icon={<IconMapPin size={16} />}>
                            {t('Locations')}
                        </Tabs.Tab>
                        {/* <Tabs.Tab value="other" icon={<IconQuestionMark size={16} />}>
                            {t("Other")}
                        </Tabs.Tab> */}
                        <Group position="right" sx={{ flexGrow: 1 }}></Group>
                    </Tabs.List>
                    <Tabs.Panel value="locations" sx={{ height: '100%' }}>
                        {customerId ? (
                            <CustomerLocations customerId={customerId} customer={customer} search={search} />
                        ) : null}
                    </Tabs.Panel>
                    {/* <Tabs.Panel value="other" sx={{ height: "100%" }}>
                        <Center sx={{ height: "100%" }}>Other</Center>
                    </Tabs.Panel> */}
                </Tabs>
            </Card.Section>
        </View>
    );
};

export default Customer;
