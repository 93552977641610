import { Badge, BadgeProps, createStyles, DefaultProps, Selectors, useComponentDefaultProps } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    TicketPriority,
    TicketPriorityP1Critical,
    TicketPriorityP2High,
    TicketPriorityP3Medium,
    TicketPriorityP4Low,
    TicketPriorityNotSet,
} from 'src/support/models';

export interface TicketPriorityBadgeStyleParams {}

const useStyles = createStyles((theme, params: TicketPriorityBadgeStyleParams, getRef) => {
    return {
        root: {},
    };
});

type TicketPriorityBadgeStyleNames = Selectors<typeof useStyles>;

export interface TicketPriorityBadgeProps
    extends DefaultProps<TicketPriorityBadgeStyleNames, TicketPriorityBadgeStyleParams> {
    priority?: TicketPriority;
    variant?: BadgeProps['variant'];
}

const componentName = 'CLUITicketPriorityBadge';
const defaultProps: Partial<TicketPriorityBadgeProps> = {
    variant: 'filled',
};

export const TicketPriorityBadge = (props: TicketPriorityBadgeProps) => {
    const { className, classNames, styles, unstyled, variant, priority, ...rest } = useComponentDefaultProps(
        componentName,
        defaultProps,
        props,
    );
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    const priorities = useMemo<{ [key in TicketPriority]: [string, string] }>(
        () => ({
            [TicketPriorityP1Critical]: [t('P1 Critical'), 'red'],
            [TicketPriorityP2High]: [t('P2 High'), 'orange'],
            [TicketPriorityP3Medium]: [t('P3 Medium'), 'yellow'],
            [TicketPriorityP4Low]: [t('P4 Low'), 'teal'],
            [TicketPriorityNotSet]: [t('Not Set'), 'gray'],
        }),
        [t],
    );
    const [label, color] = priorities[priority!] ?? [t('Unknown'), 'gray'];
    return (
        <Badge variant={variant} className={cx(classes.root, className)} color={color} {...rest}>
            {label}
        </Badge>
    );
};

export default TicketPriorityBadge;
