import {
    ActionIcon,
    Box,
    Center,
    createStyles,
    Group,
    Header,
    HeaderProps,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { IconMoon, IconSun } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/app/store';
import { toggleTheme } from '@/app/slice';
import Logo from '@/common/components/Logo';
import { Link } from 'react-router-dom';

export interface AppHeaderProps extends Omit<HeaderProps, 'children'> {
    navButton?: React.ReactNode;
}

const useStyles = createStyles((theme, params, getRef) => {
    return {};
});

export const AppHeader = ({ navButton, ...rest }: AppHeaderProps) => {
    const { classes, cx } = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useMantineTheme();
    const dispatch = useAppDispatch();
    const toggle = () => {
        dispatch(toggleTheme());
    };
    return (
        <Header sx={{ flexShrink: 0 }} {...rest}>
            <Group position="apart" sx={{ height: '100%' }}>
                <Center>
                    {navButton}
                    <Tooltip label={t('Home/Dashboard')} style={{ zIndex: 1000 }} position="right">
                        <Link to="/">
                            <Logo p="sm" sx={{ width: 256 }} />
                        </Link>
                    </Tooltip>
                </Center>
                <Box />
                <Box mr="md">
                    <Tooltip label={t('Toggle dark mode')}>
                        <ActionIcon onClick={toggle}>
                            {theme.colorScheme === 'dark' ? <IconSun /> : <IconMoon />}
                        </ActionIcon>
                    </Tooltip>
                </Box>
            </Group>
        </Header>
    );
};

export default AppHeader;
