import { useMantineTheme, Title, Center, Group, Card, Stack, Menu, Button, Tabs, Modal, Alert } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import View from '@/common/components/View';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { useGetUserQuery, useDeleteUserMutation } from '@/identity/api';
import { User as UserType } from '@/identity/models';
import {
    IconAt,
    IconDots,
    IconInfoCircle,
    IconPencil,
    IconShieldLock,
    IconSquareX,
    IconAlertCircle,
} from '@tabler/icons';
import { useAllowsActionOnResource } from '@/identity';
import * as $P from '@/identity/policy';
import LabeledText from 'src/common/components/LabeledText';
import { Link } from 'react-router-dom';
import UserGroups from '../UserGroups';
import UserPolicy from '../UserPolicy';
import { useDisclosure } from '@mantine/hooks';
import { getErrorMessage } from '@/errors';
import { useState } from 'react';

export interface UserProps {}

export const User = (props: UserProps) => {
    const theme = useMantineTheme();
    const allowsActionOnResource = useAllowsActionOnResource();
    const { userId, tab } = useParams();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);
    const [deleteUser, { isSuccess }] = useDeleteUserMutation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const handleDelete = async () => {
        setErrorMessage(null);
        try {
            await deleteUser({ id: user.id }).unwrap();
            navigate(-1);
        } catch (err) {
            setErrorMessage(getErrorMessage(err));
        }
    };

    const { data: { user = {} as UserType } = {}, isLoading, isError } = useGetUserQuery({ id: userId! });

    function onTabChange(value: string) {
        navigate(`./../${value}`, { replace: true });
    }

    // goto default tab
    if (!tab) {
        return <Navigate to={'groups'} replace />;
    }

    return (
        <View
            header={
                <Group position="apart" sx={{ minHeight: 36 }}>
                    <Title order={3}>{user.name ?? t('Unknown')}</Title>
                    <Group position="right">
                        <Menu position="left-start" shadow="md" width={200}>
                            <Menu.Target>
                                <Button variant="light" color="red" px="xs">
                                    <IconDots />
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Label>{t('Actions')}</Menu.Label>
                                {allowsActionOnResource($P.CanManageUser, $P.UserResourceFmt(userId!)) ? (
                                    <>
                                        <Menu.Item
                                            component={Link}
                                            icon={<IconPencil size={14} />}
                                            state={{ backgroundLocation: location }}
                                            to="./../edit"
                                        >
                                            {t('Edit')}
                                        </Menu.Item>
                                        <Menu.Item component="button" icon={<IconSquareX size={14} />} onClick={open}>
                                            {t('Delete')}
                                        </Menu.Item>
                                    </>
                                ) : null}
                                {allowsActionOnResource($P.CanAdministerUser, $P.UserResourceFmt(userId!)) ? (
                                    <Menu.Item
                                        component={Link}
                                        icon={<IconShieldLock size={14} />}
                                        state={{ backgroundLocation: location }}
                                        to="./../policy/set"
                                    >
                                        {t('Set Policy')}
                                    </Menu.Item>
                                ) : null}
                            </Menu.Dropdown>
                        </Menu>
                        {allowsActionOnResource($P.CanManageUser, $P.UserResourceFmt(userId!)) ? (
                            <Modal
                                opened={opened}
                                onClose={close}
                                title={
                                    <>
                                        Are you sure?
                                        <br />
                                        User {user.name} will be permanently deleted.
                                    </>
                                }
                                centered
                                withCloseButton={false}
                            >
                                {errorMessage ? (
                                    <Alert mt="md" icon={<IconAlertCircle />} title="Bummer!" color="red">
                                        {errorMessage}
                                    </Alert>
                                ) : null}
                                <Group position="right" mt={'md'}>
                                    <Button
                                        color={'gray'}
                                        onClick={() => {
                                            setErrorMessage(null);
                                            close();
                                        }}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color={'red'}
                                        loading={isLoading}
                                        leftIcon={<IconSquareX />}
                                        onClick={handleDelete}
                                    >
                                        {t('Delete')}
                                    </Button>
                                </Group>
                            </Modal>
                        ) : null}
                    </Group>
                </Group>
            }
        >
            <Card.Section sx={{ minHeight: 200 }}>
                <Group position="left" m="md" align="flex-start">
                    <Stack spacing="xs">
                        <Group position="left">
                            <Center>
                                <IconAt />
                            </Center>
                            <LabeledText label={t('Email')}>{user.emailAddress ?? t('unknown@unknown')}</LabeledText>
                        </Group>
                    </Stack>
                </Group>
            </Card.Section>
            <Card.Section
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                <Tabs
                    defaultValue="connections"
                    value={tab}
                    onTabChange={onTabChange}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}
                >
                    <Tabs.List>
                        <Tabs.Tab value="groups" icon={<IconInfoCircle size={16} />}>
                            {t('Groups')}
                        </Tabs.Tab>
                        <Tabs.Tab value="policy" icon={<IconShieldLock size={16} />}>
                            {t('Policy')}
                        </Tabs.Tab>
                        <Group position="right" sx={{ flexGrow: 1 }}></Group>
                    </Tabs.List>
                    <Tabs.Panel value="groups" sx={{ height: '100%' }}>
                        <UserGroups user={user} userId={userId!} />
                    </Tabs.Panel>
                    <Tabs.Panel value="policy" sx={{ height: '100%' }}>
                        <UserPolicy user={user} userId={userId!} />
                    </Tabs.Panel>
                </Tabs>
            </Card.Section>
        </View>
    );
};

export default User;
