import { Box, createStyles, DefaultProps, Selectors, useComponentDefaultProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export interface LogoStyleParams {}

// function getTextColor({ theme, color, variant }: GetTextColor) {
//     if (color === "dimmed") {
//         return theme.colorScheme === "dark"
//             ? theme.colors.dark[2]
//             : theme.colors.gray[6];
//     }

//     return color in theme.colors
//         ? theme.fn.variant({ variant: "filled", color }).background
//         : variant === "link"
//         ? theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 7]
//         : color || "inherit";
// }

// .cls-1{fill:#fff;}.cls-2{fill:#15ad4b;}
const useStyles = createStyles((theme, params: LogoStyleParams, getRef) => {
    return {
        root: {},
        text: {
            fill: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
        logo: {
            fill: '#15ad4b',
        },
    };
});

type LogoStyleNames = Selectors<typeof useStyles>;

export interface LogoProps extends DefaultProps<LogoStyleNames, LogoStyleParams> {}

const componentName = 'CLUILogo';
const defaultProps: Partial<LogoProps> = {};

export const Logo = (props: LogoProps) => {
    const { className, classNames, styles, unstyled, ...rest } = useComponentDefaultProps(
        componentName,
        defaultProps,
        props,
    );
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    return (
        <Box
            component="svg"
            className={cx(classes.root, className)}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1081 116"
            {...rest}
        >
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 1081.00 116.00">
                <circle fill="#00b450" cx="1028.66" cy="22.07" r="20.08" />
                <path
                    fill="#00b450"
                    d="
  M 1001.73 55.25
  L 1001.72 50.00
  Q 1001.72 49.28 1001.00 49.28
  L 979.00 49.28
  Q 978.53 49.28 978.62 48.81
  C 982.53 30.23 1006.95 26.34 1015.93 43.11
  C 1020.58 51.80 1017.94 63.30 1010.01 68.81
  C 997.59 77.44 982.60 71.27 978.48 56.65
  A 0.53 0.53 0.0 0 1 978.99 55.98
  L 1001.00 55.97
  Q 1001.73 55.97 1001.73 55.25
  Z"
                />
                <circle fill="#00b450" cx="1059.02" cy="52.60" r="20.08" />
                <path
                    fill="#00b450"
                    d="
  M 45.75 62.68
  C 39.40 55.53 28.05 55.70 22.50 62.73
  Q 18.44 67.88 19.05 74.95
  C 20.23 88.64 36.96 93.17 45.93 83.23
  Q 46.30 82.81 46.71 83.20
  L 52.98 89.13
  Q 53.51 89.63 52.98 90.14
  Q 45.35 97.42 35.63 98.04
  C 22.59 98.88 10.37 90.87 8.38 77.40
  C 5.77 59.68 18.36 46.96 36.52 48.11
  Q 46.50 48.74 53.06 56.65
  Q 53.34 56.98 53.01 57.27
  L 46.73 62.74
  A 0.69 0.69 0.0 0 1 45.75 62.68
  Z"
                />
                <path
                    fill="#00b450"
                    d="
  M 150.17 73.00
  A 26.17 25.21 0.0 0 1 124.00 98.21
  A 26.17 25.21 0.0 0 1 97.83 73.00
  A 26.17 25.21 0.0 0 1 124.00 47.79
  A 26.17 25.21 0.0 0 1 150.17 73.00
  Z
  M 124.0274 88.7200
  A 15.72 15.16 89.9 0 0 139.1600 72.9735
  A 15.72 15.16 89.9 0 0 123.9726 57.2800
  A 15.72 15.16 89.9 0 0 108.8400 73.0265
  A 15.72 15.16 89.9 0 0 124.0274 88.7200
  Z"
                />
                <path
                    className={cx(classes.text)}
                    fill="#231f20"
                    d="
  M 802.8099 70.8770
  A 27.82 24.73 -5.1 0 1 777.2984 97.9821
  A 27.82 24.73 -5.1 0 1 747.3901 75.8230
  A 27.82 24.73 -5.1 0 1 772.9016 48.7179
  A 27.82 24.73 -5.1 0 1 802.8099 70.8770
  Z
  M 793.96 73.36
  A 18.85 17.66 0.0 0 0 775.11 55.70
  A 18.85 17.66 0.0 0 0 756.26 73.36
  A 18.85 17.66 0.0 0 0 775.11 91.02
  A 18.85 17.66 0.0 0 0 793.96 73.36
  Z"
                />
                <path
                    fill="#00b450"
                    d="
  M 72.52 88.00
  L 95.47 88.00
  A 0.53 0.53 0.0 0 1 96.00 88.53
  L 96.00 96.47
  A 0.53 0.53 0.0 0 1 95.47 97.00
  L 61.53 97.00
  A 0.53 0.53 0.0 0 1 61.00 96.47
  L 61.01 49.53
  A 0.53 0.53 0.0 0 1 61.54 49.00
  L 71.47 49.01
  A 0.53 0.53 0.0 0 1 72.00 49.54
  L 71.99 87.47
  A 0.53 0.53 0.0 0 0 72.52 88.00
  Z"
                />
                <path
                    fill="#00b450"
                    d="
  M 191.46 49.00
  L 201.73 49.00
  A 0.31 0.31 0.0 0 1 202.01 49.43
  L 181.60 96.81
  A 0.31 0.31 0.0 0 1 181.32 97.00
  L 170.84 97.00
  A 0.31 0.31 0.0 0 1 170.56 96.81
  L 150.25 49.44
  A 0.31 0.31 0.0 0 1 150.53 49.01
  L 161.74 49.00
  A 0.31 0.31 0.0 0 1 162.03 49.19
  L 176.21 82.66
  A 0.31 0.31 0.0 0 0 176.78 82.66
  L 191.18 49.19
  A 0.31 0.31 0.0 0 1 191.46 49.00
  Z"
                />
                <path
                    fill="#00b450"
                    d="
  M 218.00 77.39
  L 218.00 87.61
  A 0.39 0.39 0.0 0 0 218.39 88.00
  L 243.61 88.00
  A 0.39 0.39 0.0 0 1 244.00 88.39
  L 244.00 96.61
  A 0.39 0.39 0.0 0 1 243.61 97.00
  L 207.39 97.00
  A 0.39 0.39 0.0 0 1 207.00 96.61
  L 207.00 49.39
  A 0.39 0.39 0.0 0 1 207.39 49.00
  L 242.61 49.00
  A 0.39 0.39 0.0 0 1 243.00 49.39
  L 242.99 57.61
  A 0.39 0.39 0.0 0 1 242.60 58.00
  L 218.39 58.00
  A 0.39 0.39 0.0 0 0 218.00 58.39
  L 218.00 67.87
  A 0.39 0.39 0.0 0 0 218.39 68.26
  L 239.86 68.26
  A 0.39 0.39 0.0 0 1 240.25 68.65
  L 240.26 76.61
  A 0.39 0.39 0.0 0 1 239.87 77.00
  L 218.39 77.00
  A 0.39 0.39 0.0 0 0 218.00 77.39
  Z"
                />
                <path
                    fill="#00b450"
                    d="
  M 264.00 84.25
  L 264.00 96.50
  Q 264.00 97.00 263.50 97.00
  L 253.62 96.99
  Q 253.00 96.99 253.00 96.37
  L 253.01 49.76
  Q 253.01 49.17 253.60 49.14
  Q 262.94 48.70 272.10 49.08
  Q 281.71 49.47 285.94 51.79
  Q 293.11 55.71 293.98 64.96
  Q 295.03 76.09 284.89 81.40
  Q 284.33 81.69 284.69 82.22
  L 294.50 96.50
  Q 294.84 97.00 294.24 97.00
  L 283.82 97.00
  Q 283.24 97.00 282.91 96.52
  L 274.40 84.18
  A 1.02 1.01 -16.9 0 0 273.56 83.74
  L 264.50 83.74
  Q 264.00 83.74 264.00 84.25
  Z
  M 263.99 58.24
  L 263.99 74.78
  A 0.22 0.22 0.0 0 0 264.21 75.00
  L 274.39 75.00
  A 8.62 8.00 0.0 0 0 283.01 67.00
  L 283.01 66.02
  A 8.62 8.00 -0.0 0 0 274.39 58.02
  L 264.21 58.02
  A 0.22 0.22 0.0 0 0 263.99 58.24
  Z"
                />
                <path
                    fill="#00b450"
                    d="
  M 314.63 88.00
  L 337.37 88.00
  A 0.63 0.63 0.0 0 1 338.00 88.63
  L 338.00 96.37
  A 0.63 0.63 0.0 0 1 337.37 97.00
  L 303.63 97.00
  A 0.63 0.63 0.0 0 1 303.00 96.37
  L 303.01 49.63
  A 0.63 0.63 0.0 0 1 303.64 49.00
  L 313.37 49.00
  A 0.63 0.63 0.0 0 1 314.00 49.63
  L 314.00 87.37
  A 0.63 0.63 0.0 0 0 314.63 88.00
  Z"
                />
                <path
                    fill="#00b450"
                    d="
  M 355.00 58.31
  L 355.00 67.95
  A 0.31 0.31 0.0 0 0 355.31 68.26
  L 376.94 68.26
  A 0.31 0.31 0.0 0 1 377.25 68.57
  L 377.26 76.69
  A 0.31 0.31 0.0 0 1 376.95 77.00
  L 355.31 77.00
  A 0.31 0.31 0.0 0 0 355.00 77.31
  L 355.00 87.69
  A 0.31 0.31 0.0 0 0 355.31 88.00
  L 380.69 88.00
  A 0.31 0.31 0.0 0 1 381.00 88.31
  L 381.00 96.69
  A 0.31 0.31 0.0 0 1 380.69 97.00
  L 344.31 97.00
  A 0.31 0.31 0.0 0 1 344.00 96.69
  L 344.00 49.31
  A 0.31 0.31 0.0 0 1 344.31 49.00
  L 379.69 49.00
  A 0.31 0.31 0.0 0 1 380.00 49.31
  L 380.00 57.69
  A 0.31 0.31 0.0 0 1 379.69 58.00
  L 355.31 58.00
  A 0.31 0.31 0.0 0 0 355.00 58.31
  Z"
                />
                <path
                    fill="#00b450"
                    d="
  M 398.91 87.25
  L 394.92 96.75
  A 0.40 0.40 0.0 0 1 394.55 97.00
  L 384.14 97.00
  A 0.40 0.40 0.0 0 1 383.78 96.44
  L 404.68 49.24
  A 0.40 0.40 0.0 0 1 405.05 49.00
  L 415.39 49.00
  A 0.40 0.40 0.0 0 1 415.76 49.24
  L 436.73 96.44
  A 0.40 0.40 0.0 0 1 436.36 97.00
  L 425.67 97.00
  A 0.40 0.40 0.0 0 1 425.30 96.75
  L 421.34 87.25
  A 0.40 0.40 0.0 0 0 420.97 87.00
  L 399.28 87.00
  A 0.40 0.40 0.0 0 0 398.91 87.25
  Z
  M 402.77 78.06
  A 0.34 0.34 0.0 0 0 403.09 78.53
  L 417.21 78.53
  A 0.34 0.34 0.0 0 0 417.53 78.06
  L 410.46 60.74
  A 0.34 0.34 0.0 0 0 409.84 60.74
  L 402.77 78.06
  Z"
                />
                <path
                    fill="#00b450"
                    d="
  M 453.00 80.32
  L 453.00 96.68
  A 0.32 0.32 0.0 0 1 452.68 97.00
  L 442.32 97.00
  A 0.32 0.32 0.0 0 1 442.00 96.68
  L 442.00 49.32
  A 0.32 0.32 0.0 0 1 442.32 49.00
  L 477.68 49.00
  A 0.32 0.32 0.0 0 1 478.00 49.32
  L 478.00 57.68
  A 0.32 0.32 0.0 0 1 477.68 58.00
  L 453.32 58.00
  A 0.32 0.32 0.0 0 0 453.00 58.32
  L 453.00 70.68
  A 0.32 0.32 0.0 0 0 453.32 71.00
  L 474.68 71.00
  A 0.32 0.32 0.0 0 1 475.00 71.32
  L 475.00 79.68
  A 0.32 0.32 0.0 0 1 474.68 80.00
  L 453.32 80.00
  A 0.32 0.32 0.0 0 0 453.00 80.32
  Z"
                />
                <path
                    className={cx(classes.text)}
                    fill="#231f20"
                    d="
  M 548.46 49.38
  L 556.45 49.37
  A 0.34 0.34 0.0 0 1 556.79 49.71
  L 556.79 97.03
  A 0.34 0.34 0.0 0 1 556.45 97.37
  L 549.60 97.38
  A 0.34 0.34 0.0 0 1 549.36 97.29
  L 511.61 61.46
  A 0.34 0.34 0.0 0 0 511.04 61.71
  L 511.04 97.04
  A 0.34 0.34 0.0 0 1 510.70 97.38
  L 502.71 97.37
  A 0.34 0.34 0.0 0 1 502.37 97.03
  L 502.37 49.72
  A 0.34 0.34 0.0 0 1 502.71 49.38
  L 509.60 49.36
  A 0.34 0.34 0.0 0 1 509.84 49.45
  L 547.56 85.25
  A 0.34 0.34 0.0 0 0 548.13 85.00
  L 548.12 49.72
  A 0.34 0.34 0.0 0 1 548.46 49.38
  Z"
                />
                <path
                    className={cx(classes.text)}
                    fill="#231f20"
                    d="
  M 575.46 77.14
  L 575.46 90.11
  A 0.35 0.35 0.0 0 0 575.81 90.46
  L 613.40 90.46
  A 0.35 0.35 0.0 0 1 613.75 90.81
  L 613.76 97.02
  A 0.35 0.35 0.0 0 1 613.41 97.37
  L 567.10 97.37
  A 0.35 0.35 0.0 0 1 566.75 97.02
  L 566.75 49.72
  A 0.35 0.35 0.0 0 1 567.10 49.37
  L 613.40 49.37
  A 0.35 0.35 0.0 0 1 613.75 49.72
  L 613.76 55.90
  A 0.35 0.35 0.0 0 1 613.41 56.25
  L 575.81 56.25
  A 0.35 0.35 0.0 0 0 575.46 56.60
  L 575.45 69.53
  A 0.35 0.35 0.0 0 0 575.80 69.88
  L 612.77 69.88
  A 0.35 0.35 0.0 0 1 613.12 70.23
  L 613.12 76.44
  A 0.35 0.35 0.0 0 1 612.77 76.79
  L 575.81 76.79
  A 0.35 0.35 0.0 0 0 575.46 77.14
  Z"
                />
                <path
                    className={cx(classes.text)}
                    fill="#231f20"
                    d="
  M 648.21 56.59
  L 648.21 97.03
  A 0.34 0.34 0.0 0 1 647.87 97.37
  L 639.96 97.38
  A 0.34 0.34 0.0 0 1 639.62 97.04
  L 639.62 56.59
  A 0.34 0.34 0.0 0 0 639.28 56.25
  L 620.30 56.25
  A 0.34 0.34 0.0 0 1 619.96 55.91
  L 619.96 49.71
  A 0.34 0.34 0.0 0 1 620.30 49.37
  L 667.45 49.37
  A 0.34 0.34 0.0 0 1 667.79 49.71
  L 667.80 55.91
  A 0.34 0.34 0.0 0 1 667.46 56.25
  L 648.55 56.25
  A 0.34 0.34 0.0 0 0 648.21 56.59
  Z"
                />
                <path
                    className={cx(classes.text)}
                    fill="#231f20"
                    d="
  M 698.00 87.22
  L 704.67 71.78
  A 0.31 0.31 0.0 0 0 704.66 71.52
  L 694.64 49.82
  A 0.31 0.31 0.0 0 1 694.92 49.38
  L 703.57 49.37
  A 0.31 0.31 0.0 0 1 703.85 49.56
  L 720.33 87.22
  A 0.31 0.31 0.0 0 0 720.90 87.23
  L 737.95 49.55
  A 0.31 0.31 0.0 0 1 738.23 49.37
  L 746.72 49.37
  A 0.31 0.31 0.0 0 1 747.00 49.81
  L 724.38 97.19
  A 0.31 0.31 0.0 0 1 724.10 97.37
  L 716.83 97.38
  A 0.31 0.31 0.0 0 1 716.55 97.20
  L 709.45 81.70
  A 0.31 0.31 0.0 0 0 708.88 81.70
  L 701.50 97.19
  A 0.31 0.31 0.0 0 1 701.22 97.37
  L 693.95 97.38
  A 0.31 0.31 0.0 0 1 693.67 97.20
  L 671.04 49.82
  A 0.31 0.31 0.0 0 1 671.32 49.38
  L 679.96 49.37
  A 0.31 0.31 0.0 0 1 680.24 49.55
  L 697.43 87.22
  A 0.31 0.31 0.0 0 0 698.00 87.22
  Z"
                />
                <path
                    className={cx(classes.text)}
                    fill="#231f20"
                    d="
  M 820.25 77.27
  L 820.25 96.81
  Q 820.25 97.37 819.69 97.37
  L 812.15 97.37
  Q 811.50 97.37 811.50 96.73
  L 811.50 50.00
  A 0.57 0.57 0.0 0 1 812.06 49.43
  Q 826.49 49.22 840.86 49.44
  Q 847.55 49.54 850.73 51.07
  C 861.84 56.40 860.32 71.58 849.05 75.81
  Q 848.54 76.01 848.77 76.50
  L 858.10 96.75
  Q 858.38 97.36 857.71 97.37
  L 849.96 97.38
  Q 849.36 97.38 849.11 96.84
  L 840.02 77.27
  Q 839.80 76.79 839.27 76.79
  L 820.73 76.79
  Q 820.25 76.79 820.25 77.27
  Z
  M 820.27 56.73
  L 820.23 69.35
  A 0.50 0.50 0.0 0 0 820.73 69.85
  L 842.37 69.93
  A 7.20 6.58 0.2 0 0 849.59 63.37
  L 849.59 62.91
  A 7.20 6.58 0.2 0 0 842.41 56.31
  L 820.77 56.23
  A 0.50 0.50 0.0 0 0 820.27 56.73
  Z"
                />
                <path
                    className={cx(classes.text)}
                    fill="#231f20"
                    d="
  M 875.56 80.71
  L 909.68 49.44
  A 0.31 0.31 0.0 0 1 909.89 49.36
  L 919.79 49.39
  A 0.31 0.31 0.0 0 1 920.00 49.93
  L 904.88 63.87
  A 0.31 0.31 0.0 0 0 904.81 64.24
  L 920.65 96.93
  A 0.31 0.31 0.0 0 1 920.38 97.37
  L 911.44 97.38
  A 0.31 0.31 0.0 0 1 911.17 97.20
  L 898.23 70.38
  A 0.31 0.31 0.0 0 0 897.74 70.29
  L 875.14 91.23
  A 0.31 0.31 0.0 0 0 875.04 91.46
  L 875.04 97.06
  A 0.31 0.31 0.0 0 1 874.73 97.37
  L 866.68 97.37
  A 0.31 0.31 0.0 0 1 866.37 97.06
  L 866.37 49.69
  A 0.31 0.31 0.0 0 1 866.68 49.38
  L 874.73 49.37
  A 0.31 0.31 0.0 0 1 875.04 49.68
  L 875.04 80.49
  A 0.31 0.31 0.0 0 0 875.56 80.71
  Z"
                />
                <path
                    className={cx(classes.text)}
                    fill="#231f20"
                    d="
  M 939.76 56.28
  C 937.01 56.29 934.23 58.76 933.55 61.26
  C 931.90 67.31 936.94 69.87 942.02 69.85
  C 948.48 69.83 958.51 69.00 963.90 71.09
  C 972.05 74.24 974.73 84.22 969.68 91.42
  Q 965.46 97.44 956.75 97.42
  Q 941.52 97.39 926.30 97.36
  Q 925.63 97.36 925.63 96.69
  L 925.62 91.02
  Q 925.62 90.46 926.18 90.46
  Q 940.98 90.45 955.73 90.47
  C 966.22 90.49 966.42 76.96 956.25 76.90
  Q 949.32 76.86 942.40 76.76
  Q 935.63 76.67 932.79 75.49
  C 925.10 72.28 922.47 62.84 927.00 55.73
  Q 930.83 49.71 939.25 49.57
  Q 953.17 49.35 967.10 49.36
  Q 967.75 49.36 967.75 50.01
  L 967.76 55.74
  Q 967.76 56.25 967.24 56.25
  Q 953.49 56.24 939.76 56.28
  Z"
                />
                <circle fill="#00b450" cx="1028.66" cy="83.12" r="20.08" />
                <path
                    fill="#231f20"
                    d="
  M 975.82 92.66
  L 974.42 92.25
  A 0.32 0.32 0.0 0 1 974.50 91.63
  L 978.76 91.50
  A 0.43 0.43 0.0 0 1 978.96 92.31
  L 977.91 92.81
  Q 977.56 92.98 977.56 93.37
  L 977.52 97.19
  A 0.55 0.54 44.8 0 1 976.43 97.19
  L 976.35 93.35
  Q 976.34 92.81 975.82 92.66
  Z"
                />
                <path
                    className={cx(classes.text)}
                    fill="#231f20"
                    d="
  M 981.11 94.29
  L 980.69 97.11
  A 0.39 0.38 -85.6 0 1 980.31 97.44
  Q 980.15 97.43 979.99 97.44
  Q 979.71 97.44 979.72 97.16
  L 979.90 92.07
  Q 979.91 91.64 980.34 91.59
  L 981.20 91.48
  A 0.73 0.73 0.0 0 1 982.01 92.09
  Q 982.28 93.87 983.69 94.83
  Q 984.25 95.22 984.05 95.88
  L 983.66 97.12
  A 0.65 0.65 0.0 0 1 982.41 97.10
  L 981.59 94.25
  A 0.25 0.25 0.0 0 0 981.11 94.29
  Z"
                />
                <path
                    className={cx(classes.text)}
                    fill="#231f20"
                    d="
  M 984.77 94.71
  Q 984.62 94.79 984.48 94.86
  A 0.46 0.46 0.0 0 1 983.82 94.50
  Q 983.58 92.46 985.14 91.45
  A 0.72 0.71 -18.0 0 1 986.24 92.01
  L 986.57 97.21
  A 0.48 0.47 37.0 0 1 985.63 97.34
  L 985.07 94.86
  A 0.22 0.21 70.7 0 0 984.77 94.71
  Z"
                />
            </svg>
        </Box>
    );
};

export default Logo;
