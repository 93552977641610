import Drawer, { DrawerContext, DrawerProps } from '@/common/components/Drawer';
import { Text, useComponentDefaultProps } from '@mantine/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useLazyGetLocationQuery } from 'src/management/api';
import { Location } from 'src/management/models';
import CreateConnectionForm from './CreateConnectionForm';

export interface CreateConnectionProps extends DrawerProps {}

const componentName = 'CLUICreateConnection';
const defaultProps: Partial<CreateConnectionProps> = {
    size: '50vw',
    padding: 'md',
    position: 'right',
    closeOnClickOutside: false,
    overlayOpacity: 0.0,
    shadow: 'lg',
};

export const CreateConnection = (props: CreateConnectionProps) => {
    const { onClose, ...rest } = useComponentDefaultProps(componentName, defaultProps, props);
    const { locationId } = useParams();
    const { t, i18n } = useTranslation();
    const [getLocation, { data: { location = {} as Location } = {} }] = useLazyGetLocationQuery();
    useEffect(() => {
        if (locationId) {
            getLocation({ id: locationId });
        }
    }, [locationId]);
    return (
        <Drawer
            onClose={onClose}
            {...rest}
            shadow="lg"
            title={
                location ? (
                    <Text weight="bold">
                        {t('Create Connection For')}: {location?.name ?? t('Unknown')}
                    </Text>
                ) : (
                    <Text weight="bold">{t('Create Location')}</Text>
                )
            }
        >
            <DrawerContext.Consumer>
                {({ onClose }) => (
                    <CreateConnectionForm location={location} locationId={locationId} onClose={onClose} />
                )}
            </DrawerContext.Consumer>
        </Drawer>
    );
};

export default CreateConnection;
