import {
    Box,
    createStyles,
    DefaultProps,
    Group,
    NumberInput,
    Selectors,
    useComponentDefaultProps,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useControlledForm } from 'src/common/hooks';
import { LatLng } from 'src/common/models';

export interface LocationInputStyleParams {}

const useStyles = createStyles((theme, params: LocationInputStyleParams, getRef) => {
    return {
        root: {},
    };
});

type LocationInputStyleNames = Selectors<typeof useStyles>;

export interface LocationInputProps extends DefaultProps<LocationInputStyleNames, LocationInputStyleParams> {
    value?: LatLng;
    onChange?: (value: LatLng) => void;
}

const componentName = 'CLUILocationInput';
const defaultProps: Partial<LocationInputProps> = {};
const defaultValues: Partial<LatLng> = {
    lat: 0,
    lng: 0,
};

export const LocationInput = (props: LocationInputProps) => {
    const {
        className,
        classNames,
        styles,
        unstyled,
        value = {},
        onChange,
        ...rest
    } = useComponentDefaultProps(componentName, defaultProps, props);
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    const form = useControlledForm({
        defaultValues,
        value: value,
        onChange,
    });
    return (
        <Box className={cx(classes.root, className)} {...rest}>
            {/* <Text size="sm">{t("Location")}</Text> */}
            <Group position="apart" grow sx={{ flexWrap: 'nowrap' }}>
                <NumberInput
                    label={t('Latitude')}
                    placeholder={t('')}
                    precision={2}
                    step={0.05}
                    min={-90}
                    max={90}
                    {...form.getInputProps('lat')}
                />
                <NumberInput
                    label={t('Longitude')}
                    placeholder={t('')}
                    precision={2}
                    step={0.05}
                    min={-180}
                    max={180}
                    {...form.getInputProps('lng')}
                />
            </Group>
        </Box>
    );
};

export default LocationInput;
