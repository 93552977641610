import { Box, Center, Group, ScrollArea, Space, Stack, Text, useMantineTheme } from '@mantine/core';
import {
    ConnectionDetails as ConnectionDetailsType,
    GenericConnectionDetails as GenericConnectionDetailsType,
} from '@/management/models';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import LabeledText from 'src/common/components/LabeledText';
import { useAllowsActionOnResource } from '@/identity';
import * as $P from '@/identity/policy';
import { useParams } from 'react-router';

export interface ConnectionDetailsProps {
    details?: ConnectionDetailsType;
}

export const ConnectionDetails = ({ details, ...rest }: ConnectionDetailsProps) => {
    return (
        <Stack spacing={0} align="stretch" justify="flex-start" sx={{ height: '100%' }} {...rest}>
            {details?.generic ? <GenericConnectionDetails generic={details.generic} /> : null}
        </Stack>
    );
};

export interface GenericConnectionDetailsProps {
    generic?: GenericConnectionDetailsType;
}

export const GenericConnectionDetails = ({ generic, ...rest }: GenericConnectionDetailsProps) => {
    const { t, i18n } = useTranslation();
    const theme = useMantineTheme();
    const allowsActionOnResource = useAllowsActionOnResource();
    const { connectionId, tab } = useParams();
    return (
        <Box px="md" sx={{ flexGrow: 1, position: 'relative' }}>
            <ScrollArea
                type="hover"
                sx={{
                    position: 'absolute !important' as 'absolute', // TODO: total hack for !important?
                    top: 0,
                    bottom: 0,
                    width: `calc(100% - ${theme.spacing.md * 2}px)`,
                }}
            >
                <Data label={t('Underlying Carrier')} value={generic?.carrier} />
                <Data label={t('Carrier Connection ID')} value={generic?.carrierConnectionId} />
                <Data label={t('Bandwidth')} value={generic?.bandwidth} />
                <Data label={t('Subnet Mask')} value={generic?.subnetMask} />
                <Data label={t('Gateway')} value={generic?.gateway} />
                <Data label={t('First Usable IP')} value={generic?.firstUsableIp} />
                <Data label={t('Last Usable IP')} value={generic?.lastUsableIp} />
                <Data label={t('DNS 1')} value={generic?.dns1} />
                <Data label={t('DNS 2')} value={generic?.dns2} />
                <Data label={t('Medium')} value={generic?.medium} />
                <Data label={t('Modem Make')} value={generic?.modemMake} />
                <Data label={t('Modem Model')} value={generic?.modemModel} />
                <Data label={t('Modem MAC Address')} value={generic?.modemMacAddress} />
                {allowsActionOnResource($P.CanManageConnection, $P.ConnectionResourceFmt(connectionId!)) ? (
                    <Data label={t('Hostname')} value={generic?.hostname} />
                ) : null}
                <Space h="lg" />
            </ScrollArea>
        </Box>
    );
};

interface DataProps {
    icon?: ReactNode;
    label?: string;
    value?: string;
}

const Data = ({ icon, label, value }: DataProps) => {
    const { t, i18n } = useTranslation();
    return (
        <Group position="left" mt="sm">
            {icon ? <Center>{icon}</Center> : null}
            <LabeledText label={label!}>{value ? <Text>{value}</Text> : <Text color="dimmed">-</Text>}</LabeledText>
        </Group>
    );
};
