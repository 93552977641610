export type GrpcGatewayError = {
    status: number;
    data: {
        code: number;
        message: string;
        details: any[];
    };
};

export type KnownErrorTypes = GrpcGatewayError | Error;

// TODO: work on this method signature
export const getErrorMessage = (error: KnownErrorTypes | any): string => {
    if (error instanceof Error) {
        return error.message;
    } else if (typeof error.status === 'number') {
        return error.data.message;
    } else {
        return 'Unknown error.';
    }
};
