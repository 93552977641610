import { makeAuthorization } from '@/api';
import { useAppDispatch } from '@/app/store';
import {
    selectHasPreviousSession,
    selectNonInteractiveLoginAttempted,
    setAuthorization,
    setNonInteractiveLoginAttempted,
    useLoginMutation,
} from '@/identity';
import { forDesktopUp } from '@/styles/mixins';
import { useTypedLocation } from '@/utils';
import {
    Anchor,
    Box,
    Button,
    Center,
    Checkbox,
    createStyles,
    Group,
    Paper,
    Stack,
    TextInput,
    Title,
    useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconLogin } from '@tabler/icons';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Location, Navigate, useNavigate } from 'react-router-dom';
import Logo from 'src/common/components/Logo';
import CLOEClover from '../../../../public/icon.png';
import './Login.css';

export interface LoginProps {}

const useStyles = createStyles((theme, params, getRef) => {
    return {};
});

type LoginValues = {
    emailAddress: string;
    password: string;
    rememberMe: boolean;
};

// TODO: have the login page do the non-interactive login as well

export const Login = (props: LoginProps) => {
    const { classes, cx } = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const location = useTypedLocation<{ from: Location }>();
    const from = location.state?.from?.pathname || `/`;
    const [login, { isLoading, isSuccess, isError, isUninitialized }] = useLoginMutation();
    const dispatch = useAppDispatch();
    const hasPreviousSession = useSelector(selectHasPreviousSession);
    const nonInteractiveLoginAttempted = useSelector(selectNonInteractiveLoginAttempted);
    const form = useForm<LoginValues>({
        initialValues: {
            emailAddress: '',
            password: '',
            rememberMe: true,
        },
        validate: {
            emailAddress: (value) => (value.length < 1 ? t('Email address is required') : null),
            password: (value) => (value.length < 1 ? t('Password is required') : null),
        },
    });
    const onSubmit = useCallback(
        ({ emailAddress, password, rememberMe }: LoginValues) => {
            dispatch(setAuthorization(makeAuthorization({ username: emailAddress, password })));
            login({ rememberMe });
        },
        [dispatch, login],
    );

    if (isSuccess) {
        return <Navigate to={from} replace />;
    }

    if (hasPreviousSession) {
        if (isUninitialized) {
            dispatch(setNonInteractiveLoginAttempted(true));
            login({});
        }
        if (isUninitialized || (nonInteractiveLoginAttempted && isLoading)) {
            return (
                <Center style={{ minHeight: '100vh', minWidth: '100vw' }}>
                    <Box>
                        <img
                            src={CLOEClover}
                            alt="loading symbol"
                            className="my-img"
                            style={{ width: '150px', height: 'auto' }}
                        />
                    </Box>
                </Center>
            );
        }
    }

    return (
        <Center sx={{ width: '100%' }}>
            <Stack align="center" justify="center">
                <Logo mb="50px" sx={{ width: 400 }} />
                <Paper
                    withBorder
                    shadow={'md'}
                    p={'md'}
                    sx={{
                        width: '400px',
                        ...forDesktopUp(theme, {
                            width: '400px',
                        }),
                    }}
                >
                    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
                        <Title order={3}>
                            {t(`Login to`)} {`CLOE`}
                        </Title>
                        <TextInput
                            mt="md"
                            label={t(`Username`)}
                            placeholder={t(`username`)}
                            disabled={isLoading}
                            required
                            {...form.getInputProps('emailAddress')}
                        />
                        <TextInput
                            mt="md"
                            label={t(`Password`)}
                            placeholder={t(`password`)}
                            type="password"
                            disabled={isLoading}
                            required
                            {...form.getInputProps('password')}
                        />
                        <Group
                            position="right"
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            <Checkbox
                                mt="md"
                                label={t(`Remember me`)}
                                {...form.getInputProps('rememberMe', {
                                    type: 'checkbox',
                                })}
                                style={{ paddingBottom: '1rem' }}
                            />
                            <Anchor component={Link} to={`/password_reset`}>
                                {t('Forgot Password')}
                            </Anchor>
                        </Group>
                        <Group mt="sm" position="right">
                            <Button type="submit" color={'primary'} loading={isLoading} leftIcon={<IconLogin />}>
                                {t('Login')}
                            </Button>
                        </Group>
                    </form>
                </Paper>
            </Stack>
        </Center>
    );
};

export default Login;
