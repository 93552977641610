import { useMemo, useState, useCallback, useEffect } from 'react';
import md5 from 'md5';
import { useSelector } from 'react-redux';
import { selectDeveloperFeatures, selectFeatures, selectWhoAmI } from './selectors';
import { allowsActionOnResource, allowsFor } from './policy';
import { TransferListData, TransferListItem } from '@mantine/core';
import { useGetGroupsQuery, useGetUserGroupsQuery } from './api';

export const useGravatar = (email: string) => {
    return useMemo(() => {
        return `https://www.gravatar.com/avatar/${md5(email)}`;
    }, [email]);
};

export const useAllowsActionOnResource = () => {
    const whoAmI = useSelector(selectWhoAmI);
    return useMemo(() => {
        return (act: string, obj: string) => {
            if (!whoAmI) {
                return false;
            }
            const [allow] = allowsActionOnResource(whoAmI.policy, act, obj);
            return allow;
        };
    }, [whoAmI]);
};

export const useAllowsFor = () => {
    const whoAmI = useSelector(selectWhoAmI);
    return useMemo(() => {
        return (...reqs: string[][]) => {
            if (!whoAmI) {
                return false;
            }
            const [allow] = allowsFor(whoAmI.policy, ...reqs);
            return allow;
        };
    }, [whoAmI]);
};

export const useFeatureCheck = () => {
    const features = useSelector(selectFeatures);
    const developerFeatures = useSelector(selectDeveloperFeatures);
    return useMemo(() => {
        const featureMap = [...features, ...developerFeatures].reduce(
            (acc, f) => ((acc[f.toLowerCase()] = true), acc),
            {} as Record<string, boolean>,
        );
        return (feature: string) => {
            return featureMap[feature.toLowerCase()] ?? false;
        };
    }, [features, developerFeatures]);
};

export const useFeatures = () => {
    const features = useSelector(selectFeatures);
    const developerFeatures = useSelector(selectDeveloperFeatures);
    return useMemo(() => {
        const featureMap = [...features, ...developerFeatures].reduce(
            (acc, f) => ((acc[f.toLowerCase()] = true), acc),
            {} as Record<string, boolean>,
        );
        return {
            hasSome(...features: string[]) {
                return features.some((f) => featureMap[f.toLowerCase()] ?? false);
            },
            hasEvery(...features: string[]) {
                return features.every((f) => featureMap[f.toLowerCase()] ?? false);
            },
        };
    }, [features, developerFeatures]);
};

export const useGroupTransferListData = (): [TransferListData, (data: TransferListData) => void] => {
    const [availableGroups, setAvailableGroups] = useState<TransferListItem[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<TransferListItem[]>([]);
    const setGroupData = useCallback(
        (data: TransferListData) => {
            setAvailableGroups(data[0]);
            setSelectedGroups(data[1]);
        },
        [setAvailableGroups, setSelectedGroups],
    );
    const { data: { groups: allGroups = [] } = {} } = useGetGroupsQuery({
        returnComplete: true,
    });
    useEffect(() => {
        setAvailableGroups(
            allGroups.map((group) => ({
                label: group.name,
                value: group.id,
            })),
        );
    }, [allGroups]);
    return [[availableGroups, selectedGroups], setGroupData];
};

export const useUserGroupsTransferListData = (userId: string): [TransferListData, (data: TransferListData) => void] => {
    const [availableGroups, setAvailableGroups] = useState<TransferListItem[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<TransferListItem[]>([]);
    const setGroupData = useCallback(
        (data: TransferListData) => {
            setAvailableGroups(data[0]);
            setSelectedGroups(data[1]);
        },
        [setAvailableGroups, setSelectedGroups],
    );
    const { data: { groups: allGroups = [] } = {} } = useGetGroupsQuery({
        returnComplete: true,
    });
    const { data: { groups: userGroups = [] } = {} } = useGetUserGroupsQuery({
        userId,
        returnComplete: true,
    });
    useEffect(() => {
        const userGroupIds = userGroups.map((group) => group.id);
        setAvailableGroups(
            allGroups
                .filter((group) => !userGroupIds.includes(group.id))
                .map((group) => ({
                    label: group.name,
                    value: group.id,
                })),
        );
        setSelectedGroups(
            userGroups.map((group) => ({
                label: group.name,
                value: group.id,
            })),
        );
    }, [allGroups, userGroups]);
    return [[availableGroups, selectedGroups], setGroupData];
};
