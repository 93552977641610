import { Continuation } from 'src/models';

/*
Ticket Status (6227640433563):
value,tag,default
New,new,true
Cloverleaf Response Requested,cloverleaf_response_requested,false
Contacting Carrier,contacting_carrier,false
Carrier Dispatched,carrier_dispatched,false
Customer Info Requested,customer_info_requested,false
Resolving-Leaving Ticket Open,resolving-leaving_ticket_open,false
Closed,closed,false
Escalated,escalated,false

Ticket Priority (6227650680219):
value,tag,default
P1 - Critical,p1_-_critical,false
P2 - High,p2_-_high,true
P3 - Medium,p3_-_medium,false
P4 - Low,p4_-_low,false
Not Set,not_set,false

Ticket Support Type (6227700911899)
value,tag,default
Service Down/Flapping,service_down/flapping,false
"Move, Add, Change, Delete",move__add__change__delete,false
Billing Related,billing_related,false
General Inquiry,general_inquiry,false
Feature Request,feature_request,false
*/

export const TicketStatusNew = 'new' as const;
export const TicketStatusCloverleafResponseRequested = 'cloverleaf_response_requested' as const;
export const TicketStatusContactingCarrier = 'contacting_carrier' as const;
export const TicketStatusCarrierDispatched = 'carrier_dispatched' as const;
export const TicketStatusCustomerInfoRequested = 'customer_info_requested' as const;
export const TicketStatusResolvingLeavingTicketOpen = 'resolving-leaving_ticket_open' as const;
export const TicketStatusClosed = 'closed' as const;
export const TicketStatusEscalated = 'escalated' as const;
export type TicketStatus =
    | typeof TicketStatusNew
    | typeof TicketStatusCloverleafResponseRequested
    | typeof TicketStatusContactingCarrier
    | typeof TicketStatusCarrierDispatched
    | typeof TicketStatusCustomerInfoRequested
    | typeof TicketStatusResolvingLeavingTicketOpen
    | typeof TicketStatusClosed
    | typeof TicketStatusEscalated;

export const TicketPriorityP1Critical = 'p1_-_critical' as const;
export const TicketPriorityP2High = 'p2_-_high' as const;
export const TicketPriorityP3Medium = 'p3_-_medium' as const;
export const TicketPriorityP4Low = 'p4_-_low' as const;
export const TicketPriorityNotSet = 'not_set' as const;
export type TicketPriority =
    | typeof TicketPriorityP1Critical
    | typeof TicketPriorityP2High
    | typeof TicketPriorityP3Medium
    | typeof TicketPriorityP4Low
    | typeof TicketPriorityNotSet;

export const TicketTypeServiceDownFlapping = 'service_down/flapping' as const;
export const TicketTypeMoveAddChangeDelete = 'move__add__change__delete' as const;
export const TicketTypeBillingRelated = 'billing_related' as const;
export const TicketTypeGeneralInquiry = 'general_inquiry' as const;
export const TicketTypeFeatureRequest = 'feature_request' as const;
export type TicketType =
    | typeof TicketTypeServiceDownFlapping
    | typeof TicketTypeMoveAddChangeDelete
    | typeof TicketTypeBillingRelated
    | typeof TicketTypeGeneralInquiry
    | typeof TicketTypeFeatureRequest;

export type Ticket = {
    id: number;
    subject: string;
    type: TicketType;
    status: TicketStatus;
    priority: TicketPriority;
    createdAt: string;
    updatedAt: string;
};

export type GetTicketsRequest = {
    order?: string;
    continue?: string;
    search?: string;
};

export type GetTicketsResponse = {
    tickets: Ticket[];
    continue: Continuation;
};

export type GetTicketRequest = {
    id: string;
};

export type GetTicketResponse = {
    ticket: Ticket;
};

export const ProjectStatusNewInQueue = '9678205' as const;
export const ProjectStatusNewOnHold = '16935212' as const;
export const ProjectStatusNewPMApproved = '85374108' as const;
export const ProjectStatusNewPMLongHold = '89503924' as const;
export const ProjectStatusNewWelcome = '1467768' as const;

export const ProjectStatusOnHold = '1467772' as const;
export const ProjectStatusContactingCarrier = '69498977' as const;
export const ProjectStatusCarrierOrdered = '1467774' as const;
export const ProjectStatusInstallDateReceived = '1467775' as const;
export const ProjectStatusProvisioningServices = '1467776' as const;
export const ProjectStatusInstalled = '1467777' as const;
export const ProjectStatusCancelled = '15242624' as const;
export const ProjectStatusCompany = 'COMPANY' as const;

export type ProjectStatus =
    | typeof ProjectStatusNewInQueue
    | typeof ProjectStatusNewOnHold
    | typeof ProjectStatusNewPMApproved
    | typeof ProjectStatusNewPMLongHold
    | typeof ProjectStatusNewWelcome
    | typeof ProjectStatusOnHold
    | typeof ProjectStatusContactingCarrier
    | typeof ProjectStatusCarrierOrdered
    | typeof ProjectStatusInstallDateReceived
    | typeof ProjectStatusProvisioningServices
    | typeof ProjectStatusInstalled
    | typeof ProjectStatusCancelled
    | typeof ProjectStatusCompany;

export const ProjectPriorityHigh = 'HIGH' as const;
export const ProjectPriorityMedium = 'MEDIUM' as const;
export const ProjectPriorityLow = 'LOW' as const;
export const ProjectPriorityNotSet = '' as const;
export type ProjectPriority =
    | typeof ProjectPriorityHigh
    | typeof ProjectPriorityMedium
    | typeof ProjectPriorityLow
    | typeof ProjectPriorityNotSet;

export type Project = {
    id: number;
    name: string;
    info: string;
    status: ProjectStatus;
    target: string;
    carrier: string;
    notes: string;
    companyName: string;
    pipeline: string;
    priority: ProjectPriority;
    cloeId: string;
    underlyingCarrier: string;
    downloadSpeed: string;
    uploadSpeed: string;
    defaultGateway: string;
    subnetMask: string;
    firstUsableStaticIp: string;
    lastUsableStaticIp: string;
    dns1: string;
    dns2: string;
};

export type GetProjectRequest = {
    id?: string;
};

export type GetProjectResponse = {
    project: Project;
};

export type GetProjectsRequest = {
    continue?: string;
    order?: string;
    search?: string;
    installed: boolean;
    inProgress: boolean;
    allCompanies?: string[];
    direction?: string;
};

export type GetProjectsResponse = {
    projects: Project[];
    continue: Continuation;
    next: string;
    allCompanies: string[];
};
