import { Badge, createStyles, DefaultProps, Selectors, useComponentDefaultProps } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ConnectionStatus,
    ConnectionStatusUnknown,
    ConnectionStatusDown,
    ConnectionStatusWarning,
    ConnectionStatusUp,
    ConnectionStatusPaused,
} from 'src/management/models';

export interface ConnectionStatusBadgeStyleParams {}

const useStyles = createStyles((theme, params: ConnectionStatusBadgeStyleParams, getRef) => {
    return {
        root: {},
    };
});

type ConnectionStatusBadgeStyleNames = Selectors<typeof useStyles>;

export interface ConnectionStatusBadgeProps
    extends DefaultProps<ConnectionStatusBadgeStyleNames, ConnectionStatusBadgeStyleParams> {
    status?: ConnectionStatus;
}

const componentName = 'CLUIConnectionStatusBadge';
const defaultProps: Partial<ConnectionStatusBadgeProps> = {};

export const ConnectionStatusBadge = (props: ConnectionStatusBadgeProps) => {
    const {
        className,
        classNames,
        styles,
        unstyled,
        status = ConnectionStatusUnknown,
        ...rest
    } = useComponentDefaultProps(componentName, defaultProps, props);
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    const statuses = useMemo(
        () => ({
            [ConnectionStatusUnknown]: [t('Unknown'), 'gray'],
            [ConnectionStatusDown]: [t('Down'), 'red'],
            [ConnectionStatusWarning]: [t('Warning'), 'yellow'],
            [ConnectionStatusUp]: [t('Up'), 'teal'],
            [ConnectionStatusPaused]: [t('Paused'), 'blue'],
        }),
        [t],
    );
    const [label, color] = statuses[status];
    return (
        <Badge variant="filled" className={cx(classes.root, className)} color={color} {...rest}>
            {label}
        </Badge>
    );
};

export default ConnectionStatusBadge;
