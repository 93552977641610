import { Continuation } from 'src/models';

export const SLICE_NAME = 'identity';

export type Principal = {
    id: string;
    crn: string;
    sub: string;
    name: string;
};

export type PolicyStatement = {
    sub: string;
    obj: string;
    act: string;
    eff: string;
};

export const ALLOW = 'allow';
export const DENY = 'deny';

export type Policy = {
    statements: PolicyStatement[];
};

export type WhoAmI = {
    principal: Principal;
    policy: Policy;
    features: string[];
};

export type User = {
    id: string;
    crn: string;
    createdAt: string;
    updatedAt: string;
    emailAddress: string;
    name: string;
    policy: Policy;
    features: string[];
};

export type UserRelation = {
    id: string;
    crn: string;
    name: string;
};

export type UserCreate = {
    emailAddress: string;
    password?: string;
    name: string;
    features?: string[];
};

export type UserUpdate = {
    id: string;
    emailAddress?: string;
    password?: string;
    name?: string;
    features?: string[];
};

export type Group = {
    id: string;
    crn: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    policy: Policy;
    features: string[];
};

export type NewPasswordRequest = {
    email: string;
    token: string;
    password: string;
    passwordVerify: string;
};

export type NewPasswordResponse = {};

export type PasswordResetRequest = {
    email: string;
};

export type PasswordResetResponse = {};

export type VerifyRecaptchaRequest = {
    token: string;
};

export type VerifyRecaptchaResponse = {
    success: boolean;
};

export type GroupRelation = {
    id: string;
    crn: string;
    name: string;
};

export type GroupCreate = {
    name: string;
    features?: string[];
};

export type GroupUpdate = {
    id: string;
    name?: string;
    features?: GroupFeatures;
};

export type GroupFeatures = {
    values: string[];
};

export type GroupMember = {
    id: string;
    createdAt: string;
    updatedAt: string;
    userId: string;
    userName: string;
    groupId: string;
    groupName: string;
};

export type LoginRequest = {
    rememberMe?: boolean;
};

export type LoginResponse = {
    token: string;
    principal: Principal;
    policy: Policy;
    features: string[];
};

export type LogoutRequest = {};

export type LogoutResponse = {};

export type WhoAmIRequest = {};

export type WhoAmIResponse = {
    principal: Principal;
    policy: Policy;
    features: string[];
};

export type GetUsersRequest = {
    returnCount?: boolean;
    order?: string;
    continue?: string;
    search?: string;
};

export type GetUsersResponse = {
    users: User[];
    continue: Continuation;
};

export type GetUserRequest = {
    id: string;
};

export type DeleteUserRequest = {
    id: string;
};

export type DeleteUserResponse = {
    user: User;
};

export type GetUserResponse = {
    user: User;
};

export type CreateUserRequest = {
    user: UserCreate;
    groupIds?: string[];
};

export type CreateUserResponse = {
    user: User;
};

export type UpdateUserRequest = {
    user: UserUpdate;
    groupIds?: UpdateUserGroupIds;
};

export type UpdateUserGroupIds = {
    values: string[];
};

export type UpdateUserResponse = {
    user: User;
};

export type GetGroupsRequest = {
    returnComplete?: boolean;
    returnCount?: boolean;
    order?: string;
    continue?: string;
};

export type GetGroupsResponse = {
    groups: Group[];
    continue: Continuation;
};

export type GetGroupMembersRequest = {
    groupId: string;
    returnComplete?: boolean;
    returnCount?: boolean;
    order?: string;
    continue?: string;
};

export type GetGroupMembersResponse = {
    users: UserRelation[];
    continue: Continuation;
};

export type GetUserGroupsRequest = {
    userId: string;
    returnComplete?: boolean;
    returnCount?: boolean;
    order?: string;
    continue?: string;
};

export type GetUserGroupsResponse = {
    groups: GroupRelation[];
    continue: Continuation;
};

export type SetUserGroupsRequest = {
    userId: string;
    groupIds: string[];
};

export type SetUserGroupsResponse = {};

export type SetUserPolicyRequest = {
    userId: string;
    policy: Policy;
};

export type SetUserPolicyResponse = {
    policy: Policy;
};

export type SetGroupPolicyRequest = {
    groupId: string;
    policy: Policy;
};

export type SetGroupPolicyResponse = {
    policy: Policy;
};

export type GetUserPolicyRequest = {
    userId: string;
};

export type GetUserPolicyResponse = {
    policy: Policy;
};

export type GetGroupPolicyRequest = {
    groupId: string;
};

export type GetGroupPolicyResponse = {
    policy: Policy;
};

export type CreateGroupRequest = {
    group: GroupCreate;
};

export type CreateGroupResponse = {
    group: Group;
};

export type UpdateGroupRequest = {
    group: GroupUpdate;
};

export type UpdateGroupResponse = {
    group: Group;
};

export type GetGroupRequest = {
    id: string;
};

export type GetGroupResponse = {
    group: Group;
};

export type DeleteGroupRequest = {
    id: string;
};

export type DeleteGroupResponse = {
    group: Group;
};

export type IdentityState = {
    authorization: string;
    whoAmI: WhoAmI | null;
    hasPreviousSession: boolean;
    nonInteractiveLoginAttempted: boolean;
    developerFeatures: string[];
};

export type IdentityStateSlice = {
    [SLICE_NAME]: IdentityState;
};
