import { useState } from 'react';
import { Button, useMantineTheme } from '@mantine/core';
import { Connection, GetConnectionsRequest, GetLocationRequest } from '@/management/models';
import { Continuation } from '@/models';
import { useGetConnectionsQuery, useLazyGetConnectionsQuery, useLazyGetLocationQuery } from 'src/management/api';
import { IconFileDownload } from '@tabler/icons';
import Papa from 'papaparse';

const CSVDownload = () => {
    const theme = useMantineTheme();
    const [loading, setLoading] = useState(false);
    const [req, setRequest] = useState<GetConnectionsRequest>({
        search: '',
        returnCount: true,
    });
    const { data: { connections = [], continue: paging = { count: -1, limit: 0 } as Continuation } = {} } =
        useGetConnectionsQuery({ ...req });

    const getConnections = useLazyGetConnectionsQuery();
    const getLocation = useLazyGetLocationQuery();

    const handleDownload = async () => {
        setLoading(true);

        let csvData = [
            [
                'Customer',
                'Location',
                'Name',
                'Service Address',
                'Suite',
                'City',
                'State',
                'Zip',
                'Carrier',
                'Circuit ID/Account #',
                'Bandwidth',
                'Hand off',
                'Modem MAC Address',
                'Modem Make',
                'Modem Model',
                'Gateway',
                'IP - First Usable',
                'IP - Last Usable',
                'Subnet',
                'DNS 1',
                'DNS 2',
            ],
        ];

        let pagingNext = '0';
        const uniqueLocationIds = new Set();
        let customerHashMap = new Map<string, string>();
        let connectionsArray = [] as Connection[];
        const customerDataArray: Array<[string, string]> = [];

        while (pagingNext != '') {
            if (pagingNext == '0') {
                pagingNext = '';
            }
            const response = await getConnections[0]({ ...req, continue: pagingNext });
            connectionsArray = [...connectionsArray, ...(response.data?.connections || [])];
            pagingNext = response.data?.continue.next || '';
        }

        connectionsArray.forEach((connection) => {
            uniqueLocationIds.add(connection.location?.id);
        });

        uniqueLocationIds.forEach(async (id) => {
            const response = await getLocation[0]({ ...({ id: id } as GetLocationRequest) });
            const customer = response.data?.location?.customer.name || '';
            customerHashMap.set((response.data?.location?.id as string) || '', (customer as string) || '');
        });

        for (const id of uniqueLocationIds) {
            const response = await getLocation[0]({ id } as GetLocationRequest);
            const customer = response.data?.location?.customer.name || '';
            customerDataArray.push([id as string, customer]);
        }

        csvData = [
            ...csvData,
            ...connectionsArray.map((connection: Connection) => {
                const locationId = connection.location?.id as string;
                const customerData = customerDataArray.find(([id]) => id === locationId);
                const customerName = customerData?.[1] || '';
                return [
                    customerName,
                    connection.location?.name || '',
                    connection.name || '',
                    connection.location?.address?.line1 || '',
                    connection.location?.address?.line2 || '',
                    connection.location?.address?.locality || '',
                    connection.location?.address?.administrativeArea || '',
                    "'" + connection.location?.address?.postalCode || '',
                    connection.details?.generic?.carrier || '',
                    "'" + connection.details?.generic?.carrierConnectionId || '',
                    connection.details?.generic?.bandwidth || '',
                    connection.details?.generic?.medium || '',
                    connection.details?.generic?.modemMacAddress || '',
                    connection.details?.generic?.modemMake || '',
                    connection.details?.generic?.modemModel || '',
                    connection.details?.generic?.gateway || '',
                    connection.details?.generic?.firstUsableIp || '',
                    connection.details?.generic?.lastUsableIp || '',
                    connection.details?.generic?.subnetMask || '',
                    connection.details?.generic?.dns1 || '',
                    connection.details?.generic?.dns2 || '',
                ];
            }),
        ];

        var formattedCSVData = Papa.unparse(csvData);
        const blob = new Blob([formattedCSVData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'CLOEConnectionData.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setLoading(false);
    };

    return (
        <Button
            loading={loading}
            style={{ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white }}
            onClick={() => (loading ? null : handleDownload())}
        >
            <IconFileDownload color="gray" />
        </Button>
    );
};

export default CSVDownload;
