import { useState } from 'react';
import { useAppDispatch } from '@/app/store';
import {
    selectHasPreviousSession,
    selectNonInteractiveLoginAttempted,
    setNonInteractiveLoginAttempted,
    useLoginMutation,
} from '@/identity';

import { usePasswordResetMutation, useVerifyRecaptchaMutation } from '@/identity/api';

import { forDesktopUp } from '@/styles/mixins';
import { useTypedLocation } from '@/utils';
import {
    Anchor,
    Box,
    Button,
    Center,
    createStyles,
    Group,
    Paper,
    Stack,
    TextInput,
    Title,
    useMantineTheme,
    Loader,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Location, useNavigate } from 'react-router-dom';
import Logo from 'src/common/components/Logo';
import GoogleReCaptcha from './GoogleReCaptcha';
import CLOEClover from '../../../../public/icon.png';
import '../Login/Login.css';

export interface ResetPasswordProps {}

const useStyles = createStyles((theme, params, getRef) => {
    return {};
});

type ResetPasswordValues = {
    emailAddress: string;
    reCaptcha: boolean;
    resetPasswordResult: {
        result: string;
        message: string;
    };
};

export const PasswordReset = (props: ResetPasswordProps) => {
    const { classes, cx } = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const location = useTypedLocation<{ from: Location }>();
    const from = location.state?.from?.pathname || `/`;
    const [login, { isLoading, isSuccess, isError, isUninitialized }] = useLoginMutation();
    const dispatch = useAppDispatch();
    const hasPreviousSession = useSelector(selectHasPreviousSession);
    const nonInteractiveLoginAttempted = useSelector(selectNonInteractiveLoginAttempted);
    const [resetResult, setResetResult] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [recaptchaToken, setToken] = useState<string | null>(null);
    const [btnClicked, setBtnClicked] = useState(false);
    const [sendPasswordReset] = usePasswordResetMutation();
    const [verifyRecaptcha] = useVerifyRecaptchaMutation();

    const form = useForm<ResetPasswordValues>({
        initialValues: {
            emailAddress: '',
            reCaptcha: false,
            resetPasswordResult: {
                result: '',
                message: '',
            },
        },
        validate: (values: any) => {
            const errors: Record<string, string> = {};

            if (!values.emailAddress) {
                errors.emailAddress = t('Password does not meet requirements');
                return errors;
            }
            if (!values.reCaptcha) {
                errors.emailAddress = t('Need reCaptcha verification');
            }
            return errors;
        },
    });

    const onSubmit = async ({ emailAddress }: ResetPasswordValues) => {
        if (!btnClicked) {
            setBtnClicked(true);
            try {
                sendPasswordReset({ email: emailAddress });
                setEmailAddress(emailAddress);
                setResetResult('SUCCESS');
            } catch {
                setResetResult('ERROR');
            }
            setBtnClicked(false);
        }
    };

    const handleVerify = async (token: string | null) => {
        if (token === null) {
            return;
        }
        if (!token) {
            form.setFieldValue('reCaptcha', false);
        } else {
            let res = await verifyRecaptcha({ token }).unwrap();
            form.setFieldValue('reCaptcha', res.success);
            setToken(token);
        }
    };

    if (hasPreviousSession) {
        if (isUninitialized) {
            dispatch(setNonInteractiveLoginAttempted(true));
            login({});
        }
        if (isUninitialized || (nonInteractiveLoginAttempted && isLoading)) {
            return (
                <Center style={{ minHeight: '100vh', minWidth: '100vw' }}>
                    <Box>
                        <img
                            src={CLOEClover}
                            alt="loading symbol"
                            className="my-img"
                            style={{ width: '150px', height: 'auto' }}
                        />
                    </Box>
                </Center>
            );
        }
    }

    if (resetResult === 'SUCCESS') {
        return (
            <Center sx={{ width: '100%' }}>
                <Stack align="center" justify="center">
                    <Logo mb={theme.spacing.lg * 2} />
                    <Paper
                        withBorder
                        shadow={'md'}
                        p={'md'}
                        sx={{
                            width: '400px',
                            ...forDesktopUp(theme, {
                                width: '400px',
                            }),
                        }}
                    >
                        <Title order={3}>{t(`Sent Reset Password Request to ${emailAddress}`)}</Title>
                        <Anchor component={Link} to={`/login`}>
                            {t('Back to Login')}
                        </Anchor>
                    </Paper>
                </Stack>
            </Center>
        );
    } else {
        return (
            <Center sx={{ width: '100%' }}>
                <Stack align="center" justify="center">
                    <Logo mb={theme.spacing.lg * 2} />
                    <Paper
                        withBorder
                        shadow={'md'}
                        p={'md'}
                        sx={{
                            width: '400px',
                            ...forDesktopUp(theme, {
                                width: '400px',
                            }),
                        }}
                    >
                        <form onSubmit={form.onSubmit(onSubmit)}>
                            <Title order={3}>
                                {t(`Reset Password`)} {`for CLOE`}
                            </Title>
                            <TextInput
                                mt="md"
                                mb="lg"
                                label={t(`Email Address`)}
                                placeholder={t(`Email Address`)}
                                disabled={isLoading}
                                required
                                {...form.getInputProps('emailAddress')}
                            />
                            <GoogleReCaptcha
                                onVerify={handleVerify}
                                sitekey="6LfMilolAAAAADyVTNFfMGZ9cy9uNvRDMhq5-uL7"
                            />
                            <Group mt="md" position="right">
                                <Button type="submit" color={'primary'} loading={isLoading}>
                                    {t('Send Reset Request')}
                                </Button>
                            </Group>
                        </form>
                    </Paper>
                    {btnClicked && <Loader color="green" size="xl"></Loader>}
                </Stack>
            </Center>
        );
    }
};

export default PasswordReset;
