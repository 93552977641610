import {
    Box,
    createStyles,
    DefaultProps,
    Selectors,
    TextInput,
    useComponentDefaultProps,
    Group,
    Select,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Address } from '@/models';
import { useControlledForm } from 'src/common/hooks';

export interface AddressInputStyleParams {}

const useStyles = createStyles((theme, params: AddressInputStyleParams, getRef) => {
    return {
        root: {},
    };
});

type AddressInputStyleNames = Selectors<typeof useStyles>;

export interface AddressInputProps extends DefaultProps<AddressInputStyleNames, AddressInputStyleParams> {
    value?: Address;
    onChange?: (value: Address) => void;
}

const componentName = 'CLUIAddressInput';
const defaultProps: Partial<AddressInputProps> = {};
const defaultValues: Partial<Address> = {
    line1: '',
    line2: '',
    line3: '',
    locality: '',
    subLocality: '',
    administrativeArea: '',
    postalCode: '',
    regionCode: 'US',
};
const stateCodes: { value: string; label: string }[] = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
];

export const AddressInput = (props: AddressInputProps) => {
    const {
        className,
        classNames,
        styles,
        unstyled,
        value = {},
        onChange = () => {},
        ...rest
    } = useComponentDefaultProps(componentName, defaultProps, props);
    const { classes, cx, theme } = useStyles({}, { classNames, styles, unstyled, name: componentName });
    const { t } = useTranslation();
    const form = useControlledForm({
        defaultValues,
        value: value,
        onChange,
    });
    return (
        <Box className={cx(classes.root, className)} {...rest}>
            {/* <Text size="sm">{t("Address")}</Text> */}
            <TextInput label={t('Line 1')} placeholder={t('')} {...form.getInputProps('line1')} />
            <TextInput label={t('Line 2')} placeholder={t('')} {...form.getInputProps('line2')} />
            <Group position="apart" grow sx={{ flexWrap: 'nowrap' }}>
                <TextInput label={t('City')} placeholder={t('')} {...form.getInputProps('locality')} />
                <Select
                    label={t('State')}
                    placeholder={t('Pick one')}
                    searchable
                    nothingFound={t('No options')}
                    data={stateCodes}
                    {...form.getInputProps('administrativeArea')}
                    clearable
                />
                <TextInput label={t('Zip')} placeholder={t('')} {...form.getInputProps('postalCode')} />
            </Group>
            <TextInput
                mt="md"
                sx={{ display: 'none' }}
                label={t('Country')}
                placeholder={t('')}
                {...form.getInputProps('regionCode')}
            />
        </Box>
    );
};

export default AddressInput;
