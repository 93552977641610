import Drawer, { DrawerContext, DrawerProps } from '@/common/components/Drawer';
import { Text, useComponentDefaultProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import CreateCustomerForm from './CreateCustomerForm';

export interface CreateCustomerProps extends DrawerProps {}

const componentName = 'CLUICreateCustomer';
const defaultProps: Partial<CreateCustomerProps> = {
    size: '50vw',
    padding: 'md',
    position: 'right',
    closeOnClickOutside: false,
    overlayOpacity: 0.0,
    shadow: 'lg',
};

export const CreateCustomer = (props: CreateCustomerProps) => {
    const { onClose, ...rest } = useComponentDefaultProps(componentName, defaultProps, props);
    const { t, i18n } = useTranslation();
    return (
        <Drawer onClose={onClose} {...rest} shadow="lg" title={<Text weight="bold">{t('Create Customer')}</Text>}>
            <DrawerContext.Consumer>{({ onClose }) => <CreateCustomerForm onClose={onClose} />}</DrawerContext.Consumer>
        </Drawer>
    );
};

export default CreateCustomer;
