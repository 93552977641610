import { DrawerProps, Text, useComponentDefaultProps } from '@mantine/core';

import { useTranslation } from 'react-i18next';
import Drawer, { DrawerContext } from '@/common/components/Drawer';
import UpdateUserForm from './UpdateUserForm';
import { useLazyGetUserQuery } from '@/identity/api';
import { User } from '@/identity/models';
import { useEffect } from 'react';
import { useParams } from 'react-router';

export interface UpdateUserProps extends DrawerProps {}

const componentName = 'CLUIUpdateUser';
const defaultProps: Partial<UpdateUserProps> = {
    size: '50vw',
    padding: 'md',
    position: 'right',
    closeOnClickOutside: false,
    overlayOpacity: 0.0,
    shadow: 'lg',
};

export const UpdateUser = (props: UpdateUserProps) => {
    const { onClose, ...rest } = useComponentDefaultProps(componentName, defaultProps, props);
    const { userId } = useParams();
    const { t, i18n } = useTranslation();
    const [getUser, { data: { user = {} as User } = {} }] = useLazyGetUserQuery();
    useEffect(() => {
        if (userId) {
            getUser({ id: userId });
        }
    }, [userId]);
    return (
        <Drawer
            onClose={onClose}
            {...rest}
            shadow="lg"
            title={
                <Text weight="bold">
                    {t('Update:')} {user?.name ?? 'Unknown'}
                </Text>
            }
        >
            <DrawerContext.Consumer>
                {({ onClose }) => <UpdateUserForm user={user} userId={userId!} onClose={onClose} />}
            </DrawerContext.Consumer>
        </Drawer>
    );
};

export default UpdateUser;
