import { useGetCustomersQuery } from '@/management/api';
import { Customer, GetCustomersRequest } from '@/management/models';
import { Continuation } from '@/models';
import { Anchor, Button, Card, Group, Text, TextInput, Title, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconAffiliate, IconCirclePlus, IconSearch } from '@tabler/icons';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import List from 'src/common/components/List';
import Pagination from 'src/common/components/Pagination';
import { useAllowsActionOnResource } from '@/identity';
import * as $P from '@/identity/policy';

export interface CustomersProps {}

export const Customers = ({}: CustomersProps) => {
    const location = useLocation();
    const { t } = useTranslation();
    const allowsActionOnResource = useAllowsActionOnResource();
    const [req, setRequest] = useState<GetCustomersRequest>({
        search: '',
        returnCount: true,
    });
    const [virtPageNumber, setVirtPageNumber] = useState(0);
    const {
        data: { customers = [], continue: paging = { count: -1, limit: 0 } as Continuation } = {},
        isLoading,
        isError,
    } = useGetCustomersQuery(req);
    const columnHelper = createColumnHelper<Customer>();
    const columns = useMemo(
        () => [
            columnHelper.accessor('name', {
                header: () => <Text weight="bold">{t('Name')}</Text>,
                cell: (info) => (
                    <Anchor component={Link} to={`${info.row.original.id}`}>
                        {info.getValue()}
                    </Anchor>
                ),
            }),
        ],
        [],
    );
    const gridTemplateColumns = '1fr';
    const table = useReactTable({
        data: customers,
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true,
    });

    const goToNextPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber + 1);
        setRequest((req) => ({
            ...req,
            continue: paging.next,
        }));
    };

    const goToPrevPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber - 1);
        setRequest((req) => ({
            ...req,
            continue: paging.prev,
        }));
    };

    const [search, setSearch] = useState('');
    const [searchQuery] = useDebouncedValue(search, 200);
    if (searchQuery != req.search) {
        setRequest((req) => ({
            ...req,
            continue: '',
            search: searchQuery,
        }));
    }

    return (
        <List
            table={table}
            gridTemplateColumns={gridTemplateColumns}
            header={
                <Group position="apart">
                    <Title order={3}>{t('All Customers')}</Title>
                    <Group position="right">
                        {allowsActionOnResource($P.CanManageCustomer, $P.CustomersResource) ? (
                            <Tooltip label={t('New Customer')}>
                                <Button
                                    component={Link}
                                    to={'new'}
                                    state={{ backgroundLocation: location }}
                                    variant="light"
                                    color="cyan"
                                    px="xs"
                                >
                                    <IconAffiliate />
                                    <IconCirclePlus />
                                </Button>
                            </Tooltip>
                        ) : null}
                        <TextInput
                            placeholder="Search"
                            icon={<IconSearch size={16} stroke={1.5} />}
                            onChange={(evt) => setSearch(evt.currentTarget.value)}
                        />
                    </Group>
                </Group>
            }
            footer={
                <Card.Section withBorder>
                    <Group position="apart" p="sm" sx={{ minHeight: 60 }}>
                        <Pagination
                            goToNextPage={goToNextPage}
                            goToPrevPage={goToPrevPage}
                            virtPageNumber={virtPageNumber}
                            limit={paging.limit}
                            count={paging.count}
                        />
                    </Group>
                </Card.Section>
            }
        />
    );
};

export default Customers;
