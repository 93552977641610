import { GetUsersRequest, User } from '@/identity/models';
import { Continuation } from '@/models';
import { Anchor, Button, Card, Group, Text, TextInput, Title, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconCirclePlus, IconSearch, IconUser } from '@tabler/icons';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import List from '@/common/components/List';
import Pagination from '@/common/components/Pagination';
import { useGetUsersQuery } from '@/identity/api';
import { useAllowsActionOnResource } from '@/identity';
import * as $P from '@/identity/policy';

export interface UsersProps {}

export const Users = ({}: UsersProps) => {
    const location = useLocation();
    const { t } = useTranslation();
    const allowsActionOnResource = useAllowsActionOnResource();
    const [req, setRequest] = useState<GetUsersRequest>({
        search: '',
        returnCount: true,
    });
    const [virtPageNumber, setVirtPageNumber] = useState(0);
    const {
        data: { users = [], continue: paging = { count: -1, limit: 0 } as Continuation } = {},
        isLoading,
        isError,
    } = useGetUsersQuery(req);
    const columnHelper = createColumnHelper<User>();
    const columns = useMemo(
        () => [
            columnHelper.accessor('name', {
                header: () => <Text weight="bold">{t('Name')}</Text>,
                cell: (info) => (
                    <Anchor component={Link} to={`${info.row.original.id}`}>
                        {info.getValue()}
                    </Anchor>
                ),
            }),
            columnHelper.accessor('emailAddress', {
                header: () => <Text weight="bold">{t('Email Address')}</Text>,
                cell: (info) => <Text>{info.getValue()}</Text>,
            }),
        ],
        [columnHelper],
    );
    const gridTemplateColumns = '1fr 1fr';
    const table = useReactTable({
        data: users,
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true,
    });

    const goToNextPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber + 1);
        setRequest((req) => ({
            ...req,
            continue: paging.next,
        }));
    };

    const goToPrevPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber - 1);
        setRequest((req) => ({
            ...req,
            continue: paging.prev,
        }));
    };

    const [search, setSearch] = useState('');
    const [searchQuery] = useDebouncedValue(search, 200);
    if (searchQuery != req.search) {
        setRequest((req) => ({
            ...req,
            continue: '',
            search: searchQuery,
        }));
    }

    return (
        <List
            table={table}
            gridTemplateColumns={gridTemplateColumns}
            header={
                <Group position="apart">
                    <Title order={3}>{t('All Users')}</Title>
                    <Group position="right">
                        {allowsActionOnResource($P.CanManageUser, $P.UsersResource) ? (
                            <Tooltip label={t('New User')}>
                                <Button
                                    component={Link}
                                    to={'new'}
                                    state={{ backgroundLocation: location }}
                                    variant="light"
                                    color="red"
                                    px="xs"
                                >
                                    <IconUser />
                                    <IconCirclePlus />
                                </Button>
                            </Tooltip>
                        ) : null}
                        <TextInput
                            placeholder="Search"
                            icon={<IconSearch size={16} stroke={1.5} />}
                            onChange={(evt) => setSearch(evt.currentTarget.value)}
                        />
                    </Group>
                </Group>
            }
            footer={
                <Card.Section withBorder>
                    <Group position="apart" p="sm">
                        <Pagination
                            goToNextPage={goToNextPage}
                            goToPrevPage={goToPrevPage}
                            virtPageNumber={virtPageNumber}
                            limit={paging.limit}
                            count={paging.count}
                        />
                    </Group>
                </Card.Section>
            }
        />
    );
};

export default Users;
