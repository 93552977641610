import Drawer, { DrawerContext, DrawerProps } from '@/common/components/Drawer';
import { Text, useComponentDefaultProps } from '@mantine/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useLazyGetConnectionQuery } from 'src/management/api';
import { Connection } from 'src/management/models';
import UpdateConnectionForm from './UpdateConnectionForm';

export interface UpdateConnectionProps extends DrawerProps {}

const componentName = 'CLUIUpdateConnection';
const defaultProps: Partial<UpdateConnectionProps> = {
    size: '50vw',
    padding: 'md',
    position: 'right',
    closeOnClickOutside: false,
    overlayOpacity: 0.0,
    shadow: 'lg',
};

export const UpdateConnection = (props: UpdateConnectionProps) => {
    const { onClose, ...rest } = useComponentDefaultProps(componentName, defaultProps, props);
    const { connectionId } = useParams();
    const { t, i18n } = useTranslation();
    const [getConnection, { data: { connection = {} as Connection } = {} }] = useLazyGetConnectionQuery();
    useEffect(() => {
        if (connectionId) {
            getConnection({ id: connectionId });
        }
    }, [connectionId]);
    return (
        <Drawer
            onClose={onClose}
            {...rest}
            shadow="lg"
            title={
                <Text weight="bold">
                    {t('Update')}: {connection?.name ?? t('Unknown')}
                </Text>
            }
        >
            <DrawerContext.Consumer>
                {({ onClose }) => (
                    <UpdateConnectionForm connection={connection} connectionId={connectionId!} onClose={onClose} />
                )}
            </DrawerContext.Consumer>
        </Drawer>
    );
};

export default UpdateConnection;
