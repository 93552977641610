import { useGetConnectionsQuery } from '@/management/api';
import { Connection, GetConnectionsRequest } from '@/management/models';
import { Continuation } from '@/models';
import {
    Anchor,
    Button,
    Card,
    Group,
    Text,
    TextInput,
    Title,
    ActionIcon,
    useMantineTheme,
    Tooltip,
    Loader,
} from '@mantine/core';
import { IconCirclePlus, IconNetwork, IconSearch, IconSquareX, IconFileDownload, IconRefresh } from '@tabler/icons';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import List from '@/common/components/List';
import Pagination from '@/common/components/Pagination';
import { useDebouncedValue, useForceUpdate } from '@mantine/hooks';
import ConnectionStatusBadge from '../ConnectionStatusBadge';
import { useAllowsActionOnResource } from '@/identity';
import * as $P from '@/identity/policy';
import CSVDownload from '@/util/CSVDownload';

export interface ConnectionsProps {}

export const Connections = ({}: ConnectionsProps) => {
    const forceUpdate = useForceUpdate();
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const { t } = useTranslation();
    const allowsActionOnResource = useAllowsActionOnResource();
    const [mountDownloadButton, setMountDownloadButton] = useState(false);
    const theme = useMantineTheme();
    const [req, setRequest] = useState<GetConnectionsRequest>({
        search: '',
        returnCount: true,
    });
    const [virtPageNumber, setVirtPageNumber] = useState(0);
    const {
        data: { connections = [], continue: paging = { count: -1, limit: 0 } as Continuation } = {},
        isLoading,
        isError,
        isFetching,
        refetch,
    } = useGetConnectionsQuery(req, {
        pollingInterval: 60 * 1000,
    });
    const columnHelper = createColumnHelper<Connection>();
    const columns = useMemo(
        () => [
            columnHelper.accessor('name', {
                header: () => <Text weight="bold">{t('Name')}</Text>,
                cell: (info) => (
                    <Tooltip label={info.getValue()}>
                        <Anchor component={Link} to={`${info.row.original.id}`}>
                            {info.getValue()}
                        </Anchor>
                    </Tooltip>
                ),
            }),
            columnHelper.accessor((row) => row.location?.name, {
                id: 'location.name',
                header: (info) => <Text weight="bold">{t('Location')}</Text>,
                cell: (info) => (
                    <Anchor component={Link} to={`/locations/${info.row.original.location?.id}`}>
                        {info.getValue()}
                    </Anchor>
                ),
            }),
            columnHelper.accessor((row) => row.location?.address?.line1, {
                id: 'location.address.line1',
                header: (info) => <Text weight="bold">{t('Address')}</Text>,
                cell: (info) => <Text>{info.getValue()}</Text>,
            }),
            columnHelper.accessor((row) => row.location?.address?.locality, {
                id: 'location.address.locality',
                header: (info) => <Text weight="bold">{t('City')}</Text>,
                cell: (info) => <Text>{info.getValue()}</Text>,
            }),
            columnHelper.accessor((row) => row.location?.address?.administrativeArea, {
                id: 'location.address.administrativeArea',
                cell: (info) => <Text>{info.getValue()}</Text>,
                header: (info) => <Text weight="bold">{t('State')}</Text>,
            }),
            // extra carrier column if user has connection management permission
            ...(allowsActionOnResource($P.CanManageConnection, $P.ConnectionsResource)
                ? [
                      columnHelper.accessor((row) => row.details?.generic?.carrier, {
                          id: 'details.generic.carrier',
                          cell: (info) => <Text>{info.getValue()}</Text>,
                          header: (info) => <Text weight="bold">{t('Carrier')}</Text>,
                      }),
                  ]
                : []),
            columnHelper.accessor('status', {
                header: () => <Text weight="bold">{t('Status')}</Text>,
                cell: (info) => <ConnectionStatusBadge status={info.getValue()} />,
            }),
        ],
        [columnHelper],
    );

    // extra carrier column if user has connection management permission
    const gridTemplateColumns = allowsActionOnResource($P.CanManageConnection, $P.ConnectionsResource)
        ? '20% 20% 1fr 1fr 1fr 1fr 1fr'
        : '20% 20% 1fr 1fr 1fr 1fr';

    const table = useReactTable({
        data: connections,
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true,
    });

    const goToNextPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber + 1);
        setRequest((req) => ({
            ...req,
            continue: paging.next,
        }));
    };

    const goToPrevPage = () => {
        setVirtPageNumber((virtPageNumber) => virtPageNumber - 1);
        setRequest((req) => ({
            ...req,
            continue: paging.prev,
        }));
    };

    const [search, setSearch] = useState(searchParams.get('search') ?? '');
    const [searchQuery] = useDebouncedValue(search, 200);
    if (searchQuery != req.search) {
        setRequest((req) => ({
            ...req,
            continue: '',
            search: searchQuery,
        }));
    }

    return (
        <List
            table={table}
            gridTemplateColumns={gridTemplateColumns}
            header={
                <Group position="apart" sx={{ minHeight: 36 }}>
                    <Title order={3}>{t('All Connections')}</Title>
                    <Group position="right">
                        <Tooltip label={t('Refresh')}>
                            <Button
                                variant="light"
                                color="blue"
                                onClick={() => !isFetching && refetch()}
                                disabled={isFetching}
                            >
                                {isFetching ? (
                                    <>
                                        <Loader color="blue" size="xs" />
                                        <IconRefresh />
                                    </>
                                ) : (
                                    <IconRefresh />
                                )}
                            </Button>
                        </Tooltip>
                        {allowsActionOnResource($P.CanManageConnection, $P.ConnectionsResource) ? (
                            <Tooltip label={t('Add Connection')}>
                                <Button
                                    component={Link}
                                    to={'new'}
                                    state={{ backgroundLocation: location }}
                                    variant="light"
                                    color="blue"
                                    px="xs"
                                >
                                    <IconNetwork />
                                    <IconCirclePlus />
                                </Button>
                            </Tooltip>
                        ) : null}
                        <TextInput
                            placeholder={t('Search')}
                            value={search}
                            icon={<IconSearch size={16} stroke={1.5} />}
                            onChange={(evt) => setSearch(evt.currentTarget.value)}
                            rightSection={
                                search ? (
                                    <ActionIcon onClick={() => setSearch('')}>
                                        <IconSquareX color="red" size={16} stroke={1.5} />
                                    </ActionIcon>
                                ) : (
                                    ''
                                )
                            }
                        />
                    </Group>
                </Group>
            }
            footer={
                <Card.Section withBorder>
                    <Group position="apart" p="sm" sx={{ minHeight: 60 }}>
                        <Pagination
                            goToNextPage={goToNextPage}
                            goToPrevPage={goToPrevPage}
                            virtPageNumber={virtPageNumber}
                            limit={paging.limit}
                            count={paging.count}
                        />
                        {allowsActionOnResource($P.CanManageConnection, $P.ConnectionsResource) ? (
                            !mountDownloadButton ? (
                                <Button
                                    style={{
                                        backgroundColor:
                                            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
                                    }}
                                    onMouseEnter={() => setMountDownloadButton(true)}
                                >
                                    <IconFileDownload color="gray" />
                                </Button>
                            ) : (
                                <CSVDownload></CSVDownload>
                            )
                        ) : (
                            <></>
                        )}
                    </Group>
                </Card.Section>
            }
        />
    );
};

export default Connections;
