import { rootApi } from '../api';
import {
    CreateConnectionRequest,
    CreateConnectionResponse,
    CreateCustomerRequest,
    CreateCustomerResponse,
    CreateDeviceRequest,
    CreateDeviceResponse,
    CreateLocationRequest,
    CreateLocationResponse,
    GetConnectionRequest,
    GetConnectionResponse,
    GetConnectionsRequest,
    GetConnectionsResponse,
    DeleteConnectionRequest,
    DeleteConnectionResponse,
    DeleteCustomerRequest,
    DeleteCustomerResponse,
    DeleteLocationRequest,
    DeleteLocationResponse,
    GetCustomerLocationsRequest,
    GetCustomerLocationsResponse,
    GetCustomerRequest,
    GetCustomerResponse,
    GetCustomersRequest,
    GetCustomersResponse,
    GetDeviceRequest,
    GetDeviceResponse,
    GetDevicesRequest,
    GetDevicesResponse,
    GetLocationConnectionsRequest,
    GetLocationConnectionsResponse,
    GetLocationDevicesRequest,
    GetLocationDevicesResponse,
    GetLocationRequest,
    GetLocationResponse,
    GetLocationsRequest,
    GetLocationsResponse,
    UpdateConnectionRequest,
    UpdateConnectionResponse,
    UpdateCustomerRequest,
    UpdateCustomerResponse,
    UpdateDeviceRequest,
    UpdateDeviceResponse,
    UpdateLocationRequest,
    UpdateLocationResponse,
    GetConnectionAvailabilityRequest,
    GetConnectionAvailabilityResponse,
    GetConnectionLatencyRequest,
    GetConnectionLatencyResponse,
    GetConnectionJitterRequest,
    GetConnectionJitterResponse,
    GetDashboardRequest,
    GetDashboardResponse,
    GetDashboardMapMarkersRequest,
    GetDashboardMapMarkersResponse,
    CreateCustomerAttachmentRequest,
    CreateCustomerAttachmentResponse,
    CreateLocationAttachmentRequest,
    CreateLocationAttachmentResponse,
} from './models';
import { createAttachmentFormData } from './utils';

const apiWithTag = rootApi.enhanceEndpoints({
    addTagTypes: ['Customer', 'Location', 'Device', 'Connection', 'Dashboard', 'DashboardMapMarker'],
});

export const managementApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
        getCustomers: build.query<GetCustomersResponse, GetCustomersRequest>({
            query: (req: GetCustomersRequest) => ({
                url: `customers`,
                params: req,
            }),
            providesTags: (res, err, arg) =>
                res
                    ? [
                          ...res.customers.map(({ id }) => ({
                              type: 'Customer' as const,
                              id,
                          })),
                          'Customer',
                      ]
                    : ['Customer'],
        }),
        getCustomer: build.query<GetCustomerResponse, GetCustomerRequest>({
            query: (req: GetCustomerRequest) => ({
                url: `customers/${req.id}`,
            }),
            providesTags: (res, err, arg) => [{ type: 'Customer' as const, id: res?.customer.id }],
        }),
        createCustomer: build.mutation<CreateCustomerResponse, CreateCustomerRequest>({
            query: (req: CreateCustomerRequest) => ({
                url: `customers`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: ['Customer', 'Dashboard', 'DashboardMapMarker'],
        }),
        updateCustomer: build.mutation<UpdateCustomerResponse, UpdateCustomerRequest>({
            query: (req: UpdateCustomerRequest) => ({
                url: `customers/${req.customer.id}`,
                method: 'PATCH',
                body: req,
            }),
            invalidatesTags: (res, err, arg) => [
                { type: 'Customer' as const, id: res?.customer.id },
                'Dashboard',
                'DashboardMapMarker',
            ],
        }),
        deleteCustomer: build.mutation<DeleteCustomerResponse, DeleteCustomerRequest>({
            query: (req: DeleteCustomerRequest) => ({
                url: `customers/${req.id}`,
                method: 'DELETE',
                body: req,
            }),
            invalidatesTags: ['Customer', 'Location', 'Connection', 'Dashboard', 'DashboardMapMarker'],
        }),
        getLocations: build.query<GetLocationsResponse, GetLocationsRequest>({
            query: (req: GetLocationsRequest) => ({
                url: `locations`,
                params: req,
            }),
            providesTags: (res, err, arg) =>
                res
                    ? [
                          ...res.locations.map(({ id }) => ({
                              type: 'Location' as const,
                              id,
                          })),
                          'Location',
                      ]
                    : ['Location'],
        }),
        getLocation: build.query<GetLocationResponse, GetLocationRequest>({
            query: (req: GetLocationRequest) => ({
                url: `locations/${req.id}`,
                params: req,
            }),
            providesTags: (res, err, arg) => [{ type: 'Location' as const, id: res?.location.id }],
        }),
        createLocation: build.mutation<CreateLocationResponse, CreateLocationRequest>({
            query: (req: CreateLocationRequest) => ({
                url: `locations`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: ['Location', 'Dashboard', 'DashboardMapMarker'],
        }),
        updateLocation: build.mutation<UpdateLocationResponse, UpdateLocationRequest>({
            query: (req: UpdateLocationRequest) => ({
                url: `locations/${req.location.id}`,
                method: 'PATCH',
                body: req,
            }),
            invalidatesTags: (res, err, arg) => [
                { type: 'Location' as const, id: res?.location.id },
                'Dashboard',
                'DashboardMapMarker',
            ],
        }),
        deleteLocation: build.mutation<DeleteLocationResponse, DeleteLocationRequest>({
            query: (req: DeleteLocationRequest) => ({
                url: `locations/${req.id}`,
                method: 'DELETE',
                body: req,
            }),
            invalidatesTags: ['Location', 'Device', 'Connection', 'Dashboard', 'DashboardMapMarker'],
        }),
        getCustomerLocations: build.query<GetCustomerLocationsResponse, GetCustomerLocationsRequest>({
            query: (req: GetCustomerLocationsRequest) => ({
                url: `customers/${req.customerId}/locations`,
                params: req,
            }),
            // TODO: is this correct? or should we have a separate tag for nested?
            providesTags: (res, err, arg) =>
                res
                    ? [
                          ...res.locations.map(({ id }) => ({
                              type: 'Location' as const,
                              id,
                          })),
                          'Location',
                      ]
                    : ['Location'],
        }),
        getDevices: build.query<GetDevicesResponse, GetDevicesRequest>({
            query: (req: GetDevicesRequest) => ({
                url: `devices`,
                params: req,
            }),
            providesTags: (res, err, arg) =>
                res
                    ? [
                          ...res.devices.map(({ id }) => ({
                              type: 'Device' as const,
                              id,
                          })),
                          'Device',
                      ]
                    : ['Device'],
        }),
        getDevice: build.query<GetDeviceResponse, GetDeviceRequest>({
            query: (req: GetDeviceRequest) => ({
                url: `devices/${req.id}`,
                params: req,
            }),
            providesTags: (res, err, arg) => [{ type: 'Device' as const, id: res?.device.id }],
        }),
        createDevice: build.mutation<CreateDeviceResponse, CreateDeviceRequest>({
            query: (req: CreateDeviceRequest) => ({
                url: `devices`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: ['Device', 'Dashboard', 'DashboardMapMarker'],
        }),
        updateDevice: build.mutation<UpdateDeviceResponse, UpdateDeviceRequest>({
            query: (req: UpdateDeviceRequest) => ({
                url: `devices/${req.device.id}`,
                method: 'PATCH',
                body: req,
            }),
            invalidatesTags: (res, err, arg) => [
                { type: 'Device' as const, id: res?.device.id },
                'Dashboard',
                'DashboardMapMarker',
            ],
        }),
        getLocationDevices: build.query<GetLocationDevicesResponse, GetLocationDevicesRequest>({
            query: (req: GetLocationDevicesRequest) => ({
                url: `locations/${req.locationId}/devices`,
                params: req,
            }),
            // TODO: is this correct? or should we have a separate tag for nested?
            providesTags: (res, err, arg) =>
                res
                    ? [
                          ...res.devices.map(({ id }) => ({
                              type: 'Device' as const,
                              id,
                          })),
                          'Device',
                      ]
                    : ['Device'],
        }),
        getConnections: build.query<GetConnectionsResponse, GetConnectionsRequest>({
            query: (req: GetConnectionsRequest) => ({
                url: `connections`,
                params: req,
            }),
            providesTags: (res, err, arg) =>
                res
                    ? [
                          ...res.connections.map(({ id }) => ({
                              type: 'Connection' as const,
                              id,
                          })),
                          'Connection',
                      ]
                    : ['Connection'],
        }),
        getConnection: build.query<GetConnectionResponse, GetConnectionRequest>({
            query: (req: GetConnectionRequest) => ({
                url: `connections/${req.id}`,
                params: req,
            }),
            providesTags: (res, err, arg) => [{ type: 'Connection' as const, id: res?.connection.id }],
        }),
        createConnection: build.mutation<CreateConnectionResponse, CreateConnectionRequest>({
            query: (req: CreateConnectionRequest) => ({
                url: `connections`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: ['Connection', 'Dashboard', 'DashboardMapMarker'],
        }),
        updateConnection: build.mutation<UpdateConnectionResponse, UpdateConnectionRequest>({
            query: (req: UpdateConnectionRequest) => ({
                url: `connections/${req.connection.id}`,
                method: 'PATCH',
                body: req,
            }),
            invalidatesTags: (res, err, arg) => [
                { type: 'Connection' as const, id: res?.connection.id },
                'Dashboard',
                'DashboardMapMarker',
            ],
        }),
        deleteConnection: build.mutation<DeleteConnectionResponse, DeleteConnectionRequest>({
            query: (req: DeleteConnectionRequest) => ({
                url: `connections/${req.id}`,
                method: 'DELETE',
                body: req,
            }),
            invalidatesTags: ['Connection', 'Dashboard', 'DashboardMapMarker'],
        }),
        getLocationConnections: build.query<GetLocationConnectionsResponse, GetLocationConnectionsRequest>({
            query: (req: GetLocationConnectionsRequest) => ({
                url: `locations/${req.locationId}/connections`,
                params: req,
            }),
            // TODO: is this correct? or should we have a separate tag for nested?
            providesTags: (res, err, arg) =>
                res
                    ? [
                          ...res.connections.map(({ id }) => ({
                              type: 'Connection' as const,
                              id,
                          })),
                          'Connection',
                      ]
                    : ['Connection'],
        }),
        getConnectionAvailability: build.query<GetConnectionAvailabilityResponse, GetConnectionAvailabilityRequest>({
            query: (req: GetConnectionAvailabilityRequest) => ({
                url: `connections/${req.connectionId}/availability`,
                params: req,
            }),
        }),
        getConnectionLatency: build.query<GetConnectionLatencyResponse, GetConnectionLatencyRequest>({
            query: (req: GetConnectionLatencyRequest) => ({
                url: `connections/${req.connectionId}/latency`,
                params: req,
            }),
        }),
        getConnectionJitter: build.query<GetConnectionJitterResponse, GetConnectionJitterRequest>({
            query: (req: GetConnectionJitterRequest) => ({
                url: `connections/${req.connectionId}/jitter`,
                params: req,
            }),
        }),
        getDashboard: build.query<GetDashboardResponse, GetDashboardRequest>({
            query: (req: GetDashboardRequest) => ({
                url: `dashboard`,
                params: req,
            }),
            providesTags: ['Dashboard'],
        }),
        getDashboardMapMarkers: build.query<GetDashboardMapMarkersResponse, GetDashboardMapMarkersRequest>({
            query: (req: GetDashboardMapMarkersRequest) => ({
                url: `dashboard/map/markers`,
                params: req,
            }),
            providesTags: ['DashboardMapMarker'],
        }),
        createCustomerAttachment: build.mutation<CreateCustomerAttachmentResponse, CreateCustomerAttachmentRequest>({
            query: (req: CreateCustomerAttachmentRequest) => ({
                url: `customers/${req.customerId}/attachments`,
                method: 'POST',
                body: createAttachmentFormData(req),
            }),
            invalidatesTags: (res, err, req) => [
                { type: 'Customer' as const, id: req?.customerId },
                'DashboardMapMarker',
            ],
        }),
        createLocationAttachment: build.mutation<CreateLocationAttachmentResponse, CreateLocationAttachmentRequest>({
            query: (req: CreateLocationAttachmentRequest) => ({
                url: `locations/${req.locationId}/attachments`,
                method: 'POST',
                body: createAttachmentFormData(req),
            }),
            invalidatesTags: (res, err, req) => [
                { type: 'Location' as const, id: req?.locationId },
                'DashboardMapMarker',
            ],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetCustomersQuery,
    useGetCustomerQuery,
    useCreateCustomerMutation,
    useUpdateCustomerMutation,
    useGetLocationsQuery,
    useGetLocationQuery,
    useCreateLocationMutation,
    useUpdateLocationMutation,
    useGetCustomerLocationsQuery,
    useGetDevicesQuery,
    useGetDeviceQuery,
    useCreateDeviceMutation,
    useUpdateDeviceMutation,
    useGetLocationDevicesQuery,
    useGetConnectionsQuery,
    useGetConnectionQuery,
    useDeleteConnectionMutation,
    useDeleteCustomerMutation,
    useDeleteLocationMutation,
    useCreateConnectionMutation,
    useUpdateConnectionMutation,
    useGetLocationConnectionsQuery,
    useGetConnectionAvailabilityQuery,
    useGetConnectionLatencyQuery,
    useGetConnectionJitterQuery,
    useGetDashboardQuery,
    useGetDashboardMapMarkersQuery,
    useCreateCustomerAttachmentMutation,
    useCreateLocationAttachmentMutation,
} = managementApi;

export const {
    useLazyGetCustomerQuery,
    useLazyGetLocationQuery,
    useLazyGetConnectionQuery,
    useLazyGetConnectionsQuery,
} = managementApi;
